import { Component, OnInit } from "@angular/core";
import { NotifierService } from "angular-notifier";
import { UserService } from "../../services/user.service";
import { EventsService } from "../../services/events.service";
import { DataService } from "src/app/services/data.service";
import { AuthService } from "src/app/services/auth";

interface Tab {
  objectId: string;
  generateUniqueId?: string;
  templateId?: any;
  action: string;
  event: string;
  isActive: boolean;
  enableMapping: boolean;
  stage: any;
  order?: number;
  delay?: number;
}

@Component({
  selector: 'app-automationComponent',
  templateUrl: './automation.component.html',
  styleUrls: ['./automation.component.scss'],
})
export class AutomationComponent implements OnInit {
  public disabled = true;
  public selected: any;
  public showGuide: Boolean = false;
  public showWorkflow: Boolean = true;
  public isLoading = true;
  public stagesId: any;
  public templateId: any;
  public emailTemplatesList: any[] = [];
  public eventsList: any[] = [];
  public eventsMappingList: any[] = [];
  public checkedList: any = [];
  public tabs: { [key: string]: Tab[] } = {};
  public selectedEvent: string | null = null;
  public eventKeys:any[] = [];
  public uniqueEventsList: any[] = [];
  public tabEvent: string;
  public selectedSpace: any;
  public spacesList: any[] = [];

  constructor(
    private notifier: NotifierService,
    public userService: UserService,
    public eventService: EventsService,
    private dataService: DataService,
    public authService: AuthService,
  ) {}

  public async ngOnInit() {
    this.eventsList = await this.eventService.getEventsList(true);
    this.getEventsMappingList();
    this.getStagesList();
    this.isLoading = false;
  }

  // Event handlers
  public selectEvent(eventKey: string) {
    this.selectedEvent = eventKey;
    this.tabEvent = eventKey
    if(!this.eventKeys.includes(eventKey) && !this.tabs[this.selectedEvent]) {
      this.addNewRow('NewMapping')
    }
  }

  public async getSpacesList(spaceId?: string ) {
    let spacesQuery: any;
    if(!this.authService.isSuperAdmin()){
      spacesQuery = {
        where: {
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
          isArchived: { $ne : true },
        },
      };
      if (spaceId) {
        spacesQuery.where['objectId'] = spaceId
      }
    }
    const resp = await this.dataService.getFromServer(
      'classes/Spaces',
      spacesQuery,
    );
    this.spacesList = resp.results;
    
     return resp.results.length > 0 ? this.spacesList[0] : null;
   
  }

  public async getStagesList() {
    const resp1 = await this.getSpacesList()
    let stageQuery = {
      where: {
        $or: [
          {
            entity: {
              __type: "Pointer",
              className: "Entity",
              objectId: this.authService.getUser().entityId.objectId,
            },
          },
          {
            entity: { $exists: false },
          },
        ],
      },
      order: "order",
    };
    
    if (resp1.objectId) {
      stageQuery.where = {
        ...stageQuery.where, 
        space: {
          __type: "Pointer",
          className: "Spaces",
          objectId: resp1.objectId,
        },
      } as any;
    }
  
    const resp = await this.dataService.getFromServer(
      "classes/Stages",
      stageQuery,
    );
    this.stagesId = resp.results; 
  }

  public async getEmailTemplatesList(){
    try {
    let emailTemplateQuery;
    if(!this.authService.isSuperAdmin()){
      emailTemplateQuery = {
        where: {
          fromUser: {
            __type: 'Pointer',
            className: '_User',
            objectId: this.authService.getUser().objectId
          },
        },
        include: 'toContact',
    };
    }
    const resp = await this.dataService.getFromServer(
      'classes/EmailTemplates', emailTemplateQuery,
    );
    this.emailTemplatesList = resp.results;
  }
  catch(error) {
    this.notifier.notify('error', 'Getting error while checking for Email Templates');
    return;
    }
  }
  
  public async getEventsMappingList() {
    this.getEmailTemplatesList();
    let eventsMappingQuery;
    if (!this.authService.isSuperAdmin()) {
      eventsMappingQuery = {
        include: 'templateId,stage',
        where: {
          entity: {
            __type: "Pointer",
            className: "Entity",
            objectId: this.authService.getUser().entityId.objectId
          },
          isActive: true,
        },
      };
    }
    const resp = await this.dataService.getFromServer(
      "classes/EventMapping",
      eventsMappingQuery
    );

    let tempArray : any = [];
    resp.results.forEach(async (event : any) => {
      tempArray.push({
        ...event,
        templateId: event.templateId || {objectId: 'null'}, 
        disabled : true,
        stage: event.stage || {objectId: 'null'}
      });
    });
    this.eventsMappingList = JSON.parse(JSON.stringify(tempArray));
    if (this.eventsMappingList.length) {
      //@ts-ignore
      this._tabs = this.eventsMappingList.reduce((acc, item) => {
        // Initialize the event group if it doesn't exist
        if (!acc[item.event]) {
          acc[item.event] = [];
        }
        
        // Push the item into the appropriate event group
        acc[item.event].push(item);
    
        // Sort the group by `order`, treating missing `order` as 0
        acc[item.event].sort((a: Tab, b: Tab) => (a.order || 0) - (b.order || 0));
    
        // After sorting, check if the first tab's delay is not set and set it to 0
        if (acc[item.event].length > 0 && acc[item.event][0].delay == null) {
          acc[item.event][0].delay = 0; // Set delay to 0 if it's not set
        }
    
        return acc;
      }, {} as { [key: string]: Tab[] });
  
      //@ts-ignore
       this.tabs = this._tabs
       //@ts-ignore
      this.eventKeys =Object.keys(this._tabs);

      if(this.eventKeys.length && this.eventsList.length) {  
        let arrayNames = this.eventsList.map(obj => obj.name);
        const ignoreItems = [ "INIT", "Identify" ];

        this.uniqueEventsList = arrayNames.filter(
          item => !ignoreItems.includes(item) && !this.eventKeys.includes(item)
        );
        if((!this.selectedEvent) || (this.uniqueEventsList && this.selectedEvent && this.uniqueEventsList.includes(this.selectedEvent))) {
          // this.selectedEvent = this.eventKeys[0]
        }
      }else {
        this.uniqueEventsList = this.eventKeys.map(obj => obj.name);
      }

    } else {
      this.eventsList = await this.eventService.getEventsList(true);
      this.uniqueEventsList = this.eventsList.map(obj => obj.name);
    }
  }

  public async getSpaces() {
    const spacesQuery = {
      where: {
        entity: {
          __type: "Pointer",
          className: "Entity",
          objectId: this.authService.getUser().entityId.objectId
        },
      },
    };
    const resp = await this.dataService.getFromServer(
      "classes/Spaces",
      spacesQuery,
    );
    const spacesList = resp.results;
    return spacesList[0].templateId;
  }

  onActionChange(i: number, selectedValue: string) {
    this.eventsMappingList[i].action = selectedValue;
  }

  onStageChange(index:number, newStageId:string){
    const newStage = this.stagesId.find((stage: { objectId: string; }) => stage.objectId == newStageId);
    this.eventsMappingList[index].stage = newStage;
  }

  onTemplateChange(index:number, newTemplateId:string){
    const newTemplate = this.emailTemplatesList.find((template: { objectId: string; }) => template.objectId == newTemplateId);
    this.eventsMappingList[index].templateId = newTemplate;
  }

  public async onSaveAll() {
    if(this.selectedEvent !== null) {
       // Validate duplicate delays
       const delaysWithActions = this.tabs[this.selectedEvent].map((item) => `${item.delay}-${item.action}`);
       const delaySet = new Set(delaysWithActions);
       
       if (delaysWithActions.length !== delaySet.size) {
         this.notifier.notify('error', 'Duplicate delay-action combinations found. Please ensure all combinations are unique.');
       }
      else {       
        try {
          const promises = this.tabs[this.selectedEvent].map((tab) => {
            let obj = {}
            if(tab.objectId === '' && tab.generateUniqueId) {
              obj = {
                eventName: this.selectedEvent,
                action: tab.action,
                stageObjectId: tab.stage && tab.stage.objectId ? tab.stage.objectId : '',
                templateObjectId: tab.templateId ? tab.templateId.objectId : '',
                enableMapping: tab.enableMapping,
                delay: tab.delay,
                isActive: true,
                entityObjectId : this.authService.getUser().entityId.objectId,
              };
            } else {
              obj = {
                eventName: this.selectedEvent,
                action: tab.action,
                stageObjectId: tab.stage && tab.stage.objectId ? tab.stage.objectId : '',
                templateObjectId: tab.templateId ? tab.templateId.objectId : '',
                enableMapping: tab.enableMapping,
                eventMappingObjectId: tab.objectId,
                delay: tab.delay,
                isActive: tab.isActive,
              };
            }
            const endpoint = tab.objectId === '' ? 'functions/saveEventMapping' : 'functions/updateEventMapping';
            return this.dataService.postToServer(endpoint, obj);
          });

          await Promise.all(promises);
            this.notifier.notify('success', 'Changes saved successfully!!!');
        } catch (_) {
          this.notifier.notify('error', 'Error processing event mappings');
        }
        this.getEventsMappingList();
      }
    }
  }

  public addNewRow(NewMapping?: string) {
    if(this.selectedEvent) {
      
      if (this.tabs[this.selectedEvent] && this.tabs[this.selectedEvent].length >= 6) {
        this.notifier.notify('error', 'Only 6 mappins allowed.');
        return;
      }

      let newTab: Tab = {
        objectId: '',
        generateUniqueId: this.generateUniqueId(),  // Unique ID for the new tab
        templateId: { objectId: '', templateName: '' },
        action: '',
        event: '',
        isActive: true,
        enableMapping: true,
        stage: { objectId: '', name: '' },
      };
      if(NewMapping) {
        if (!this.tabs[this.selectedEvent]) {
          this.tabs[this.selectedEvent] = [];
        }
        newTab = {...newTab, delay: 0}
        this.tabs[this.selectedEvent].push(newTab);
      }else {
        this.tabs[this.selectedEvent].push(newTab);
      }
    }
  }

  public generateUniqueId(): string {
    return 'id_' + new Date().getTime();
  }

  public async deleteRow(eventRowId: string, generateId?: string) {
    try {
      if(this.selectedEvent !== null) {
        if(eventRowId) {
        await this.dataService.updateToServer(
          "classes/EventMapping/" + eventRowId,
          { isActive: false }
        );
        this.tabs[this.selectedEvent] = this.tabs[this.selectedEvent].filter(
          (tab: Tab) => tab.objectId !== eventRowId        
        );
      }else {
        this.tabs[this.selectedEvent] = this.tabs[this.selectedEvent].filter(
          (tab: Tab) => tab.generateUniqueId !== generateId         
        );
      }
        this.notifier.notify('success', 'Event deleted successfully!!!');
        this.getEventsMappingList();
        this.showMappingHome();
      } else {
        this.notifier.notify('error', 'Error processing event deleting');
      }
    } catch (_) {
      this.notifier.notify('error', 'Error processing event deleting');
    }
  }

  public createNewMapping(newMapping: string) {
    if(!newMapping) {
      this.notifier.notify('error', 'Something went wrong, please try again.');
    } else {
      this.tabEvent = newMapping;
      this.selectedEvent = newMapping;
      this.addNewRow(newMapping);
    }
  }

  public showMappingHome() {
    this.tabEvent = '';
    this.selectedEvent = null;
  }
}
