// error.ts

export function getErrorMessage(error: unknown): string {
    if (error instanceof Error) {
        return error.message;
    }
    return 'Something went wrong, please try again later.'; // Fixed typo and improved wording
}

// Optionally, define a custom error class
export class CustomError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'CustomError';
    }
}