import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NotifierService } from "angular-notifier";
import { DataService } from "../../services/data.service";
import { AuthService } from "../../services/auth";

@Component({
  selector: "landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"]
})
export class LandingPageComponent implements OnInit {
  public isLoading = true;
  public websiteForm: FormGroup;
  public isWebsite: boolean = false;
  public imgURL: any;
  productImages: Array<{ url: string; name: string }> = [];
  public widgetsActionStatus: boolean = true;
  public whatAppWidgetStatus: boolean = false;
  public contactFormWidgetStatus: boolean = false;
  public joinWaitlistWidgetStatus: boolean = false;
  public isFormSubmitted: boolean;
  public initialSlug = "";
  public entityImage: boolean;
  public isApproved: boolean = false;
  public showSeoFields: boolean = false;
  public slugValidationState: boolean | null = null;
  public previewUrl: string | null = null;
  public formLogoUrl: string | null = null;

  @ViewChild('fileInput') fileInput!: ElementRef;

  constructor(
    private notifier: NotifierService,
    private dataService: DataService,
    public authService: AuthService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.websiteForm = this.fb.group({
      slugName: ["", [Validators.required]],
      websiteTitle: ["", [Validators.required]],
      // websiteSubTitle: ["", [Validators.required]],
      websiteOwnLink: ["", [Validators.required]],
      websiteDescription: ["", [Validators.required]],
      imgURL: ["", [Validators.required]],
      whatsappNumber: ["", [Validators.required]],
      contactCta: ["", [Validators.required]],
      metaTitle: ["", [Validators.required]],
      metaDescription: ["", [Validators.required]],
      logoUrl: ["", [Validators.required]]
    });
    // this.fetchInitialIsWebsiteValue();
    this.getDataFromWebflow();
    this.isLoading = false;
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.previewUrl = e.target.result; 
        this.formLogoUrl = null; 
      };
      reader.readAsDataURL(file);
    }
  }

  triggerFileInput(): void {
    if (this.fileInput) {
      this.fileInput.nativeElement.click();
    } else {
      console.error('File input reference is not defined.');
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    const file = event.dataTransfer && event.dataTransfer.files ? event.dataTransfer.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.previewUrl = e.target.result;
        this.formLogoUrl = null; 
      };
      reader.readAsDataURL(file);
    }
  }

  removeImage(event: Event): void {
    event.stopPropagation();
    this.previewUrl = null;
    this.formLogoUrl = null;

    if (this.fileInput) {
      this.fileInput.nativeElement.value = ''; 
    }
  }

  populateWebflowLogo(logoUrl: string): void {
    this.formLogoUrl = logoUrl;
    this.previewUrl = null; 
  }

  async toggleWebsite(event: any) {
    this.isWebsite = event.target.checked;
    await this.saveToggleState();
    if (this.isWebsite) {
      await this.fetchInitialIsWebsiteValue();
    } else {
      this.websiteForm.controls["slugName"].reset();
      this.initialSlug = "";
    }
  }

  public async onSelectFile(event: Event) {
    this.entityImage = true;
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      if (files[0].size / (1024 * 1024) > 1) {
        (event.target as HTMLInputElement).value = "";
        this.notifier.notify(
          "error",
          "Please select a file size less than 1 MB."
        );
        this.entityImage = false;
        return;
      } else {
        this.imgURL = await this.dataService.uploadFile(files[0]);
        try {
          const data = {
            entityImage: {
              name: this.imgURL.name,
              url: this.imgURL.url,
              __type: "File"
            },
            isApproved: false,
          };
          const entityObject = this.authService.getUser();
          if (entityObject) {
            const response = await this.dataService.updateToServer(
              "classes/Entity/" + entityObject.entityId.objectId,
              data
            );
            if (response) {
              this.isApproved =  false;
              this.notifier.notify("success", "Entity Image Updated");
            }
          }
        } catch (error) {
          this.notifier.notify("error", "Unable to add your website URL");
        }
      }
    }
    this.entityImage = false;
  }

  public async onSelectMultipleFiles(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      const uploadedFiles: Array<{
        url: string;
        name: string;
      }> = await this.dataService.uploadFiles(files);
      this.productImages = (this.productImages as Array<{
        url: string;
        name: string;
      }>).concat(
        uploadedFiles.map(file => ({ url: file.url, name: file.name }))
      );
    }
  }

  public deleteImage(index: number) {
    if (
      index < 0 ||
      index >=
        (this.productImages as Array<{ url: string; name: string }>).length
    ) {
      this.notifier.notify("error", "Invalid index for image deletion");
      return;
    }

    (this.productImages as Array<{ url: string; name: string }>).splice(
      index,
      1
    );
    this.notifier.notify("success", "Image removed successfully");
  }

  async widgetsCallToActions(event: any) {
    this.widgetsActionStatus = event.target.checked;
    await this.saveToggleState();
  }

  async wappWidgetStatus(event: any) {
    this.whatAppWidgetStatus = event.target.checked;
    await this.saveToggleState();
  }

  async formWidgetStatus(event: any, type: string) {
    const checked = event.target.checked;
    if (type === "cf") {
      this.contactFormWidgetStatus = checked;
      if (checked) {
        this.joinWaitlistWidgetStatus = false;
      }
    } else if (type === "jw") {
      this.joinWaitlistWidgetStatus = checked;
      if (checked) {
        this.contactFormWidgetStatus = false;
      }
    }
    await this.saveToggleState();
  }

  get isInitialSlugEmpty() {
    return !this.initialSlug;
  }

  public async addSlugIntegrations() {
    const isSlugAvailable = await this.checkSlugAvailability();
    if (!isSlugAvailable) {
        this.notifier.notify("error", "Slug is already taken. Please choose a different one.");
        return; 
    }
    this.addDataToWebflow();
    this.isFormSubmitted = true;
    const profileSlug = this.websiteForm.value.slugName;
    const websiteDescription = this.websiteForm.value.websiteDescription;
    // const websiteSubTitle = this.websiteForm.value.websiteSubTitle;
    const websiteTitle = this.websiteForm.value.websiteTitle;
    const websiteOwnLink = this.websiteForm.value.websiteOwnLink;
    const productImages = this.productImages.map(img => ({
      url: img.url,
      name: img.name,
      __type: "File"
    }));
    const whatsappNumber = this.websiteForm.value.whatsappNumber+'';
    const contactCta = this.websiteForm.value.contactCta;
    const metaTitle = this.websiteForm.value.metaTitle;
    const metaDescription = this.websiteForm.value.metaDescription;

   
    try {
      const data = {
        profileSlug,
        websiteTitle,
        websiteDescription,
        isWebsite: this.isWebsite,
        productImages,
        websiteOwnLink,
        whatsappNumber,
        contactCta,
        metaTitle,
        metaDescription,
        isApproved: false,
      };

      const entityObject = this.authService.getUser();
      if (entityObject) {
        const response = await this.dataService.updateToServer(
          "classes/Entity/" + entityObject.entityId.objectId,
          data
        );
        if (response) {
          this.isApproved =  false;
          // this.notifier.notify("success", "Your landing page under review");
        }
      }
    } catch (error) {
      this.notifier.notify("error", "Unable to add your Landing Page");
      console.error("Error updating slug integrations:", error);
    } finally {
      this.isFormSubmitted = false;
    }
  }

  public async addDataToWebflow() {
    this.isFormSubmitted = true;

    const userParams = {
      include: "entityId"
    };
    const entityDetails = await this.dataService.getFromServer(
      "classes/_User/" + this.authService.getUser().objectId,
      userParams,
    );
    const entityApiKey = entityDetails.entityId.apiKey
    const entityImage = entityDetails.entityId.entityImage
    const entityObj = entityDetails.entityId
    
    const profileSlug = this.websiteForm.value.slugName;
    const websiteTitle = this.websiteForm.value.websiteTitle;
    const websiteDescription = this.websiteForm.value.websiteDescription;
    const websiteOwnLink = this.websiteForm.value.websiteOwnLink;
    const productImages = this.productImages.map(img => ({
      url: img.url,
      name: img.name,
      __type: "File"
    }));
    const whatsappNumber = this.websiteForm.value.whatsappNumber + '';
    const contactCta = this.websiteForm.value.contactCta;
    const metaTitle = this.websiteForm.value.metaTitle;
    const metaDescription = this.websiteForm.value.metaDescription;
  
    const data = {
      "fieldData": {
        "slug": profileSlug,
        "name": websiteTitle,
        "description": websiteDescription,
        "prooduct-images": productImages,
        "website-own-link": websiteOwnLink,
        "whatsapp-number": whatsappNumber,
        "action-button-label": contactCta,
        "seo-title": metaTitle,
        "seo-description": metaDescription,
        "is-approved": "true",
        "api-key": entityApiKey,
        "logo-2": entityImage,
        "entity-obj": entityObj
      },
    };

    try {
        await this.dataService.postToServer(
          'functions/sendDataToWebflow',
          data,
        );
        this.notifier.notify("success", "Your landing page under review");
    } catch (error) {
      this.notifier.notify("error", "Unable to add your Landing Page");
      console.error("Error updating slug integrations:", error);
    } finally {
      this.isFormSubmitted = false;
    }
  }

  public async checkSlugAvailability() {
    try {
        const slugVal = this.websiteForm.value.slugName;
        const response = await this.dataService.postToServer(
            'functions/checkSlugExists',
            { slug: slugVal }  
        );

        if (response.result && response.result.exists) {
          this.slugValidationState = false;
            return false;
        } else {
            this.slugValidationState = true;
            return true;
        }
    } catch (error) {
        this.notifier.notify("error", "Error checking slug availability");
        console.error("Error in checkSlugAvailability:", error);
        this.slugValidationState = false;
        return false;
    }
  }

  public async viewProductPage() {
    const profileSlug = this.websiteForm.value.slugName;
    // debugger;
    try {
      if (profileSlug) {
        // window.open(`http://localhost:3000/profile/${profileSlug}`, '_blank');
        window.open(`https://inleads.ai/landing/${profileSlug}`, "_blank");
      }
    } catch (e) {
      console.log(e);
    }
  }

  public async saveToggleState() {
    try {
      const data = {
        isWebsite: this.isWebsite,
        widgetsActionStatus: this.widgetsActionStatus,
        whatAppWidgetStatus: this.whatAppWidgetStatus,
        contactFormWidgetStatus: this.contactFormWidgetStatus,
        joinWaitlistWidgetStatus: this.joinWaitlistWidgetStatus || false,
        isApproved: false,
      };

      const entityObject = this.authService.getUser();

      if (
        entityObject &&
        entityObject.entityId &&
        entityObject.entityId.objectId
      ) {
        console.log("Sending data to server:", data);

        const response = await this.dataService.updateToServer(
          `classes/Entity/${entityObject.entityId.objectId}`,
          data
        );

        console.log("Server response:", response);

        if (response && data.isWebsite === false) {
          this.notifier.notify("success", "Your Landing Page is Offline");
        } else if (response && data.widgetsActionStatus !== false) {
          this.notifier.notify("success", "Call to actions enabled");
        } else if (response && data.widgetsActionStatus === false) {
          this.notifier.notify("error", "Call to actions disabled");
        } else {
          this.notifier.notify("error", "Unexpected response from the server");
        }
      } else {
        throw new Error("Invalid entity object");
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(
          "Error updating the landing page toggle state:",
          error.message
        );
      } else {
        console.error(
          "Unknown error updating the landing page toggle state:",
          error
        );
      }
      this.notifier.notify(
        "error",
        "Unable to update the Landing page toggle state"
      );
    }
  }

  public async fetchInitialIsWebsiteValue() {
    try {
      const entityObject = this.authService.getUser();
      if (entityObject) {
        const response = await this.dataService.getFromServer(
          "classes/Entity/" + entityObject.entityId.objectId
        );
        this.isApproved = response.isApproved
        console.log('response',response);
        if (response && response.isWebsite !== undefined) {
          this.isWebsite = response.isWebsite;
          if (this.isWebsite) {
            this.initialSlug = response.profileSlug || "";
            this.websiteForm.patchValue({
              slugName: response.profileSlug || "",
              websiteTitle: response.websiteTitle || "",
              websiteDescription: response.websiteDescription || "",
              websiteOwnLink: response.websiteOwnLink || "",
              whatsappNumber: response.whatsappNumber || "",
              contactCta: response.contactCta || "",
              metaTitle: response.metaTitle || "",
              metaDescription: response.metaDescription || "",
            });
            this.imgURL = response.entityImage ? response.entityImage.url : "";
            this.productImages = response.productImages
              ? response.productImages.map((img: any) => ({
                  url: img.url,
                  name: img.name
                }))
              : [];

            this.websiteForm.updateValueAndValidity();
          }
        }
      }
    } catch (error) {
      this.notifier.notify(
        "error",
        "Unable to fetch the initial isWebsite value"
      );
      console.error("Error fetching initial value:", error);
    }
  }

  public async getDataFromWebflow() {
    const userParams = { include: "entityId" };
  
    try {
      if (!this.websiteForm) {
        console.error("websiteForm is not initialized.");
        return;
      }
  
      const entityDetails = await this.dataService.getFromServer(
        "classes/_User/" + this.authService.getUser().objectId,
        userParams
      );
  
      const itemId = entityDetails.entityId ? entityDetails.entityId.webflowCmsItemId : null;
      if (!itemId) {
        console.error("Item ID not found in entity details.");
        return;
      }
  
      const data = { "itemId": itemId };
  
      const response = await this.dataService.postToServer(
        "functions/getDataFromWebflow",
        data
      );
  
      if (response && response.result && response.result.fieldData) {
        this.isWebsite = true;
        const fieldData = response.result.fieldData;

        const logoUrl = fieldData["logo-2"] || null;

        const productImagesArray = fieldData["prooduct-images"]
          ? fieldData["prooduct-images"].split(",").map((imgUrl: string) => ({
              url: imgUrl.trim()
            }))
          : [];
  
        this.websiteForm.patchValue({
          websiteTitle: fieldData["name"] || "",
          websiteDescription: fieldData["description"] || "",
          productImages: productImagesArray,
          whatsappNumber: fieldData["whatsapp-number"] || "",
          contactCta: fieldData["action-button-label"] || "",
          metaTitle: fieldData["seo-title"] || "",
          metaDescription: fieldData["seo-description"] || "",
          slugName: fieldData["slug"] || "",
          websiteOwnLink: fieldData["website-own-link"] || "",
          logoUrl: logoUrl || ""
        });
  
        this.populateWebflowLogo(logoUrl);
        this.websiteForm.updateValueAndValidity();
      } else {
        console.error("Invalid response format from Webflow:", response);
      }
    } catch (error) {
      console.error("Error fetching data from Webflow:", error);
    }
  }

  public toggleSeoFields() {
    this.showSeoFields = !this.showSeoFields;
  }
 
}
