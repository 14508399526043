import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
//import { validationPattern } from 'src/app/constants/regexp';
import { AuthService } from '../../services/auth';
import { RegisterParameters } from '../../types/auth';
// import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/services/data.service';
import { Title } from '@angular/platform-browser';
import { validationPattern } from 'src/app/constants/regexp';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit, AfterViewInit, OnDestroy {
  public registerForm: FormGroup;
  public disableFields: boolean;
  public isFormSubmitted: boolean;
  public afterView: boolean;
  public usertype: string;
  public currentEntity: string;
  public entitiesList = [];
  public showPassword: boolean;
  public freeEmailDomains: string[] = [
    'gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 'icloud.com', 'aol.com', 'mail.com', 'zoho.com'
  ];

  constructor(
    private authService: AuthService,
    // private router: Router,
    private notifier: NotifierService,
    private dataService: DataService,
    private titleService: Title,
  ) {
  }
  public ngAfterViewInit() {
    this.titleService.setTitle("Signup | InLeads")
    setTimeout(() => {
      this.afterView = true;
    }, 10);
  }

  public ngOnDestroy() {
    this.afterView = false;
  }

  public togglemyPasswordFieldType() {
    this.showPassword = !this.showPassword;
  }

  public ngOnInit() {
    // const _email = null;
    const _password = null;
    this.registerForm = new FormGroup({
      name: new FormControl(),
      companyName: new FormControl(),
      emailId: new FormControl('', [
        Validators.required,
        Validators.pattern(validationPattern.email),
        // this.workEmailValidator.bind(this)
      ]),
      passcode: new FormControl(_password, [
        Validators.required,
         Validators.minLength(8),
          Validators.pattern(validationPattern.password),
      ]),
      // companyName: new FormControl(),
      // phoneNumber: new FormControl(),
      signInuserType: new FormControl(),
      termsAndConditions: new FormControl(false, Validators.requiredTrue),
    });
  }


  public async register(_form: FormGroup) {
    // const Url = this.router.url;
    // if (Url === '/registration/agent') {
    //   this.usertype = 'agent';
    // } else if (Url === '/registration/customer') {
    //   this.usertype = 'customer';
    // }
    /* if(!this.registerForm.value.companyName){
      return this.notifier.notify('error', 'Please Enter Compay Name');
    } */
    if (!this.registerForm.value.name) {
      return this.notifier.notify('error', 'Please Enter Name');
    }
    if (!this.registerForm.value.companyName) {
      return this.notifier.notify('error', 'Please Enter company Name');
    }
    if (!this.registerForm.value.passcode) {
      return this.notifier.notify('error', 'Password should be a minimum of 8 characters and must contain at least one uppercase letter, one lowercase letter, one digit, and one special character (except "#")');
    }

    if (!this.registerForm.value.emailId) {
      return this.notifier.notify('error', 'Please Enter Email ID');
    }
    if (!this.registerForm.value.termsAndConditions) {
      return this.notifier.notify('error', 'Please check the Terms And Conditions');
    }

    if(!validationPattern.email.test(this.registerForm.value.emailId)){
      return this.notifier.notify('error', 'Email Id pattern is invalid');
    }

    if(!validationPattern.password.test(this.registerForm.value.passcode)){
      return this.notifier.notify('error', 'Password should be a minimum of 8 characters and must contain at least one uppercase letter, one lowercase letter, one digit, and one special character (except "#").');
    }

    this.disableFields = true;
    this.isFormSubmitted = true;
    try {

      const params = {
        username: this.registerForm.value.emailId,
      };
      let userExists: any = await this.dataService.postToServer(
          "functions/checkUserExist",
          params
      );
      if(userExists.result === false){
        const entityData = {
          name: this.registerForm.value.companyName + ' - Entity',
        };
        let entityObj = await this.dataService.postToServer('classes/Entity', entityData);
        const userDetails: RegisterParameters = {
          name: this.registerForm.value.name,
          password: this.registerForm.value.passcode,
          email: this.registerForm.value.emailId,
          companyName: this.registerForm.value.companyName,
          // phoneNumber: parseInt(this.registerForm.value.phoneNumber, 10),
          userType: 'admin',
          isActive: true,
          username: this.registerForm.value.emailId,
          entityId: { __type: 'Pointer', className: 'Entity', objectId: entityObj.objectId },
        };
        await this.authService.register(userDetails);
      }   
    } catch (error) {
      console.log(error);
      this.disableFields = false;
      this.isFormSubmitted = false;
      if (error) {
        return this.notifier.notify('error', error.error.error);
      }
      else {
        return this.notifier.notify('error', "Something went wrong, Please try again later.");
      }
    }

    //   if (error && error.error.code === 142) {
    //     return this.notifier.notify(
    //       'error',
    //       'Password should be a minimum of 8 characters and must contain at least one uppercase letter, one lowercase letter, one digit, and one special character (except "#").',
    //     );
    //   } else if (error && error.error.code === 202){      
    //     return this.notifier.notify('error', "Email already exists. Please try with a different Email.");
    //   }
    //   else {
    //     return this.notifier.notify('error', "Something went wrong, Please try again later.");
    //   }
    // }
  }

  // public isFieldValid(
  //   field: string,
  //   currentForm: FormGroup,
  //   showErrors: boolean,
  // ): boolean {
  //   if (currentForm.get(field) && !this.disableFields)  {
  //     return (
  //       !currentForm!.get(field)!.valid &&
  //       (currentForm.get(field)!.touched || showErrors)
  //     );
  //   }
  //   return true;
  // }

  public isFieldValid(field: string, currentForm: FormGroup) {
    if (currentForm.get(field) && !this.disableFields) {
      return !currentForm.get(field)!.valid && currentForm.get(field)!.touched;
    }
    return;
  }

  public displayFieldCss(field: string, currentForm: FormGroup) {
    return {
      'has-error': this.isFieldValid(field, currentForm),
      'has-feedback': this.isFieldValid(field, currentForm),
    };
  }

  public resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  public googleLogin() {
    window.location.href = "https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://server.inleads.ai/redirect&prompt=consent&response_type=code&client_id=383088886467-h998qd9i88v9i525fc637icvgh6ehca1.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/plus.me+https://www.googleapis.com/auth/userinfo.profile+&access_type=offline";
  }

  public workEmailValidator(control: FormControl) {
    const email = control.value;
    if (email) {
      const domain = email.split('@')[1];
      if (this.freeEmailDomains.includes(domain)) {
        return { workEmail: true };
      }
    }
    return null;
  }

}
