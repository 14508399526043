import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
// import {Router} from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { validationPattern } from '../../constants/regexp';
import { AuthService } from '../../services/auth';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  public loginForm: FormGroup;
  public disableFields: boolean;
  public isFormSubmitted: boolean;
  public afterView: boolean;
  public showPassword: boolean;
  public isLoading: boolean;
  public is2FAEnabled: boolean = false;
  public response: any;
  public showAlert = true;
  public isResendDisabled = true;
  public countdown: number = 60;
  public interval: any;

  constructor(
    private authService: AuthService,
    private notifier: NotifierService,
    private dataService: DataService,
    private router: Router,
    private titleService : Title,
  ) {
    }

  public ngAfterViewInit() {
    this.titleService.setTitle("Login | InLeads");
    setTimeout(() => {
      this.afterView = true;
    },10);
  }

  public ngOnDestroy() {
    this.afterView = false;
  }

  public togglemyPasswordFieldType() {
    this.showPassword = !this.showPassword;
  }

  public ngOnInit() {
    const _email = null;
    const _password = null;
    this.loginForm = new FormGroup({
      email: new FormControl(_email, [
        Validators.pattern(validationPattern.email),
      ]),
      password: new FormControl(_password, [
        Validators.required,
        Validators.minLength(6),
      ]),
      twoFactorCode: new FormControl('')
    });

    const url = new URL(window.location.href);
    const [, queryStr] = url.hash.split("?");
    const searchParams = new URLSearchParams(queryStr);
    const code = searchParams.get('code');
    if (code) {
      this.isLoading = true;
      this.fetchGoogleToken(code).then(res => {
        if (res.result && res.result.user) {
          this.dataService.updateSessionHeaders(res.result.user.sessionToken);
          localStorage.setItem('user', JSON.stringify(res.result.user));
          sessionStorage.setItem('user', JSON.stringify(res.result.user));
          this.router.navigate(['/dashboard']);
          this.notifier.notify('success', 'Welcome '+res.result.user.name);
        }
        this.isLoading = false;
      }).catch(err => {
        this.isLoading = false;
        if(err.error.error.message == "Account already exists for this username.")
        {
          this.notifier.notify('error', 'Account already exists.Please try login');
        }else{
          this.notifier.notify('error', 'Something went wrong, please try again');
        }
      });
    }
    this.startTimer();
  }

  public async verify2FA() {
    try {
      const authToken = this.loginForm.value.twoFactorCode;
      const response = await this.dataService.postToServer('functions/verify2FA', { token: authToken });
      if (response) {
        await this.authService.getEntityDetails(this.response);
        localStorage.setItem('user', JSON.stringify(this.response));
        sessionStorage.setItem('user', JSON.stringify(this.response));
        this.router.navigate(['/dashboard']);
        this.notifier.notify('success', `Welcome ${this.response.name}`);
      } else {
        this.handleInvalidToken();
      }
    } catch (error) {
      this.notifier.notify('error', error.error.error || 'Failed to verify. Please Enter Valid Code');
    }
  }

  private handleInvalidToken() {
    this.notifier.notify('error', 'Invalid token.');
    this.dataService.cleanHeaders();
    this.router.navigate(['']);
  }

  public async login(_form: FormGroup) {
    this.isFormSubmitted = true;
    this.disableFields = true;

    if (this.loginForm.invalid) {
      this.handleInvalidLoginForm();
      return;
    }

    try {
      const response: any = await this.authService.login(
        this.loginForm.value.email,
        this.loginForm.value.password,
      );
      this.response = response;
      if (response) {
        await this.dataService.updateSessionHeaders(response.sessionToken);
        const currentPageUrl = window.location.href;
        if(this.response.is2FAEnabled === true) {
          await this.dataService.postToServer('functions/enable2FA', {currentPageUrl});
          this.is2FAEnabled = true;
        } else {
          await this.authService.getEntityDetails(this.response);
          localStorage.setItem('user', JSON.stringify(this.response));
          sessionStorage.setItem('user', JSON.stringify(this.response));
          this.router.navigate(['/dashboard']);
          this.notifier.notify('success', `Welcome ${this.response.name}`);
          this.is2FAEnabled = false;
        }
      }
    } catch (error) {
      this.notifier.notify('error', 'Login failed. Please check your credentials and try again.');
    } finally {
      this.isFormSubmitted = false;
      this.disableFields = false
    }
  }

  public async resendCode() {
    const currentPageUrl = window.location.href;
    await this.dataService.postToServer('functions/enable2FA', {currentPageUrl});
    this.is2FAEnabled = true;
    this.startTimer();
  }

  public startTimer() {
    this.isResendDisabled = true;
    this.countdown = 60;
    this.interval = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.isResendDisabled = false;
        clearInterval(this.interval);
      }
    }, 1000);
  }

  private handleInvalidLoginForm() {
    this.isFormSubmitted = false;
    this.disableFields = false;

    if (!this.loginForm.value.email && !this.loginForm.value.password) {
      this.notifier.notify('error', 'Please enter Email-ID and Password');
    } else if (!this.loginForm.value.email) {
      this.notifier.notify('error', 'Email-ID is required');
    } else if (!this.loginForm.value.password) {
      this.notifier.notify('error', 'Password is required');
    }
  }


  public isFieldValid(field: string, currentForm: FormGroup) {
    if (currentForm.get(field) && !this.disableFields) {
      return !currentForm.get(field)!.valid && currentForm.get(field)!.touched;
    }
    return;
  }

  public displayFieldCss(field: string, currentForm: FormGroup) {
    return {
      'has-error': this.isFieldValid(field, currentForm),
      'has-feedback': this.isFieldValid(field, currentForm),
    };
  }

  public resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  public googleLogin() {
    const baseRedirectUri = "https://server.inleads.ai/";
const hashRedirectPath = "redirect"; // This is the path defined in your Angular routing
const fullRedirectUri = `${baseRedirectUri}${hashRedirectPath}`;

const redirectUri = encodeURIComponent(fullRedirectUri);
const scope = encodeURIComponent("https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/plus.me https://www.googleapis.com/auth/userinfo.profile");

const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${redirectUri}&prompt=consent&response_type=code&client_id=383088886467-h998qd9i88v9i525fc637icvgh6ehca1.apps.googleusercontent.com&scope=${scope}&access_type=offline`;

    window.location.href = googleAuthUrl
  }

  public async fetchGoogleToken(code: string){
  const googleParams = {
    code
  }
    

  const resp = await this.dataService.postToServer(
    'functions/getGoogleToken',
    googleParams,
  );
  
    if (resp.result && resp.result.user && resp.result.user.objectId) { 
      if (resp.result.flag && resp.result.flag == 'signin') { 
        await this.authService.doLoginWithGoogle(resp.result.user);
      }
      if (resp.result.flag && resp.result.flag == 'signup') { 
        await this.authService.doSignupWithGoogle(resp.result.user);
      }
    }
   return resp; 
}
  
}
