import { Component, OnInit, ViewChild, ElementRef} from "@angular/core";
import {
  FormGroup,
  AbstractControl,
  ValidationErrors,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { NotifierService } from "angular-notifier";
import { UserService } from "../../services/user.service";
import { EventsService } from "../../services/events.service";
import { updateUserRequestParams } from "src/app/types/user";
import { DataService } from "src/app/services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth";
import { User } from "src/app/types/user";
import * as moment from "moment";
import { tz } from "moment-timezone";
import { track, unset } from "@inleads/event-logger";
import { ConfigService } from "src/app/services/config-data.service";
import { BsDropdownDirective, ModalDirective } from "ngx-bootstrap";
import { environment } from "src/environments/environment";
import {EmitterService} from "../../services/emitter.service";
import { getErrorMessage } from "src/app/helpers/error.helper";
import { HelperService } from '../../services/helper.service';

interface Tab {
  objectId: string;
  generateUniqueId?: string;
  templateId?: any;
  action: string;
  event: string;
  isActive: boolean;
  enableMapping: boolean;
  stage: any;
  order?: number;
  delay?: number;
}

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"]
})
export class SettingsComponent implements OnInit {
  @ViewChild("codeToWhatsup") codeToWhatsup: ElementRef;
  @ViewChild("codeToVisitorAnalytics") codeToVisitorAnalytics: ElementRef;
  @ViewChild("codeToNPS") codeToNPS: ElementRef;
  @ViewChild("addToWhatsup") addToWhatsup: ElementRef;
  @ViewChild("codeToContact") codeToContact: ElementRef;
  @ViewChild("addToContact") addToContact: ElementRef;
  @ViewChild("entitiesCode") entitiesCode: ElementRef;
  @ViewChild("moreDropdown") moreDropdown: BsDropdownDirective;
  @ViewChild("newFieldMap") public newFieldMap?: ModalDirective;
  @ViewChild("fbPageUnsubscribeModal") public fbPageUnsubscribeModal?: ModalDirective;
  @ViewChild("calendlyUnsubscribeModal") public calendlyUnsubscribeModal?: ModalDirective;
  public questionsInServer: any = {};
  public eventsFormGroup: FormGroup;
  public tasksFormGroup: FormGroup;
  public fieldMapFormGroup: FormGroup;
  public imgURL: any;
  public entityImage: boolean;
  isShowWorkflowDiv = true;
  showMoreDropdown = false;
  isShowEmptyWorkflowDiv = false;
  isShowNewWorkflowAddingDiv = false;
  isShowNewTaskAddingDiv = false;
  public disabled = true;
  public selected: any;
  showGuide: Boolean = false;
  showWorkflow: Boolean = true;
  public isAutoTaskEnabled: Boolean = true;
  public isDefaultTasksEnabled: Boolean = true;
  public isAdmin: boolean;
  public isSuperAdmin: boolean;
  public logoURL:any;
  public logoLoader: boolean;
  public showNewPassword: boolean;
  public showReEnterPassword: boolean;
  public oAuthResult: any[] = [];
  public showDefaultDeleteModal: boolean = false;
  public defaultTaskObj: any;


  copyWhatsupCode() {
    // const text = this.textToCopy.nativeElement.innerText;
    // const textArea = document.createElement('textarea');
    // textArea.value = text;
    // document.body.appendChild(textArea);
    // textArea.select();
    // document.execCommand('copy');
    // textArea.remove();

    const textArea = this.codeToWhatsup.nativeElement;
    textArea.select();
    document.execCommand("copy");
    textArea.setSelectionRange(0, 0);
  }

  copyNPSCode() {
    const textArea = this.codeToNPS.nativeElement;
    textArea.select();
    document.execCommand("copy");
    textArea.setSelectionRange(0, 0);
  }
  copyVisitorAnalyticsCode() {
    const textArea = this.codeToVisitorAnalytics.nativeElement;
    textArea.select();
    document.execCommand("copy");
    textArea.setSelectionRange(0, 0);
  }
  copyAddWhatsupCode() {
    const textArea = this.addToWhatsup.nativeElement;
    textArea.select();
    document.execCommand("copy");
    textArea.setSelectionRange(0, 0);
  }
  copyContactCode() {
    const textArea = this.codeToContact.nativeElement;
    textArea.select();
    document.execCommand("copy");
    textArea.setSelectionRange(0, 0);
  }
  copyAddContactCode() {
    const textArea = this.addToContact.nativeElement;
    textArea.select();
    document.execCommand("copy");
    textArea.setSelectionRange(0, 0);
  }
  copyEntitiesCode() {
    const input = this.entitiesCode.nativeElement;
    input.select();
    document.execCommand("copy");
    input.setSelectionRange(0, 0);
  }
  public passwordForm: FormGroup;
  public webhookForm: FormGroup;
  public businessLogoForm: FormGroup;
  public editProfileForm: FormGroup;
  public isFormSubmitted: boolean;
  public isLinkedInButtonDisabled: boolean = false;
  public isCalendlyButtonDisabled: boolean = false;
  public showCalendlyIntegrationResult: boolean = false;
  public isDeactivate: boolean;
  public isLoading = true;
  public deactivateModal = false;
  public entityApiKey: string;
  public eventsApiKey: string;
  public isGeneratingEventApiKey = false;
  public tab = "companyDetails";
  public isEmail: FormGroup;
  public isMessage: FormGroup;
  public standupForm: FormGroup;
  public currentUser: User | undefined;
  public cuurentFallowUp: any;
  public timezones: any;
  public stagesId: any;
  public templateId: any;
  public emailTemplatesList: any[] = [];
  public eventsList: any[] = [];
  public tasksList: any[] = [];
  public fieldMapsList: any[] = [];
  public defaultTasksList: any[] = [];
  public eventsMappingList: any[] = [];
  public buttonText: string = "Add";
  public addTaskButtonText: string = "Add New Task";
  public isIconActive: boolean = false;
  public iconSrc: string = "../../../assets/images/New/plus.svg";
  public showInnerTabs: boolean = true;
  public showSlackIntegration: boolean = false;
  public showFbIntegration: boolean = false;
  public showLinkedInIntegration: boolean = false;
  public showRazorpayIntegration: boolean = false;
  public showWhatsappIntegration: boolean = false;
  public showContactIntegration: boolean = false;
  public showNPSIntegration: boolean = false;
  public showNPSConfiguration: boolean = false;
  public showVisitorIntegration: boolean = false;
  public showSegmentIntegration: boolean = false;
  public showCalendlyIntegration: boolean = false;
  public showGmailIntegration: boolean = false;
  public segmentEventStatus: string;
  public slackIntegrationUrl: boolean = false;
  public websiteForm: FormGroup;
  public slugNameUrl: boolean = false;
  public isWebsite: boolean = false;

  public widgetsActionStatus: boolean = true;
  public whatAppWidgetStatus: boolean = false;
  public contactFormWidgetStatus: boolean = false;
  public joinWaitlistWidgetStatus: boolean = false;

  public initialSlug = '';
  public websiteUrl: any;
  public websiteDescription: any;
  public websiteSubTitle: any;
  public websiteOwnLink: any;
  public websiteTitle: any;
  productImages: Array<{ url: string, name: string }> = [];


  public list = [
    {
      name: "Mon",
      value: "1"
    },
    {
      name: "Tue",
      value: "2"
    },
    {
      name: "Wed",
      value: "3"
    },
    {
      name: "Thur",
      value: "4"
    },
    {
      name: "Fri",
      value: "5"
    },
    {
      name: "Sat",
      value: "6"
    },
    {
      name: "Sun",
      value: "0"
    }
  ];
  public checkedList: any = [];
  public followupData: any;
  public showButton: any;
  public followupId: string;
  public configData: any;
  public fbPages: { id: string, name: string }[] = [];
  public selectedPage: { id: string, name: string };

  //public razorPayPages: string[] =[];
  public user: any;
  public selectedCurrency: any;
  public selectedMetric : any;
  public selectedModel :any;

  public passwordMatchValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    const password: string = control.get("newPassword")!.value;
    const confirmPassword: string = control.get("reEnterPassword")!.value;
    if (password && confirmPassword && password !== confirmPassword) {
      control.get("reEnterPassword")!.setErrors({ isMismatched: true });
    }
    return password === confirmPassword ? null : { isMismatched: false };
  }
  public webHookUrl: any;
  public tabs: { [key: string]: Tab[] } = {};
  public selectedEvent: string | null = null;
  public eventKeys:any[] = [];
  public uniqueEventsList: any[] = [];
  public tabEvent: string;
  public selectedSpace: any;
  public spacesList: any[] = [];
  public calendly_auth_code:any;
  authWindow: Window | null = null; // Store reference to the popup window


  // Event handlers
  public selectEvent(eventKey: string) {
   this.selectedEvent = eventKey;
   this.tabEvent = eventKey
    if(!this.eventKeys.includes(eventKey) && !this.tabs[this.selectedEvent]) {
      this.addNewRow('NewMapping')
    }
  }

  constructor(
    private notifier: NotifierService,
    public userService: UserService,
    public eventService: EventsService,
    private dataService: DataService,
    private configService: ConfigService,
    private router: Router,
    public authService: AuthService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private helperService: HelperService
  ) {}



  public async ngOnInit() {
    this.initForm();
    this.editProfileForm = new FormGroup(
      {
        profileFormCompanyName: new FormControl('', [
          Validators.required,
        ]),
        currency: new FormControl('', [
          Validators.required,
        ]),
        salesTarget: new FormControl('', [
          Validators.required,
        ]),
        northMetric: new FormControl('', [
          Validators.required,
        ]),
        businessModel: new FormControl('', [
          Validators.required,
        ]),

        profileFormUrl: new FormControl(''),
        profileFormWaNumber: new FormControl(''),
        profileFormAddress: new FormControl(''),
        profileFormCity: new FormControl(''),
        profileFormState: new FormControl(''),
        profileFormCountry: new FormControl(''),
      },
    );
    this.websiteForm = this.fb.group({
      slugName: ['', [Validators.required]],
      websiteTitle: ['', [Validators.required]],
      websiteSubTitle: ['', [Validators.required]],
      websiteOwnLink: ['', [Validators.required]],
      websiteDescription: ['', [Validators.required]],
      imgURL: ['', [Validators.required]]
    });
     this.fetchInitialIsWebsiteValue();
    this.isAdmin = this.authService.isAdmin();
    this.isSuperAdmin = this.authService.isSuperAdmin();
    this.fieldMapFormGroup = new FormGroup({
      fieldName: new FormControl("", [Validators.required]),
      mappingField: new FormControl("", [Validators.required])
    });
    this.eventsList = await this.eventService.getEventsList(true);
    this.configData = await this.configService.getConfigData();
    this.getEventsMappingList();
    this.getEmailTemplatesList();
    this.getDefaultTaskDefsList();
    this.getTaskDefsList();
    this.initializeStandupForm();
    this.getStagesList();
    this.entityData();
    this.isRazorpayConnected();
    this.currentUser = this.userService.getUser();
    const userEntityData = await this.userService.userEntityData();
    this.isAutoTaskEnabled = userEntityData.entityId.isAutoTaskEnabled;
    this.isDefaultTasksEnabled = userEntityData.entityId.isDefaultTasksEnabled;
    this.route.params.subscribe(params => {
      // this.tab = params.tabName || 'spaces';
      this.tab = params.tabName || "companyDetails";

      if(this.tab === "workflows") {
        track("WORKFLOWS");
      }
      if(this.tab === "fieldMapping") {
        track("FIELDMAPPING");
      }
    });
    this.getUserFollowup();
    this.getFieldMapsList();
    // this.getFollowup();
    this.timezones = tz.names();
    this.eventsFormGroup = new FormGroup(
      {
        events: new FormControl('', [
          Validators.required,
        ]),
        actions: new FormControl('', [
          Validators.required,
        ]),
        stages: new FormControl('', [
          Validators.required,
        ]),
        templates: new FormControl('', [
          Validators.required,
        ]),
        toggle: new FormControl(true, [
          Validators.required,
        ]),
      },
    );
    this.tasksFormGroup = new FormGroup(
      {
        stages: new FormControl('', [
          Validators.required,
        ]),
        tasks: new FormControl('', [
          Validators.required,
        ]),
      },
    );

    const authButton = document.getElementById('authButton') as HTMLButtonElement;
    const razorpayAuthButton = document.getElementById('razorpayAuthButton') as HTMLButtonElement;
    const fbAuthStatus = localStorage.getItem('fbAuthStatus');

    if (fbAuthStatus === 'authenticated' && authButton) {
      authButton.disabled = true;
    }

    const razorpayAuthStatus = localStorage.getItem('razorpayAuthStatus');

    if (razorpayAuthStatus === 'authenticated' && authButton) {
      razorpayAuthButton.disabled = true;
    }

    const resp = JSON.parse(localStorage.getItem('entity') || '{}');
    this.logoURL = resp.logo || null;

    const userResponse =  this.dataService.getFromServer('users/' + this.authService.getUser().objectId,{include: 'entityId'});
    userResponse.then(async (res) => {
      if(res){
        this.user = res;
        await this.checkCalendlyConnectionExist();
        const companyName = res.entityId && res.entityId.name ? res.entityId.name : '';
        this.selectedCurrency = res.entityId && res.entityId.currency ? res.entityId.currency : '';
        this.selectedMetric = res.entityId && res.entityId.northStarMetric ? res.entityId.northStarMetric : '';
        this.selectedModel = res.entityId && res.entityId.businessModel ? res.entityId.businessModel : '';
        const salesTarget = res.entityId && res.entityId.salesTarget ? res.entityId.salesTarget : '';
        const websiteUrl = res.entityId && res.entityId.websiteUrl ? res.entityId.websiteUrl : '';
        const whatsAppNumber = res.entityId && res.entityId.whatsappNumber ? res.entityId.whatsappNumber : '';
        const address = res.entityId && res.entityId.address ? res.entityId.address : '';
        const city = res.entityId && res.entityId.city ? res.entityId.city : '';
        const state = res.entityId && res.entityId.state ? res.entityId.state : '';
        const country = res.entityId && res.entityId.country ? res.entityId.country : '';

        if(res){
          this.editProfileForm.patchValue({ profileFormCompanyName: companyName,
            currency:  this.selectedCurrency, salesTarget: salesTarget,
            profileFormUrl: websiteUrl, profileFormWaNumber: whatsAppNumber, profileFormAddress: address, profileFormCity: city,
            profileFormState: state, profileFormCountry: country,northMetric:this.selectedMetric,
            businessModel: this.selectedModel
          });
        }
      }
    });
    this.isLinkedInButtonDisabled = !!userEntityData.entityId.LinkedinToken || false;
  }

  public async saveProfile(event: Event){
    try {
      const companyName = this.editProfileForm.get('profileFormCompanyName')!.value;
      const currency = this.selectedCurrency;
      const northMetric = this.selectedMetric;
      const businessModel = this.selectedModel;
      const salesTarget = this.editProfileForm.get('salesTarget')!.value;
      const websiteUrl = this.editProfileForm.get('profileFormUrl')!.value;
      const whatsAppNumber = this.editProfileForm.get('profileFormWaNumber')!.value;
      const address = this.editProfileForm.get('profileFormAddress')!.value;
      const city = this.editProfileForm.get('profileFormCity')!.value;
      const state = this.editProfileForm.get('profileFormState')!.value;
      const country = this.editProfileForm.get('profileFormCountry')!.value;

      if(!companyName){
        this.notifier.notify('error', 'Company Name cannot be empty!');
        return;
      }
      if(!northMetric){
        this.notifier.notify('error', 'North Star Metric Cannot be empty');
        return;
      }
      if(!businessModel){
        this.notifier.notify('error', 'Business Model Cannot be empty');
        return;
      }
      if(!currency){
        this.notifier.notify('error', 'Currency Cannot be empty');
        return;
      }
      if(!salesTarget){
        this.notifier.notify('error', 'Sales Target Cannot be empty');
        return;
      }
      if(this.user.entityId){
        const entityParams = {
          entityId: this.user.entityId.objectId,
          companyName: this.editProfileForm.get('profileFormCompanyName')!.value,
          currency: this.selectedCurrency,
          salesTarget: +this.editProfileForm.get('salesTarget')!.value,
          northStarMetric: this.selectedMetric,
          businessModel: this.selectedModel,
          isProfileCompleted: true,
          websiteUrl: websiteUrl ? websiteUrl : '',
          whatsappNumber: whatsAppNumber ? String(whatsAppNumber) : '',
          address: address ? address : '',
          city: city ? city : '',
          state: state ? state : '',
          country: country ? country : ''
        };
        await this.dataService.postToServer(
          'functions/updateEntityData',
          entityParams,
        );
        const response = await this.dataService.getFromServer('classes/Entity/' + this.user.entityId.objectId);
        localStorage.setItem('entity', JSON.stringify(response));
        localStorage.setItem("currencyPrefix", this.selectedCurrency);
      }
      const objectId = this.authService.getUser().objectId;
      if(objectId){
        const userUpdateRequestData = {
          objectId
        }
        const response = await this.userService.editUserSave(userUpdateRequestData);
        if (response) {
          this.notifier.notify('success', 'Company Details Saved Successfully!!!');
        }
      }
    } catch (error) {
      this.notifier.notify('error', getErrorMessage(error) + event);
    }
  }

  public fbButtonLabel(): string {
    return this.fbPages.length > 0 ? 'Update connected Pages' : 'Connect with Facebook';
  }

  public async getFBSubscribedPages() {
    try {
      const resp = await this.dataService.postToServer(
        'functions/get-fb-subscribed-pages', {});
      this.fbPages = resp.result;
    } catch(e) {
      this.notifier.notify('error', e)
    }
  }

  public async fetchInitialIsWebsiteValue() {
    try {
      const entityObject = this.authService.getUser();
      if (entityObject) {
        const response = await this.dataService.getFromServer('classes/Entity/' + entityObject.entityId.objectId);
        if (response && response.isWebsite !== undefined) {
          this.isWebsite = response.isWebsite;
          if (this.isWebsite) {
            this.initialSlug = response.profileSlug || '';
            this.websiteForm.patchValue({
              slugName: response.profileSlug || '',
              websiteTitle: response.websiteTitle || '',
              websiteSubTitle: response.websiteSubTitle || '',
              websiteDescription: response.websiteDescription || '',
              websiteOwnLink: response.websiteOwnLink || '',
              widgetsActionStatus: response.widgetsActionStatus || '',
              whatAppWidgetStatus: response.whatAppWidgetStatus || '',
              contactFormWidgetStatus: response.contactFormWidgetStatus || '',
              joinWaitlistWidgetStatus: response.joinWaitlistWidgetStatus || ''
            });
            this.imgURL = response.entityImage ? response.entityImage.url : '';
            this.productImages = response.productImages ? response.productImages.map((img: any) => ({
              url: img.url,
              name: img.name
            })) : [];

            this.websiteForm.updateValueAndValidity();
          }
        }
      }
    } catch (error) {
      this.notifier.notify('error', 'Unable to fetch the initial isWebsite value');
      console.error('Error fetching initial value:', error);
    }
  }

  private convertToBoolean(value: string | boolean): boolean {
    if (typeof value === 'boolean') {
      return value;
    }
    return value === 'true'; // Adjust this based on how values are represented in your form
  }

  public async addSlugIntegrations() {
    this.isFormSubmitted = true;
    const profileSlug = this.websiteForm.value.slugName;
    const websiteDescription = this.websiteForm.value.websiteDescription;
    const websiteSubTitle = this.websiteForm.value.websiteSubTitle;
    const websiteTitle = this.websiteForm.value.websiteTitle;
    const websiteOwnLink = this.websiteForm.value.websiteOwnLink;
    const productImages = this.productImages.map(img => ({
      url: img.url,
      name: img.name,
      __type: 'File'
    }));

    const joinWaitlistWidgetStatus = this.convertToBoolean(this.websiteForm.value.joinWaitlistWidgetStatus);
    const contactFormWidgetStatus = this.convertToBoolean(this.websiteForm.value.contactFormWidgetStatus);
    const whatAppWidgetStatus = this.convertToBoolean(this.websiteForm.value.whatAppWidgetStatus);

    try {
      const data = {
        profileSlug,
        websiteTitle,
        websiteDescription,
        websiteSubTitle,
        isWebsite: this.isWebsite,
        widgetsActionStatus: this.widgetsActionStatus,
        joinWaitlistWidgetStatus,
        contactFormWidgetStatus,
        whatAppWidgetStatus,
        productImages,
        websiteOwnLink
      };

      const entityObject = this.authService.getUser();
      if (entityObject) {
        const response = await this.dataService.updateToServer(
          'classes/Entity/' + entityObject.entityId.objectId,
          data
        );
        if (response) {
          this.notifier.notify('success', 'Your Landing Page is Online');
        }
      }
    } catch (error) {
      this.notifier.notify('error', 'Unable to add your Landing Page');
      console.error('Error updating slug integrations:', error);
    } finally {
      this.isFormSubmitted = false;
    }
  }


  public async onSelectFile(event: Event) {
    this.entityImage = true;
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      if((files[0].size / (1024 * 1024)) > 1){
        (event.target as HTMLInputElement).value = '';
        this.notifier.notify('error', 'Please select a file size less than 1 MB.');
        this.entityImage = false;
        return;
      }
      else{
        this.imgURL = await this.dataService.uploadFile(files[0]);
        try {
          const data = {
            entityImage: {
              name: this.imgURL.name,
              url: this.imgURL.url,
              __type: 'File',
            }
          };
          const entityObject = this.authService.getUser();
          if (entityObject) {
            const response = await this.dataService.updateToServer(
              "classes/Entity/" + entityObject.entityId.objectId,
              data
            );
            if (response) {
              this.notifier.notify("success", "Entity Image Updated");
            }
          }
        } catch (error) {
          this.notifier.notify("error", "Unable to add your website URL");
        }
      }
    }
    this.entityImage = false;
  }

  public async onSelectMultipleFiles(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      const uploadedFiles: Array<{ url: string, name: string }> = await this.dataService.uploadFiles(files);
      this.productImages = (this.productImages as Array<{ url: string, name: string }>).concat(
        uploadedFiles.map(file => ({ url: file.url, name: file.name }))
      );
    }
  }

  public deleteImage(index: number) {
    if (index < 0 || index >= (this.productImages as Array<{ url: string, name: string }>).length) {
      this.notifier.notify("error", "Invalid index for image deletion");
      return;
    }

    (this.productImages as Array<{ url: string, name: string }>).splice(index, 1);
    this.notifier.notify("success", "Image removed successfully");
  }

  public async viewProductPage(){
    const profileSlug = this.websiteForm.value.slugName;
    try{
      if(profileSlug){
        // window.open(`http://localhost:3000/profile/${profileSlug}`, '_blank');
        window.open(`https://inleads.ai/landing/${profileSlug}`, '_blank');
      }
    }catch(e){
      console.log(e);
    }

  }

  async toggleWebsite(event: any) {
    this.isWebsite = event.target.checked;
    await this.saveToggleState();
    if (this.isWebsite) {
      this.fetchInitialIsWebsiteValue();
    } else {
      this.websiteForm.controls['slugName'].reset();
      this.initialSlug = '';
    }
  }

  async widgetsCallToActions(event: any) {
    this.widgetsActionStatus = event.target.checked;
    await this.saveToggleState();
  }

  async wappWidgetStatus(event: any) {
    this.whatAppWidgetStatus = event.target.checked;
    await this.saveToggleState();
  }

  async formWidgetStatus(event: any, type: string) {
    const checked = event.target.checked;
    if (type === 'cf') {
      this.contactFormWidgetStatus = checked;
      if (checked) {
        this.joinWaitlistWidgetStatus = false;
      }
    } else if (type === 'jw') {
      this.joinWaitlistWidgetStatus = checked;
      if (checked) {
        this.contactFormWidgetStatus = false;
      }
    }
    await this.saveToggleState();
  }

  public async saveToggleState() {
    try {
      const data = {
        isWebsite: this.isWebsite,
        widgetsActionStatus: this.widgetsActionStatus,
        whatAppWidgetStatus: this.whatAppWidgetStatus,
        contactFormWidgetStatus: this.contactFormWidgetStatus,
        joinWaitlistWidgetStatus: this.joinWaitlistWidgetStatus || false
      };

      const entityObject = this.authService.getUser();

      if (entityObject && entityObject.entityId && entityObject.entityId.objectId) {
        console.log('Sending data to server:', data);

        const response = await this.dataService.updateToServer(
          `classes/Entity/${entityObject.entityId.objectId}`,
          data
        );

        console.log('Server response:', response);

        if (response && data.isWebsite === false) {
          this.notifier.notify("success", "Your Landing Page is Offline");
        } else if (response && data.widgetsActionStatus !== false) {
          this.notifier.notify("success", "Call to actions enabled");
        } else if (response && data.widgetsActionStatus === false) {
          this.notifier.notify("error", "Call to actions disabled");
        } else {
          this.notifier.notify("error", "Unexpected response from the server");
        }
      } else {
        throw new Error('Invalid entity object');
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error updating the landing page toggle state:', error.message);
      } else {
        console.error('Unknown error updating the landing page toggle state:', error);
      }
      this.notifier.notify("error", "Unable to update the Landing page toggle state");
    }
  }




  get isInitialSlugEmpty() {
    return !this.initialSlug;
  }

  get slugName() {
    return this.websiteForm.get('slugName');
  }

  private initializeStandupForm() {
    this.standupForm = this.fb.group({
      question1: ["", Validators.required],
      question2: ["", Validators.required],
      question3: ["", Validators.required],
      timezonesList: ["", Validators.required],
      days: this.fb.array([
        this.fb.control(false),
        this.fb.control(false),
        this.fb.control(false),
        this.fb.control(false),
        this.fb.control(false),
        this.fb.control(false),
        this.fb.control(false)
      ]),
      standup_time: new FormControl("", [Validators.required]),
      status: new FormControl("", [Validators.required]),
      schedule: new FormControl("", [Validators.required])
    });
  }

  private initForm() {
    this.webhookForm = new FormGroup({
      slackWebhookUrl: new FormControl("", [
        Validators.required,
        Validators.pattern(
          /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
        )
      ])
    });
    this.passwordForm = new FormGroup(
      {
        newPassword: new FormControl("", [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@$%^&*()_+-=|{[\]}:;"'?/>.<,])(?=.{8,})/
          )
        ]),
        reEnterPassword: new FormControl("", [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@$%^&*()_+-=|{[\]}:;"'?/>.<,])(?=.{8,})/
          )
        ]),
        isEmail: new FormControl("", [Validators.required]),
        isMessage: new FormControl("", [Validators.required])
      },
      this.passwordMatchValidator
    );
  }

  public async toggleAutoTasks(event: any) {
    event.stopPropagation();
    const entityObject = this.authService.getUser();

    if (entityObject) {
      const updateData = {
        isAutoTaskEnabled: event.target.checked
      };

      try {
        const response = await this.dataService.updateToServer(
          "classes/Entity/" + entityObject.entityId.objectId,
          updateData,
        );
        console.log('Auto task updated successfully:', response);
      } catch (error) {
        console.error('Error updating data:', error);
      }
    }
  }

  public async toggleDefaultTasks(event: any) {
    event.stopPropagation();

    const entityObject = this.authService.getUser();

    if (entityObject) {
      const updateData = {
        isDefaultTasksEnabled: event.target.checked
      };

      try {
        const response = await this.dataService.updateToServer(
          "classes/Entity/" + entityObject.entityId.objectId,
          updateData,
        );
        console.log('Default task updated successfully:', response);
      } catch (error) {
        console.error('Error updating data:', error);
      }
    }
  }

  toggleNewPasswordFieldType() {
    this.showNewPassword = !this.showNewPassword;
  }

  toggleReEnterPasswordFieldType() {
    this.showReEnterPassword = !this.showReEnterPassword;
  }

  public async formSubmitted() {
    const newPassword = this.passwordForm.value.newPassword;
    const reEnterPassword = this.passwordForm.value.reEnterPassword;
    if (newPassword !== reEnterPassword) {
      this.notifier.notify('error', 'Password does not match');
      return;
    }
    this.isFormSubmitted = true;
    try {
      await this.userService.updateUserPassword(newPassword);
      this.passwordForm.reset();
      this.notifier.notify('success', 'Password updated Successfully!!');
    } catch (e) {
      if (e instanceof Error) {
        this.notifier.notify('error', e.message);
      }
    }
    this.isFormSubmitted = false;
  }

  public async deactivate() {
    this.isDeactivate = true;
    const user = this.userService.getUser();
    const temp: updateUserRequestParams = {
      objectId: user!.objectId,
      isActive: false,
    };
    try {
      await this.userService.editUserSave(temp);
      this.notifier.notify('success', 'Account Deactivated');
      this.deactivateModal = false;
      await this.logoutUser();
    } catch (e) {
      if (e instanceof Error) {
        this.notifier.notify('error', e.message);
      }
    }
    this.isDeactivate = false;
  }

  public async logoutUser() {
    const userDetails = {};
    await this.dataService.postToServer("/logout", userDetails);
    this.helperService.cleanupLeadsLiveQueries();
    unset();
    await this.dataService.cleanHeaders();
    localStorage.clear();
    sessionStorage.clear();
    await this.router.navigateByUrl('');
  }

  public async getSpacesList(spaceId?: string ) {
    let spacesQuery: any;
    if(!this.authService.isSuperAdmin()){
      spacesQuery = {
        where: {
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
          isArchived: { $ne : true },
        },
      };
      if (spaceId) {
        spacesQuery.where['objectId'] = spaceId
      }
    }
    const resp = await this.dataService.getFromServer(
      'classes/Spaces',
      spacesQuery,
    );
    this.spacesList = resp.results;
    
     return resp.results.length > 0 ? this.spacesList[0] : null;
   
  }

  public async getStagesList() {
    const resp1 = await this.getSpacesList()
    console.log('resp',resp1)
    let stageQuery = {
      where: {
        $or: [
          {
            entity: {
              __type: "Pointer",
              className: "Entity",
              objectId: this.authService.getUser().entityId.objectId,
            },
          },
          {
            entity: { $exists: false },
          },
        ],
      },
      order: "order",
    };
    
    if (resp1.objectId) {
      stageQuery.where = {
        ...stageQuery.where, 
        space: {
          __type: "Pointer",
          className: "Spaces",
          objectId: resp1.objectId,
        },
      } as any;
    }
  
    const resp = await this.dataService.getFromServer(
      "classes/Stages",
      stageQuery,
    );
    this.stagesId = resp.results; 
  }

  public async getEmailTemplatesList(){
    try {
    let emailTemplateQuery;
    if(!this.authService.isSuperAdmin()){
      emailTemplateQuery = {
        where: {
          fromUser: {
            __type: 'Pointer',
            className: '_User',
            objectId: this.authService.getUser().objectId
          },
        },
        include: 'toContact',
    };
    }
    const resp = await this.dataService.getFromServer(
      'classes/EmailTemplates', emailTemplateQuery,
    );
    // this.emailTemplatesList = resp.results.map((templateName : any) => ({ ...templateName }));
    this.emailTemplatesList = resp.results;
  }
  catch(error) {
    this.notifier.notify('error', 'Getting error while checking for Email Templates');
    return;
    }
  }

  public async entityData() {
    try {
      const userParams = {
        include: "entityId"
      };
      const entityDetails = await this.dataService.getFromServer(
        "classes/_User/" + this.authService.getUser().objectId,
        userParams,
      );

      this.passwordForm.patchValue({
        isEmail: entityDetails.sendEmail ? true : false,
        isMessage: entityDetails.sendSMS ? true : false,
      });
      this.entityApiKey =
        entityDetails.entityId && entityDetails.entityId.apiKey
          ? entityDetails.entityId.apiKey
          : "N/A";
      this.eventsApiKey =
        entityDetails.entityId && entityDetails.entityId.eventsApiKey
          ? entityDetails.entityId.eventsApiKey
          : "";
    } catch (_) {}

    this.isLoading = false;
  }
  public emailsTrue(item: boolean) {
    console.log(item);
  }

  public async saveSettings(){
    const isEmail = this.passwordForm.value.isEmail;
    const isMessage = this.passwordForm.value.isMessage;
    try {
      const data = {
        sendEmail: isEmail,
        sendSMS: isMessage
      };
      const objectId =  this.authService.getUser().objectId;
      if(objectId){
        const response = await this.dataService.updateToServer('users/' + objectId, data);
        if (response) {
          this.notifier.notify('success', 'Settings Saved Successfully!!!');
        }
      }
    } catch (error) {
      this.notifier.notify('error', '');
    }
  }

  onCheckboxChange(option: any, event: any) {
    if (event.target.checked) {
      this.checkedList.push(option!.value);
    } else {
      for (var i = 0; i < this.list.length; i++) {
        if (this.checkedList[i] == option.value) {
          this.checkedList.splice(i, 1);
        }
      }
    }
  }

  public async createStandup() {
    const standupTime = this.standupForm.get("standup_time")!.value;
    const mom = moment(standupTime, "HHmmss");
    const schedule =
      mom.format("mm HH") + " * * " + [this.checkedList].join(",");
    const status = this.standupForm.get("status")!.value;
    if (!standupTime) {
      this.notifier.notify('error', 'standup Time cannot empty');
      return;
    }
    if (!this.checkedList) {
      this.notifier.notify('error', 'Days cannot be empty');
      return;
    }

    let questionsArrayPointers: any = [];
    const entityId = this.currentUser!.entityId!.objectId;
    const userId = this.authService.getUser().objectId;
    let allQuestionsFilled = true;
    for (const key of Object.keys(this.standupForm.controls)) {
      if (key.includes("question")) {
        if (!this.standupForm.get(key)!.value) {
          this.notifier.notify('error', `${key} cannot be empty`);
          allQuestionsFilled = false;
          return;
        }
      }
    }
    if (!allQuestionsFilled) return;
    for (const key of Object.keys(this.standupForm.controls)) {
      if (key.includes("question")) {
        if (!this.standupForm.get(key)!.value) {
          this.notifier.notify('error', `${key} cannot be empty`);
          return;
        }
        const qParams = {
          name: this.standupForm.get(key)!.value,
          entity: {
            __type: "Pointer",
            className: "Entity",
            objectId: entityId
          },
          user: {
            __type: "Pointer",
            className: "_User",
            objectId: userId
          },
        };
        const response: any = await this.dataService.postToServer(
          "classes/Questions",
          qParams,
        );
        if (response && response.objectId) {
          questionsArrayPointers.push({
            __type: "Pointer",
            className: "Questions",
            objectId: response.objectId
          });
        }
      }
    }

    const standupParams = {
      questions: questionsArrayPointers,
      schedule,
      status,
      entity: {
        __type: "Pointer",
        className: "Entity",
        objectId: this.currentUser!.entityId!.objectId
      },
      user: {
        __type: "Pointer",
        className: "_User",
        objectId: this.authService.getUser().objectId
      },
    };

    const followupData = await this.getFollowup();
    if (!followupData) {
      try {
        const response = await this.dataService.postToServer(
          "classes/FollowUps/",
          standupParams
        );
        if (response && response.objectId) {
          this.showButton = true;
          this.getUserFollowup();
        }
        this.notifier.notify('success', 'New FollowUp Created');
      } catch (e) {
        this.notifier.notify('error', getErrorMessage(e));
      }
    } else {
      this.notifier.notify('error', 'Already You have FollowUp');
    }
  }

  public async getFollowup() {
    if (this.authService.getUser().objectId) {
      try {
        const paramsData: any = {
          where: {
            user: {
              __type: "Pointer",
              className: "_User",
              objectId: this.authService.getUser().objectId
            }
          },
          include: "questions"
        };
        const resp = await this.dataService.getFromServer(
          "classes/FollowUps/",
          paramsData
        );
        if (resp.results && resp.results.length) {
          this.showButton = resp.results.length;
          return resp.results;
        } else {
          return;
        }
      } catch (e) {
        this.notifier.notify('error', 'Invalid User');
      }
    } else {
      this.notifier.notify('error', 'error');
    }
  }

  public async getUserFollowup() {
    try {
      let arr, k: any, temp_str;
      const followupData = await this.getFollowup();
      if (followupData && followupData[0]) {
        let weeks: any[] = ["1", "2", "3", "4", "5", "6", "0"];
        this.followupId = followupData[0].objectId || "";
        if (followupData[0].schedule) {
          temp_str = followupData[0].schedule;
          k = temp_str.split(/[, ]+/);
          arr = weeks.map((item: any) => {
            const item2 = k.find((i: any) => i === item);
            if (item2 !== undefined) {
              this.checkedList.push(item2);
              return item2;
            }
          });
        }
        const entityObject = this.authService.getUser();
        const response = await this.dataService.getFromServer(
          "classes/Entity/" + entityObject.entityId.objectId
        );
        const questions: any = {};
        followupData[0].questions.forEach((que: any, i: number) => {
          this.questionsInServer[`question${i + 1}`] = que;
          questions[`question${i + 1}`] = que.name || "";
        });
        this.standupForm.patchValue({
          ...questions,
          days: arr ? arr : "",
          standup_time:
            k && k[0] && k[1] ? k[1] + ":" + k[0] : moment().format("HH:mm"),
          status: followupData[0]!.status === true ? true : false || false,
        });
        this.webHookUrl = response.slackIntegrationUrl
          ? response.slackIntegrationUrl
          : "";
        this.webhookForm.patchValue({
          slackWebhookUrl: this.webHookUrl
        });
        this.websiteUrl = response.profileSlug
        ? response.profileSlug
        : "";
        this.websiteDescription = response.websiteDescription
        ? response.websiteDescription
        : "";
        this.websiteSubTitle = response.websiteSubTitle
        ? response.websiteSubTitle
        : "";
        this.websiteOwnLink = response.websiteOwnLink
        ? response.websiteOwnLink
        : "";
        this.websiteTitle = response.websiteTitle
        ? response.websiteTitle
        : "";
        this.productImages = response.productImages
        ? response.productImages
        : "";
        this.imgURL = response.entityImage && response.entityImage.url
        ? response.entityImage.url
        : "";
        this.widgetsActionStatus = response.widgetsActionStatus
        ? response.widgetsActionStatus
        : "";
        this.whatAppWidgetStatus = response.whatAppWidgetStatus
        ? response.whatAppWidgetStatus
        : "";
        this.contactFormWidgetStatus = response.contactFormWidgetStatus
        ? response.contactFormWidgetStatus
        : "";
        this.joinWaitlistWidgetStatus = response.joinWaitlistWidgetStatus
        ? response.joinWaitlistWidgetStatus
        : "";
        this.websiteForm.patchValue({
          slugName: this.websiteUrl,
          entityImage: this.entityImage,
          websiteDescription: this.websiteDescription,
          websiteSubTitle: this.websiteSubTitle,
          websiteOwnLink: this.websiteOwnLink,
          websiteTitle: this.websiteTitle,
          contactFormWidgetStatus: this.contactFormWidgetStatus,
          whatAppWidgetStatus: this.whatAppWidgetStatus,
          widgetsActionStatus: this.widgetsActionStatus,
          joinWaitlistWidgetStatus: this.joinWaitlistWidgetStatus,
          imgURL: this.imgURL
        });
      }
    } catch (e) {
      this.notifier.notify('error', getErrorMessage(e));
    }
  }

  async updateQuestion(id: string, question: string) {
    try {
      const res = await this.dataService.updateToServer(
        "classes/Questions/" + id,
        {
          name: question
        }
      );
      return res;
    } catch (e) {
      if (e instanceof Error) {
        this.notifier.notify('error', e.message);
      }
      return null;
    }
  }

  public async updateStandup(_event: Event) {
    Object.keys(this.questionsInServer).forEach(async (key: string) => {
      if (!this.standupForm.get(key)!.value) {
        this.notifier.notify('error', `${key} cannot be empty`);
        return;
      }
      if (
        this.standupForm.get(key)!.value !== this.questionsInServer[key].name
      ) {
        const res = await this.updateQuestion(
          this.questionsInServer[key].objectId,
          this.standupForm.get(key)!.value
        );
        if (!res) return;
      }
    });
    const standupTime = this.standupForm.get("standup_time")!.value;
    const mom = moment(standupTime, "HHmmss");
    const schedule =
      mom.format("mm HH") + " * * " + [this.checkedList].join(",");
    const data = {
      schedule,
      status: this.standupForm.get("status")!.value
    };
    if (this.followupId) {
      try {
        const response = await this.dataService.updateToServer(
          "classes/FollowUps/" + this.followupId,
          data
        );
        if (response) {
          this.notifier.notify('success', 'FollowUp Updated Successfully!!!');
        } else {
          this.notifier.notify('error', 'Error while editing FollowUp.');
        }
      } catch (e) {
          this.notifier.notify('error', getErrorMessage(e));
      }
    }
  }

  public async addSlackIntegrations() {
    const slackIntegrationUrl = this.webhookForm.value.slackWebhookUrl;
    this.isFormSubmitted = true;
    try {
      const data = {
        slackIntegrationUrl
      };
      const entityObject = this.authService.getUser();
      if (entityObject) {
        const response = await this.dataService.updateToServer(
          "classes/Entity/" + entityObject.entityId.objectId,
          data
        );
        if (response) {
          this.notifier.notify('success', 'Webhook Url Saved Successfully!!!');
        }
      }
    } catch (error) {
      this.notifier.notify('error', 'Unable to Add your Webhook Url');
    }
    this.isFormSubmitted = false;
  }

  public async saveMapping() {
    const eventName = this.eventsFormGroup.controls.events.value;
    const action = this.eventsFormGroup.controls.actions.value;
    const stageObjectId = this.eventsFormGroup.controls.stages.value;
    const mailTemp = this.eventsFormGroup.controls.templates.value;
    const enableMapping = this.eventsFormGroup.controls.toggle.value;

    if (eventName.length <= 0) {
      this.notifier.notify('error', 'Please Select Event');
      return;
    }
    if (action.length <= 0) {
      this.notifier.notify('error', 'Please Select Action');
      return;
    }

    // if (stageObjectId.length <= 0) {
    //   this.notifier.notify('error', 'Please Select Stage');
    //   return;
    // }
    // if (mailTemp.length <= 0) {
    //   this.notifier.notify('error', 'Please Select Template');
    //   return;
    // }

    const eventMaps = {
      eventName,
      action,
      stageObjectId,
      enableMapping,
      isActive: true,
      entityObjectId : this.authService.getUser().entityId.objectId,
      templateObjectId: mailTemp,
    }
    await this.dataService.postToServer('functions/saveEventMapping', eventMaps);
    this.isShowNewWorkflowAddingDiv = false;
    this.eventsFormGroup.get('events')!.setValue('');
    this.eventsFormGroup.get('actions')!.setValue('');
    this.eventsFormGroup.get('stages')!.setValue('');
    this.eventsFormGroup.get('templates')!.setValue('');
    this.eventsFormGroup.get('toggle')!.setValue(true);
    this.getEventsMappingList();

    this.buttonText = "Add";
    this.isIconActive = !this.isIconActive;
    if (this.isIconActive) {
      this.iconSrc = "../../../assets/images/New/cancel-cross.svg";
    } else {
      this.iconSrc = "../../../assets/images/New/plus.svg";
    }
  }

  public async addWorkflow() {
    this.isShowNewWorkflowAddingDiv = !this.isShowNewWorkflowAddingDiv;
    this.eventsFormGroup.get("events")!.setValue("");
    this.eventsFormGroup.get("actions")!.setValue("");
    this.eventsFormGroup.get("stages")!.setValue("");
    this.eventsFormGroup.get("toggle")!.setValue(true);
    if (this.buttonText === "Add") {
      this.buttonText = "Cancel";
    } else {
      this.buttonText = "Add";
    }
    this.isIconActive = !this.isIconActive;
    if (this.isIconActive) {
      this.iconSrc = "../../../assets/images/New/cancel-cross.svg";
    } else {
      this.iconSrc = "../../../assets/images/New/plus.svg";
    }
  }


  public async onEdit(eventObject: any) {
    eventObject.disabled = false;
  }

  public async onSave(eventObject: any) {
    const obj = {
      eventName: eventObject.event,
      action: eventObject.action,
      stageObjectId: eventObject.stage ? eventObject.stage.objectId : '',
      templateObjectId: eventObject.templateId ? eventObject.templateId.objectId : '',
      eventMappingObjectId: eventObject.objectId,
      enableMapping: eventObject.enableMapping
    };
    await this.dataService.postToServer("functions/updateEventMapping", obj);
    //eventObject.disabled = true;
    this.notifier.notify('success', 'Changes saved successfully!!!');
  }

  public async onDelete(eventObject: any) {
    try {
      await this.dataService.updateToServer(
        "classes/EventMapping/" + eventObject.objectId,
        { isActive: false }
      );
      this.notifier.notify('success', 'Event deleted successfully!!!');
      await this.getEventsMappingList();
    } catch (e) {
      console.log('error', 'Error deleting event');
    }
  }

  public async onCancel(eventObject: any) {
    eventObject.disabled = true;
    this.getEventsMappingList();
  }

  public async getEventsMappingList() {
    this.getEmailTemplatesList();
    let eventsMappingQuery;
    if (!this.authService.isSuperAdmin()) {
      eventsMappingQuery = {
        include: 'templateId,stage',
        where: {
          entity: {
            __type: "Pointer",
            className: "Entity",
            objectId: this.authService.getUser().entityId.objectId
          },
          isActive: true,
        },
      };
    }
    const resp = await this.dataService.getFromServer(
      "classes/EventMapping",
      eventsMappingQuery
    );

    let tempArray : any = [];
    resp.results.forEach(async (event : any) => {
      tempArray.push({
        ...event,
        templateId: event.templateId || {objectId: 'null'}, 
        disabled : true,
        stage: event.stage || {objectId: 'null'}
      });
    });
    this.eventsMappingList = JSON.parse(JSON.stringify(tempArray));
    if (this.eventsMappingList.length) {
      //@ts-ignore
      this._tabs = this.eventsMappingList.reduce((acc, item) => {
        // Initialize the event group if it doesn't exist
        if (!acc[item.event]) {
          acc[item.event] = [];
        }
        
        // Push the item into the appropriate event group
        acc[item.event].push(item);
    
        // Sort the group by `order`, treating missing `order` as 0
        acc[item.event].sort((a: Tab, b: Tab) => (a.order || 0) - (b.order || 0));
    
        // After sorting, check if the first tab's delay is not set and set it to 0
        if (acc[item.event].length > 0 && acc[item.event][0].delay == null) {
          acc[item.event][0].delay = 0; // Set delay to 0 if it's not set
        }
    
        return acc;
      }, {} as { [key: string]: Tab[] });
  
      //@ts-ignore
       this.tabs = this._tabs
       //@ts-ignore
      this.eventKeys =Object.keys(this._tabs);

      if(this.eventKeys.length && this.eventsList.length) {  
        let arrayNames = this.eventsList.map(obj => obj.name);
        const ignoreItems = [ "INIT", "Identify" ];

        this.uniqueEventsList = arrayNames.filter(
          item => !ignoreItems.includes(item) && !this.eventKeys.includes(item)
        );
        if((!this.selectedEvent) || (this.uniqueEventsList && this.selectedEvent && this.uniqueEventsList.includes(this.selectedEvent))) {
          // this.selectedEvent = this.eventKeys[0]
        }
      }else {
        this.uniqueEventsList = this.eventKeys.map(obj => obj.name);
      }

    } else {
      this.eventsList = await this.eventService.getEventsList(true);
      this.uniqueEventsList = this.eventsList.map(obj => obj.name);
    }
  }


  public isEventSelected(eventName: string): boolean {
    return this.eventsMappingList.some(item => item.event === eventName);
  }

  helpButton() {
    this.showGuide = true;
    this.showWorkflow = false;
  }

  backButton() {
    this.showGuide = false;
    this.showWorkflow = true;
  }

  public async updateEventsApiKey() {
    try {
      this.isGeneratingEventApiKey = true;
      const response = await this.dataService.postToServer(
        "functions/updateEventsApiKey",
        {},
      );
      this.eventsApiKey = response.result;
      this.isGeneratingEventApiKey = false;
    } catch (e) {
      this.isGeneratingEventApiKey = false;
      this.notifier.notify('error',' "Unable to update Api key"');
    }
  }

  public async getSpaces() {
    const spacesQuery = {
      where: {
        entity: {
          __type: "Pointer",
          className: "Entity",
          objectId: this.authService.getUser().entityId.objectId
        },
      },
    };
    const resp = await this.dataService.getFromServer(
      "classes/Spaces",
      spacesQuery,
    );
    const spacesList = resp.results;
    return spacesList[0].templateId;
  }

  public async getDefaultTaskDefsList() {
    try {
      const resp = await this.dataService.postToServer(
        "functions/getTaskDefs",
        { template: await this.getSpaces() }
      );
      this.defaultTasksList =
        resp.result && resp.result.map((task: any) => ({ ...task }));
    } catch (error) {
      this.notifier.notify(
        'error',
        'Getting error while checking for your default tasks'
      );
      return;
    }
  }

  public async getTaskDefsList() {
    try {
      const resp = await this.dataService.postToServer(
        "functions/getTaskDefs",
        {
          entity: {
            __type: "Pointer",
            className: "Entity",
            objectId: this.authService.getUser().entityId.objectId
          },
        },
      );
      this.tasksList =
        resp.result && resp.result.map((task: any) => ({ ...task }));
    } catch (error) {
      this.notifier.notify(
        'error',
        'Getting error while checking for your configured default tasks'
      );
      return;
    }
  }

  public async addNewTask() {
    this.isShowNewTaskAddingDiv = !this.isShowNewTaskAddingDiv;
    this.tasksFormGroup.get("tasks")!.setValue("");
    this.tasksFormGroup.get("stages")!.setValue("");
    if (this.addTaskButtonText === "Add New Task") {
      this.addTaskButtonText = "Cancel";
    } else {
      this.addTaskButtonText = "Add New Task";
    }
    this.isIconActive = !this.isIconActive;
    if (this.isIconActive) {
      this.iconSrc = "../../../assets/images/New/cancel-cross.svg";
    } else {
      this.iconSrc = "../../../assets/images/New/plus.svg";
    }
  }

  public async saveTaskDefs() {
    try {
      const taskName = this.tasksFormGroup.controls.tasks.value;
      const stageName = this.tasksFormGroup.controls.stages.value;
      if (taskName.length <= 0) {
        this.notifier.notify('error', 'Please Enter Task');
        return;
      }
      if (stageName.length <= 0) {
        this.notifier.notify('error', 'Please Select Stage');
        return;
      }
      const templateQuery = {
        where: {
          name: stageName
        }
      };
      const resp = await this.dataService.getFromServer(
        "classes/Stagedefs",
        templateQuery
      );
      this.templateId = resp.results[0].template;
      const stagedefId = resp.results[0].objectId;

      const taskDefs = {
        taskName,
        entityObjectId: this.authService.getUser().entityId.objectId,
        template: this.templateId,
        stagedefId: stagedefId
      };
      await this.dataService.postToServer("functions/saveTaskDefs", taskDefs);
      this.isShowNewTaskAddingDiv = false;
      this.tasksFormGroup.get("tasks")!.setValue("");
      this.tasksFormGroup.get("stages")!.setValue("");
      this.getTaskDefsList();
      this.notifier.notify('success', 'Task added successfully!!!');

      this.addTaskButtonText = "Add New Task";
      this.isIconActive = !this.isIconActive;
      this.iconSrc = this.isIconActive
        ? "../../../assets/images/New/cancel-cross.svg"
        : "../../../assets/images/New/plus.svg";
    } catch (error) {
      this.notifier.notify('error', 'Getting error while saving the auto task');
      return;
    }
  }

  public async updateTask(taskObject: any) {
    try {
      const obj = {
        taskName: taskObject.name,
        taskObjectId: taskObject.objectId
      };
      await this.dataService.postToServer("functions/updateTaskDefs", obj);
      this.notifier.notify('success',' "Changes saved successfully!!!"');
    } catch (error) {
      this.notifier.notify(
        'error',
        'Getting error while updating the auto task'
      );
      return;
    }
  }

  public async deleteDefaultTask(taskObject:any){
    this.defaultTaskObj = taskObject;
    this.showDefaultDeleteModal = true;
   }
 
  public async onDeleteTask() {
    this.showDefaultDeleteModal = false;
    try {
      await this.dataService.postToServer("functions/deleteTaskDefs", {
        taskObjectId: this.defaultTaskObj.objectId
      });
      this.notifier.notify('success', 'Task deleted successfully!!!');
    } catch (error) {
      this.notifier.notify('error', 'Error deleting task');
    }
    this.getTaskDefsList();
  }

  public async declineDelete(){
    this.showDefaultDeleteModal = false;
  }


  public async getFieldMapsList() {
    try {
      const paramsData: any = {
        where: {
          entity: {
            __type: "Pointer",
            className: "Entity",
            objectId: this.authService.getUser().entityId.objectId
          },
          isActive: true,
        },
      };
      const resp = await this.dataService.getFromServer(
        "classes/FieldMapping",
        paramsData
      );
      if (resp.results && resp.results.length) {
        this.fieldMapsList = resp.results.map((fieldMap: any) => ({
          ...fieldMap
        }));
        return;
      }
    } catch (error) {
      this.notifier.notify(
        'error',
        'Getting error while fetching your field maps'
      );
      return;
    }
  }

  public async openNewFieldMapPopup() {
    document.body.click();
    this.newFieldMap!.show();
  }

  public async saveFieldMap() {
    try {
      const fieldName = this.fieldMapFormGroup.get("fieldName")!.value;
      const mappingField = this.fieldMapFormGroup.get("mappingField")!.value;
      if (!fieldName) {
        this.notifier.notify('error',' "Please Enter Field Name"');
        return;
      }
      if (!mappingField) {
        this.notifier.notify('error', 'Please Enter Mapping Field');
        return;
      }
      const qParams = {
        fieldName: fieldName,
        mappingField: mappingField,
        isActive: true,
        entity: {
          __type: "Pointer",
          className: "Entity",
          objectId: this.authService.getUser().entityId.objectId
        },
      };
      await this.dataService.postToServer("classes/FieldMapping", qParams);
      this.getFieldMapsList();
      this.newFieldMap!.hide();
      this.notifier.notify('success',' "Field Map added successfully!!!"');
    } catch (error) {
      this.notifier.notify('error',' "Getting error while saving the Field Map"');
      return;
    }
  }

  public async onUpdateFieldMap(fieldMapObject: any) {
    try {
      const updatedData = {
        fieldName: fieldMapObject.fieldName,
        mappingField: fieldMapObject.mappingField,
        isActive: true,
      };
      await this.dataService.updateToServer(
        "classes/FieldMapping/" + fieldMapObject.objectId,
        updatedData,
      );
      this.getFieldMapsList();
      this.notifier.notify('success', 'Field Map Updated successfully!!!');
    } catch (error) {
      this.notifier.notify('error',' "Error Updating Field Map"');
    }
  }

  public async onDeleteFieldMap(fieldMapObject: any) {
    try {
      await this.dataService.updateToServer(
        "classes/FieldMapping/" + fieldMapObject.objectId,
        { isActive: false },
      );
      this.getFieldMapsList();
      this.notifier.notify('success',' "Field Map deleted successfully!!!"');
    } catch (error) {
      this.notifier.notify('error', 'Error deleting Field Map');
    }
  }

  onActionChange(i: number, selectedValue: string) {
    this.eventsMappingList[i].action = selectedValue;
  }

  onStageChange(index:number, newStageId:string){
    const newStage = this.stagesId.find((stage: { objectId: string; }) => stage.objectId == newStageId);
    this.eventsMappingList[index].stage = newStage;
  }

  onTemplateChange(index:number, newTemplateId:string){
    const newTemplate = this.emailTemplatesList.find((template: { objectId: string; }) => template.objectId == newTemplateId);
    this.eventsMappingList[index].templateId = newTemplate;
  }
  public async triggerChangeTab(tabId: string) {
    this.tab = tabId;
    this.showMoreDropdown = false;
  }

  public async showInnerTabsContent(flag: string) {
    // Reset all flags to false initially
    this.resetAllFlags();

    switch (flag) {
      case 'slack':
        if (this.webhookForm.value.slackWebhookUrl.length > 0) {
          this.slackIntegrationUrl = true;
        }
        this.showSlackIntegration = true;
        break;

      case 'linkedin':
        this.showLinkedInIntegration = true;
        break;

      case 'fb':
        track("FACEBOOK");
        await this.getFBSubscribedPages();
        this.showFbIntegration = true;
        break;

      case 'whatsapp':
        this.showWhatsappIntegration = true;
        break;

      case 'contact':
        this.showContactIntegration = true;
        break;

      case 'nps':
        this.showNPSIntegration = true;
        break;

      case 'visitor':
        this.showVisitorIntegration = true;
        break;

      case 'segment':
        this.showSegmentIntegration = true;
        break;

      case 'calendly':
        this.showCalendlyIntegration = true;
        break;

      case 'npsconfig':
        this.showNPSConfiguration = true;
        break;

      case 'razorpay':
        this.showRazorpayIntegration = true;
        break;

      case 'gmail':
        this.showGmailIntegration = true;
        break;

      case 'hide':
        this.showInnerTabs = true;
        break;

      default:
        console.warn(`Unknown flag: ${flag}`);
        break;
    }
  }


  private resetAllFlags() {
    this.showSlackIntegration = false;
    this.showLinkedInIntegration = false;
    this.showRazorpayIntegration = false;
    this.showFbIntegration = false;
    this.showWhatsappIntegration = false;
    this.showContactIntegration = false;
    this.showNPSIntegration = false;
    this.showSegmentIntegration = false;
    this.showCalendlyIntegration = false;
    this.showNPSConfiguration = false;
    this.showVisitorIntegration = false;
    this.showGmailIntegration = false;
    this.showInnerTabs = false;
  }


  public async validateDataAndSave() {
    const activityParams = {
      where: {
        entity: {
          __type: "Pointer",
          className: "Entity",
          objectId: this.authService.getUser().entityId.objectId,
        },
      },
    };
    const events = await this.dataService.getFromServer(
        'classes/Events',
        activityParams,
    );
    if (events.results && events.results.length > 0) {
      this.segmentEventStatus = 'Connection Success.';
    } else{
      this.segmentEventStatus = 'Connection Failed.';
      // this.notifier.notify('error', 'Events not detected. Please follow above document to connect.');
    }
  }

  public connectToRazorpay(){
    const serverUrl = environment.apiUrl;
    const updatedServerUrl = serverUrl.replace('/parse/', '/');
    const authUrl = `${updatedServerUrl}auth/razorpay?apikey=${this.eventsApiKey}`;
    const authWindow = window.open(authUrl, 'authWindow', 'width=1000,height=1000');

    if (!authWindow) {
      console.error('Popup blocked. Please allow popups for this site.');
      return;
    }

    window.addEventListener('message', (event) => {
      // Allow the event to come from `https://server.inleads.ai`
      const allowedOrigin = 'https://server.inleads.ai';

      if (event.origin !== allowedOrigin) {
        console.warn('Received message from unauthorized origin:', event.origin);
        return;
      }

      const resultElement = document.getElementById('result');
      const razorpayAuthButton = document.getElementById('razorpayAuthButton') as HTMLButtonElement;

      if (resultElement) {
        if (event.data.type === 'oauth-success') {
          resultElement.innerText = 'Razorpay Connection Successful!';
          if (razorpayAuthButton) {
            razorpayAuthButton.innerText = 'Connected';
            razorpayAuthButton.disabled = true;
          }
          localStorage.setItem('razorpayAuthStatus', 'authenticated');
        } else if (event.data.type === 'oauth-failure') {
          razorpayAuthButton.innerText = 'Connect with Razorpay';
          resultElement.innerText = 'Razorpay Connection Failed!';
        }
      }

      if (authWindow) {
        authWindow.close();
      }
    });
  }

  public async syncRazorpayPayments(){
    try {
      await this.dataService.postToServer(
          'functions/sync-razorpay',{},
        );
    } catch (e) {
      this.notifier.notify('error', 'Something went wrong, please try again.');
    }
  };

  public async disconnectFromFb() {
    try {
      const response = await this.dataService.postToServer(
        'functions/unsubscribe-facebook',
        {},
      );

      if (response && response.result) {
        this.fbPageUnsubscribeModal!.hide();
        this.fbPages=[]
        this.notifier.notify('success', 'Successfully unsubscribed from Facebook.');
      } else {
        console.warn('Unexpected Response:', response);
        this.fbPageUnsubscribeModal!.hide();
        throw new Error('Unsubscribe failed');
      }
    } catch (e) {
      console.error('Error during unsubscribe:', e);
      this.fbPageUnsubscribeModal!.hide();
      this.notifier.notify('error', 'Something went wrong, please try again.');
    }
  }


  public connectToFb() {
    const serverUrl = environment.apiUrl;
    const updatedServerUrl = serverUrl.replace('/parse/', '/');
    const authUrl = `${updatedServerUrl}auth/facebook?apikey=${this.eventsApiKey}`;
    const authWindow = window.open(authUrl, 'authWindow', 'width=1000,height=1000');

    if (!authWindow) {
      console.error('Popup blocked. Please allow popups for this site.');
      return;
    }

    window.addEventListener('message', async (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      const resultElement = document.getElementById('result');
      const authButton = document.getElementById('authButton') as HTMLButtonElement;

      if (resultElement) {
        if (event.data.type === 'oauth-success') {
          resultElement.innerText = 'Facebook Connection Successful!';
          if (authButton) {
            authButton.disabled = true;
          }
          localStorage.setItem('fbAuthStatus', 'authenticated');
          await this.getFBSubscribedPages()
        } else if (event.data.type === 'oauth-failure') {
          resultElement.innerText = 'Facebook Connection Failed!';
        }
      }

      if (authWindow) {
        authWindow.close();
      }
    });
  }

  public async updateBusinessLogo(event: Event) {
    try {
      this.logoLoader = true;
      const files = (event.target as HTMLInputElement).files;
      if (files) {
        if ((files[0].size / (1024 * 1024)) > 1) {
          (event.target as HTMLInputElement).value = '';
          this.notifier.notify('error', 'Please select a file size less than 1 MB.');
          this.logoLoader = false;
          return;
        } else {
          this.logoURL = await this.dataService.uploadFile(files[0]);

          if(this.authService.getUser() && this.logoURL) {
            const entityParams = {
              entityId: this.authService.getUser().entityId.objectId,
              logo: {
                name: this.logoURL.name,
                url: this.logoURL.url,
                __type: 'File',
              },
            };
            await this.dataService.postToServer(
                'functions/updateEntityData',
                entityParams,
            );
            await this.authService.getEntityDetails(this.authService.getUser())
            EmitterService.get('headerUpdate').emit(this.authService.getUser());
            this.notifier.notify('success', 'Company Logo updated!!');
          }
        }
      }
      this.logoLoader = false;
    } catch (e) {
      console.log(`Error ${e}`);
      this.notifier.notify('error', 'Something went wrong, please try again.');
    }
  }

  public async connectToLinkedIn(){

    const serverUrl = environment.apiUrl;
    const updatedServerUrl = serverUrl.replace('/parse/', '/');
    const authUrl = `${updatedServerUrl}auth/linkedin?apikey=${this.eventsApiKey}`;
    const authWindow = window.open(authUrl, 'authWindow', 'width=1000,height=1000');

    if (!authWindow) {
      console.error('Popup blocked. Please allow popups for this site.');
      return;
    }

    window.addEventListener('message', (event) => {
      // Allow the event to come from `https://server.inleads.ai`
      const allowedOrigin = 'https://server.inleads.ai';

      if (event.origin !== allowedOrigin) {
        console.warn('Received message from unauthorized origin:', event.origin);
        return;
      }

      const resultElement = document.getElementById('result');
      const linkedinAuthButton = document.getElementById('linkedinAuthButton') as HTMLButtonElement;

      if (resultElement) {
        if (event.data.type === 'oauth-success') {
          resultElement.innerText = 'Linkedin Connection Successful!';
          if (linkedinAuthButton) {
            linkedinAuthButton.innerText = 'Connected';
            linkedinAuthButton.disabled = true;
          }
          localStorage.setItem('fbAuthStatus', 'authenticated');
        } else if (event.data.type === 'oauth-failure') {
          linkedinAuthButton.innerText = 'Connect with LinkedIn';
          resultElement.innerText = 'Linkedin Connection Failed!';
        }
      }

      if (authWindow) {
        authWindow.close();
      }
    });
  }

  public async isRazorpayConnected() {
    const resp = await this.dataService.postToServer(
      'functions/isRazorpayConnected',
      {
        entityId: this.authService.getUser().entityId.objectId
      }
    );
    this.oAuthResult = resp.result;
  }

  get razorpayButtonText(): string {
    return this.oAuthResult.length > 0 ? 'Connected' : 'Connect with Razorpay';
  }

  public async unsubscribePage(pageId: string) {
    const response = await this.dataService.postToServer(
      'functions/unsubscribe-facebook', { pageId },
    );
    if (response.result.message) {
      this.getFBSubscribedPages();
      this.fbPageUnsubscribeModal!.hide();
    } else {
      console.error(response.error || 'Unsubscribe failed');
      this.fbPageUnsubscribeModal!.hide();
      this.notifier.notify('error', response.result.error);
    }
  }

  public openfbPageUnsubscribeModal() {
    this.fbPageUnsubscribeModal!.show();
  }

  public openCalendlyUnsubscribeModal() {
    this.calendlyUnsubscribeModal!.show();
  }


  public async onSaveAll() {
    if(this.selectedEvent !== null) {
       // Validate duplicate delays
       const delaysWithActions = this.tabs[this.selectedEvent].map((item) => `${item.delay}-${item.action}`);
       const delaySet = new Set(delaysWithActions);
       
       if (delaysWithActions.length !== delaySet.size) {
         this.notifier.notify('error', 'Duplicate delay-action combinations found. Please ensure all combinations are unique.');
       }
      else {       
        try {
          const promises = this.tabs[this.selectedEvent].map((tab) => {
            let obj = {}
            if(tab.objectId === '' && tab.generateUniqueId) {
              obj = {
                eventName: this.selectedEvent,
                action: tab.action,
                stageObjectId: tab.stage && tab.stage.objectId ? tab.stage.objectId : '',
                templateObjectId: tab.templateId ? tab.templateId.objectId : '',
                enableMapping: tab.enableMapping,
                delay: tab.delay,
                isActive: true,
                entityObjectId : this.authService.getUser().entityId.objectId,
              };
            } else {
              obj = {
                eventName: this.selectedEvent,
                action: tab.action,
                stageObjectId: tab.stage && tab.stage.objectId ? tab.stage.objectId : '',
                templateObjectId: tab.templateId ? tab.templateId.objectId : '',
                enableMapping: tab.enableMapping,
                eventMappingObjectId: tab.objectId,
                delay: tab.delay,
                isActive: tab.isActive,
              };
            }
            const endpoint = tab.objectId === '' ? 'functions/saveEventMapping' : 'functions/updateEventMapping';
            return this.dataService.postToServer(endpoint, obj);
          });

          await Promise.all(promises);
            this.notifier.notify('success', 'Changes saved successfully!!!');
        } catch (_) {
          this.notifier.notify('error', 'Error processing event mappings');
        }
        this.getEventsMappingList();
      }
    }
  }

  public addNewRow(NewMapping?: string) {
    if(this.selectedEvent) {
      
      if (this.tabs[this.selectedEvent] && this.tabs[this.selectedEvent].length >= 6) {
        this.notifier.notify('error', 'Only 6 mappins allowed.');
        return;
      }

      let newTab: Tab = {
        objectId: '',
        generateUniqueId: this.generateUniqueId(),  // Unique ID for the new tab
        templateId: { objectId: '', templateName: '' },
        action: '',
        event: '',
        isActive: true,
        enableMapping: true,
        stage: { objectId: '', name: '' },
      };
      if(NewMapping) {
        if (!this.tabs[this.selectedEvent]) {
          this.tabs[this.selectedEvent] = [];
        }
        newTab = {...newTab, delay: 0}
        this.tabs[this.selectedEvent].push(newTab);
      }else {
        this.tabs[this.selectedEvent].push(newTab);
      }
    }
  }

  public generateUniqueId(): string {
    return 'id_' + new Date().getTime();
  }

  public async deleteRow(eventRowId: string, generateId?: string) {
    try {
      if(this.selectedEvent !== null) {
        if(eventRowId) {
        await this.dataService.updateToServer(
          "classes/EventMapping/" + eventRowId,
          { isActive: false }
        );
        this.tabs[this.selectedEvent] = this.tabs[this.selectedEvent].filter(
          (tab: Tab) => tab.objectId !== eventRowId        
        );
      }else {
        this.tabs[this.selectedEvent] = this.tabs[this.selectedEvent].filter(
          (tab: Tab) => tab.generateUniqueId !== generateId         
        );
      }
        this.notifier.notify('success', 'Event deleted successfully!!!');
        this.getEventsMappingList();
      } else {
        this.notifier.notify('error', 'Error processing event deleting');
      }
    } catch (_) {
      this.notifier.notify('error', 'Error processing event deleting');
    }
  }

  public createNewMapping(newMapping: string) {
    if(!newMapping) {
      this.notifier.notify('error', 'Something went wrong, please try again.');
    } else {
      this.tabEvent = newMapping;
      this.selectedEvent = newMapping;
      this.addNewRow(newMapping);
    }
  }
  public showMappingHome() {
    this.tabEvent = '';
    this.selectedEvent = null;
  }


  public async checkCalendlyConnectionExist() {
    try {
      const response = await this.dataService.postToServer(
        'functions/check-calendly-connection-exist',
        {entityId : this.user.entityId.objectId},
      );

      if (response && response.result) {
        this.isCalendlyButtonDisabled = true;
      } else {
        console.warn('Unexpected Response:', response);
        this.isCalendlyButtonDisabled = false;
        throw new Error('check-calendly-connection-exist failed');
      }
    } catch (e) {
      this.isCalendlyButtonDisabled = false;
      console.error(e);
    }
  }

  public async disconnectFromCalendly() {
    try {
      const response = await this.dataService.postToServer(
        'functions/delete-calendly-webhook-subscription',
        {entityId : this.user.entityId.objectId},
      );

      if (response && response.result) {
        this.calendlyUnsubscribeModal!.hide();
        this.isCalendlyButtonDisabled = false;
        this.notifier.notify('success', 'Successfully disconnected from Calendly.');
      } else {
        console.warn('Unexpected Response:', response);
        this.calendlyUnsubscribeModal!.hide();
        throw new Error('Calendly disconnection failed');
      }
    } catch (e) {
      console.error('Error during unsubscribe:', e);
      this.fbPageUnsubscribeModal!.hide();
      this.notifier.notify('error', 'Something went wrong, please try again.');
    }
  }


  calendlyAuthMessageHandler = (event: MessageEvent) => {
    console.log('📩 Received message:', event);
    // const allowedOrigin = 'https://6879-2405-201-c028-80a2-9826-e3ed-6354-1d02.ngrok-free.app'; // Testing in local replace with valid test url, comment this for production
    const allowedOrigin = 'https://server.inleads.ai'; // for Production


    if (event.origin !== allowedOrigin) {
        console.warn('Received message from unauthorized origin:', event.origin);
        return;
    }

    if (event.data.type === 'oauth-success') {
      console.log('✅ OAuth Success Received');
      this.isCalendlyButtonDisabled = true;
      this.showCalendlyIntegrationResult = true;
      setTimeout(()=> {
        this.showCalendlyIntegrationResult = false;
      }, 1000);
    }
    else{

    }
    
    // ✅ Close popup if it exists
    if (this.authWindow) {
      this.authWindow.close();
    }
  };

  
  public integrateCalendly() {
    window.removeEventListener('message', this.calendlyAuthMessageHandler, false);
    window.addEventListener('message', this.calendlyAuthMessageHandler, false);

    const serverUrl = environment.apiUrl;
    let entityId = this.user.entityId.objectId;
    const updatedServerUrl = serverUrl.replace('/parse/', '/');
    const authUrl = `${updatedServerUrl}auth/calendly?entityId=${entityId}`;
    this.authWindow = window.open(authUrl, 'authWindow', 'width=1000,height=1000');
    console.log("authWindow", this.authWindow);

    if (!this.authWindow) {
      console.error('Popup blocked. Please allow popups for this site.');
      return;
    }
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.calendlyAuthMessageHandler);
  }
}
