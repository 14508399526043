import { Component, Input, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'dashboard-wrapper',
  templateUrl: './dashboard-wrapper.component.html',
  styleUrls: ['./dashboard-wrapper.component.scss'],
})
export class DashboardWrapperComponent implements OnInit {
  @Input() public isLoggedIn: boolean;
  @Input() public routeParams: object;
  public isSidebarOpened = false;
  public isDesktopMenu: boolean;
  public isMobileMenu: boolean;
  constructor(private deviceService: DeviceDetectorService) {}

  public ngOnInit() {
    if (this.deviceService.isMobile()) {
      this.isDesktopMenu = false;
      this.isMobileMenu = true;
    } else {
      this.isDesktopMenu = true;
      this.isMobileMenu = false;
    }
  }

  public toggleNavigation() {
    this.isSidebarOpened = !this.isSidebarOpened;
  }
}
