import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./router/app-routing.module";
import { DataTablesModule } from "angular-datatables";
import { RecaptchaModule } from "ng-recaptcha";
import { DragDropModule } from "@angular/cdk/drag-drop";
import {
  AlertModule,
  ModalModule,
  AccordionModule,
  BsDropdownModule,
  TabsModule,
  TooltipModule,
  BsDatepickerModule,
} from "ngx-bootstrap";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HelperService } from "./services/helper.service";
import { DataService } from "./services/data.service";
import { EventsService } from "./services/events.service";
import { CommonInterceptor } from "./services/common-interceptor";
import { NotifierModule } from "angular-notifier";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { DashboardComponent } from "./screens/dashboard/dashboard.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { FormBuilderModule } from "./components/shared/form-builder/form-builder.module";
import { AuthService } from "./services/auth";
import { NgSelect2Module } from "ng-select2";
import { DashboardWrapperComponent } from "./components/dashboard-wrapper/dashboard-wrapper.component";
import { LoginComponent } from "./screens/login/login.component";
import { ActivitiesService } from "./services/activities.service";
import { DeviceDetectorModule } from "ngx-device-detector";
import { RegistrationComponent } from "./screens/registration/registration.component";
import { ForgotpasswordComponent } from "./screens/forgotpassword/forgotpassword.component";
import { MobilemenuComponent } from "./components/mobilemenu/mobilemenu.component";
import { NgxAutoScrollModule } from "ngx-auto-scroll";
import { AuthGuardService } from "./services/auth-guard.service";
import { NoAuthenticationService } from "./services/no-authentication.service";
import { UserService } from "./services/user.service";
import { NotificationService } from "./services/notification.service";
import { NotificationComponent } from "./components/notification/notification.component";
import { SettingsComponent } from "./screens/settings/settings.component";
import { PrivacyPolicyComponent } from "./screens/privacy-policy/privacy-policy.component";
import { TermsConditionsComponent } from "./screens/terms-conditions/terms-conditions.component";
import { DocumentsService } from "./services/documents.service";
import { LeadsComponent } from "./screens/leads/leads.component";
import { ContactsComponent } from "./screens/contacts/contacts.component";
import { ReportsComponent } from "./screens/reports/reports.component";
import { ProfileComponent } from "./screens/profile/profile.component";
import { DateAgoPipe } from "./pipes/date-ago.pipe";
import { DefaultPicPipe } from "./pipes/default-pic.pipe";
import { AgentsComponent } from "./screens/agents/agents.component";
import { ManagersComponent } from "./screens/managers/managers.component";
import { ProductsComponent } from "./screens/products/products.component";
import { SubscriptionComponent } from "./screens/subscription/subscription.component";
import { KanbanComponent } from "./components/kanban/kanban.component";
import { KanbanViewComponent } from "./components/kanban-view/kanban-view.component";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { GoogleChartsModule } from "angular-google-charts";
import { ContactDetailComponent } from "./screens/contact-detail/contact-detail.component";
import { SpacesComponent } from "./screens/spaces/spaces.component";
import { PopoverModule } from "ngx-bootstrap/popover";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { QuotesComponent } from "./screens/quotes/quotes.component";
import { ArticlesComponent } from "./screens/articles/articles.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { FollowUpsComponent } from "./screens/follow-ups/follow-ups.component";
import { TasksComponent } from "./screens/tasks/tasks.component";
import { PaymentMethodsComponent } from "./components/subscription/payment-methods/payment-methods.component";
import { PaymentCardComponent } from "./components/subscription/payment-card/payment-card.component";
import { OutreachComponent } from "./screens/outreach/outreach.component";
import { EventloggerInstallationGuideComponent } from "./screens/eventlogger-installation-guide/eventlogger-installation-guide.component";
import { DauMetricsComponent } from "./screens/dau-metrics/dau-metrics.component";
import { OutreachTemplateComponent } from './screens/outreach-templates/outreach-templates.component';
import { DashboardWidgetComponent } from "./components/dashboard-widget/dashboard-widget.component";
import { ChartComponent } from "./components/chart/chart.component";
import { DashboardWidgetContentComponent } from "./components/dashboard-widget-content/dashboard-widget-content.component";
import { UserSignupMetricsWidgetComponent } from "./components/dashboard-widget-content/user-signup-metrics-widget/user-signup-metrics-widget.component";
import { EngagedUserMetricsWidgetComponent } from "./components/dashboard-widget-content/engaged-user-metrics-widget/engaged-user-metrics-widget.component";
import { DashboardWidgetHeaderComponent } from "./components/dashboard-widget-header/dashboard-widget-header.component";
import { ConfigService } from "./services/config-data.service";
import { VisitorsComponent } from "./screens/visitors/visitors.component";
import { NetPromoterScoreWidgetComponent } from "./components/dashboard-widget-content/net-promoter-score-widget/net-promoter-score-widget.component";
import { ChatbotComponent } from './components/chatbot/chatbot.component';
import { VisitorDeatilsComponent } from './screens/visitor-deatils/visitor-deatils.component';
import { LeadDetailsComponent } from './screens/lead-details/lead-details.component';
import { NpsWidgetComponent } from './components/settings/nps-widget/nps-widget.component';
import { OwlCarouselComponent } from './components/owl-carousel/owl-carousel.component';
import { ChatDocSettingsComponent } from './components/settings/chat-doc-settings/chat-doc-settings.component';
import { PostmarkConfigurationComponent } from './components/settings/postmark-configuration/postmark-configuration.component';
import { GroupsComponent } from './screens/groups/groups.component';
import { SupportComponent } from './screens/support/support.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!
import { LandingPageComponent } from './screens/landing-page/landing-page.component';
import { EventDetailsComponent } from './screens/event-details/event-details.component';
import { AiAssistComponent } from './screens/ai-assist/ai-assist.component';
import { EmailEditorModule } from 'angular-email-editor';
import { AutomationComponent } from "./screens/automation/automation.component";

// @ts-ignore
// @ts-ignore
@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    DashboardWrapperComponent,
    LoginComponent,
    RegistrationComponent,
    ForgotpasswordComponent,
    MobilemenuComponent,
    NotificationComponent,
    SettingsComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    LeadsComponent,
    ContactsComponent,
    ReportsComponent,
    ProfileComponent,
    DateAgoPipe,
    DefaultPicPipe,
    AgentsComponent,
    ManagersComponent,
    ProductsComponent,
    SubscriptionComponent,
    KanbanComponent,
    ContactDetailComponent,
    SpacesComponent,
    QuotesComponent,
    ArticlesComponent,
    FollowUpsComponent,
    TasksComponent,
    PaymentMethodsComponent,
    PaymentCardComponent,
    OutreachComponent,
    EventloggerInstallationGuideComponent,
    DauMetricsComponent,
    OutreachTemplateComponent,
    DashboardWidgetComponent,
    ChartComponent,
    DashboardWidgetContentComponent,
    UserSignupMetricsWidgetComponent,
    EngagedUserMetricsWidgetComponent,
    DashboardWidgetHeaderComponent,
    VisitorsComponent,
    ChatbotComponent,
    VisitorDeatilsComponent,
    LeadDetailsComponent,
    NpsWidgetComponent,
    NetPromoterScoreWidgetComponent,
    OwlCarouselComponent,
    ChatDocSettingsComponent,
    PostmarkConfigurationComponent,
    GroupsComponent,
    SupportComponent,
    LandingPageComponent,
    EventDetailsComponent,
    AiAssistComponent,
    KanbanViewComponent,
    AutomationComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AngularEditorModule,
    GooglePlaceModule,
    CarouselModule,
    HttpClientModule,
    AppRoutingModule,
    DataTablesModule,
    ReactiveFormsModule,
    FormsModule,
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: "right",
        },
        vertical: {
          position: "top",
        },
      },
    }),
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    FormBuilderModule,
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    NgSelect2Module,
    DeviceDetectorModule.forRoot(),
    NgxAutoScrollModule,
    TooltipModule.forRoot(),
    RecaptchaModule,
    DragDropModule,
    AutocompleteLibModule,
    GoogleChartsModule.forRoot(),
    PopoverModule.forRoot(),
    NgxUiLoaderModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatPseudoCheckboxModule,
    FullCalendarModule,
    EmailEditorModule // Add the module to imports
  ],
  providers: [
    DataService,
    EventsService,
    ConfigService,
    HelperService,
    AuthService,
    ActivitiesService,
    NoAuthenticationService,
    AuthGuardService,
    UserService,
    NotificationService,
    DocumentsService,
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CommonInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
