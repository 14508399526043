import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { UserService } from './user.service';
import {AuthService} from "./auth";
import * as moment from "moment";
import {expectedRevenue, getDatesRange} from "../helpers/helper.date";

@Injectable({
    providedIn: 'root',
})
export class CompanyOverViewReportService {
    public overViewReport = {
        dailyActiveUsers: 0,
        totalLeads: 0,
        expectedRevenue: 0,
        totalContacts: 0,
        retentionRate: 0,
        churnRate: 0,
        totalDealAmount: 0,
        graphDate: [] as any[],
    }

    constructor(
        private dataService: DataService,
        public authService: AuthService,
        private userService: UserService,
    ) {}

    public async dailyUsersCount(selectedRange: string) {
        try {
            const date = getDatesRange(selectedRange)
            const startDate = moment(date.startDate).startOf('day').toDate().toISOString();
            const endDate = moment(date.endDate).endOf('day').toDate().toISOString();
            let params = {
              entityId: this.userService.getUser()!.entityId!.objectId,
              limit: 10000,
              startDate,
              endDate,  
              typeOfData: 'Reports',
            };
            const paramsResponse = await this.dataService.postToServer(
                "functions/getActiveUsersMetrics",
                params
            );

            let responseObj = paramsResponse.result || [];
            const dau  = responseObj.reduce((sum: any, report: any) => sum + (report.count || 0), 0);

            //current month dau rate
            // const dau = responseObj.dau ? responseObj.dau : 0;

            //current month churn rate
            const currentChurn = responseObj.currentChurn ? parseInt(responseObj.currentChurn) : 0;

            //current month retention rate
            let currentMonthRetentionRate = 1 - (currentChurn / 100);
            let retentionRate = (currentMonthRetentionRate * 100).toFixed(0);

            this.overViewReport.dailyActiveUsers = dau;
            this.overViewReport.retentionRate = parseInt(retentionRate);
            this.overViewReport.churnRate = currentChurn;
            return await this.getContacts(selectedRange)
        } catch (_) {
            return await this.getContacts(selectedRange)
        }
    }


    public async getContacts(selectedRange: string) {
        try {
            const date = getDatesRange(selectedRange)
            const startDate = moment(date.startDate).toISOString();
            const endDate = moment(date.endDate).toISOString();
            let customerParams;
            if (this.authService.isSuperAdmin()) {
                customerParams = {
                    limit: 10000,
                    where: {
                        createdAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}},
                    },
                };
            } else if (this.authService.isAdmin()) {
                customerParams = {
                    limit: 10000,
                    where: {
                        createdAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}},
                        email: {"$ne": 'anonymous@inleads.ai'},
                        $or: [
                            {
                                entity: {
                                    __type: "Pointer",
                                    className: "Entity",
                                    objectId: this.authService.getUser().entityId.objectId,
                                },
                            },
                        ],
                    },
                };
            } else if (this.authService.isAgent()) {
                customerParams = {
                    limit: 10000,
                    where: {
                        createdAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}},
                        email: {"$ne": 'anonymous@inleads.ai'},
                        $or: [
                            {
                                agent: {
                                    __type: "Pointer",
                                    className: "_User",
                                    objectId: this.authService.getUser().objectId,
                                },
                            },
                            {
                                assignTo: {
                                    __type: "Pointer",
                                    className: "_User",
                                    objectId: this.authService.getUser().objectId,
                                },
                            },
                        ],
                    },
                };
            } else if (this.authService.isManager()) {
                customerParams = {
                    limit: 10000,
                    where: {
                        createdAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}},
                        email: {"$ne": 'anonymous@inleads.ai'},
                        $or: [
                            {
                                agent: {
                                    $inQuery: {
                                        where: {
                                            createdAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}},
                                            entityId: {
                                                __type: "Pointer",
                                                className: "Entity",
                                                objectId: this.authService.getUser().entityId.objectId,
                                            },
                                        },
                                        className: "_User",
                                    },
                                },
                            },
                            {
                                assignTo: {
                                    $inQuery: {
                                        where: {
                                            entityId: {
                                                __type: "Pointer",
                                                className: "Entity",
                                                objectId: this.authService.getUser().entityId.objectId,
                                            },
                                        },
                                        className: "_User",
                                    },
                                },
                            },
                            {
                                agent: {
                                    __type: "Pointer",
                                    className: "_User",
                                    objectId: this.authService.getUser().objectId,
                                },
                            },
                            {
                                assignTo: {
                                    __type: "Pointer",
                                    className: "_User",
                                    objectId: this.authService.getUser().objectId,
                                },
                            },
                        ],
                    },
                };
            }

            const contactResponse = await this.dataService.getFromServer(
                "classes/Contacts",
                customerParams
            );
            // let contactsList = contactResponse ? contactResponse.results : [];
            let countContacts =
                contactResponse && contactResponse.results
                    ? contactResponse.results.length
                    : 0;

            this.overViewReport.totalContacts = countContacts
            return await this.fetchLeadsList(selectedRange);
        }catch (_) {
            return await this.fetchLeadsList(selectedRange);
        }
    }

    public async fetchLeadsList(selectedRange: string) {
        try {
            const getCurrentUser = this.authService.getUser();
            const date = getDatesRange(selectedRange)
            const startDate = moment(date.startDate).toISOString();
            const endDate = moment(date.endDate).toISOString();
            let query;
            if (!this.authService.isSuperAdmin()) {
                query = {
                    where: {
                        entity: {
                            __type: "Pointer",
                            className: "Entity",
                            objectId: this.authService.getUser().entityId.objectId,
                        },
                        createdAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}},
                        isArchived: {$ne: true},
                    },
                };
            }

            const spaceResp = await this.dataService.getFromServer(
                "classes/Spaces",
                query
            );

            let spaceList =
                spaceResp.results && spaceResp.results.length > 0
                    ? [spaceResp.results[0]]
                    : [];

            let selectedSpace = spaceList[0];


            let params: any;
            if (this.authService.isSuperAdmin()) {
                params = {
                    include: "status,stage,contact",
                    where: {
                        isArchived: {$ne: true},
                        createdAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}}
                    },
                };
            } else if (this.authService.isAdmin()) {
                params = {
                    // include: "stage,contact",
                    include: "status,stage,contact",
                    where: {
                        entity: {
                            __type: "Pointer",
                            className: "Entity",
                            objectId: this.authService.getUser().entityId.objectId,
                        },
                        isArchived: {$ne: true},
                        createdAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}}
                    },
                };
            } else if (this.authService.isAgent()) {
                params = {
                    include: "status,stage,contact",
                    where: {
                        entity: {
                            __type: "Pointer",
                            className: "Entity",
                            objectId: this.authService.getUser().entityId.objectId,
                        },
                        isArchived: {$ne: true},
                        createdAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}}
                    },
                };
                if (!!getCurrentUser.isPrivate) {
                    params = {
                        where: {
                            ...params.where,
                            $or: [
                                {
                                    agent: {
                                        __type: 'Pointer',
                                        className: '_User',
                                        objectId: this.authService.getUser().objectId,
                                    },
                                },
                                {
                                    assignTo: {
                                        __type: 'Pointer',
                                        className: '_User',
                                        objectId: this.authService.getUser().objectId,
                                    },
                                },
                            ],
                        },
                    }
                }
            } else if (this.authService.isManager()) {
                params = {
                    include: "status,stage,contact",
                    where: {
                        $or: [
                            {
                                agent: {
                                    $inQuery: {
                                        where: {
                                            manager: {
                                                __type: "Pointer",
                                                className: "_User",
                                                objectId: this.authService.getUser().objectId,
                                            },
                                        },
                                        className: "_User",
                                    },
                                },
                            },
                            {
                                assignTo: {
                                    $inQuery: {
                                        where: {
                                            manager: {
                                                __type: "Pointer",
                                                className: "_User",
                                                objectId: this.authService.getUser().objectId,
                                            },
                                        },
                                        className: "_User",
                                    },
                                },
                            },
                            {
                                agent: {
                                    __type: "Pointer",
                                    className: "_User",
                                    objectId: this.authService.getUser().objectId,
                                },
                            },
                            {
                                assignTo: {
                                    __type: "Pointer",
                                    className: "_User",
                                    objectId: this.authService.getUser().objectId,
                                },
                            },
                        ],
                        isArchived: {$ne: true},
                        createdAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}}
                    },
                };
            }
            if (selectedSpace) {
                params.where.space = {
                    __type: "Pointer",
                    className: "Spaces",
                    objectId: selectedSpace.objectId,
                };
            }
            params.limit = 10000;
            const leadsResp = await this.dataService.getFromServer(
                "/classes/Leads",
                params
            );

            this.overViewReport.totalLeads = leadsResp.results.length || 0;
            if(leadsResp.results.length) {
                const totalDeal = await expectedRevenue(leadsResp.results)
                this.overViewReport.totalDealAmount = totalDeal || 0
            }
            return await this.getEngagedUsersMetrics(selectedRange)
        } catch (_) {
            return await this.getEngagedUsersMetrics(selectedRange)
        }
    }


    public async getEngagedUsersMetrics(selectedRange:string) {
        try {
            let chartData = [] as any[];

            let date = getDatesRange(selectedRange);
            const params = {
                entityId: this.authService.getUser().entityId.objectId,
                startDate: date.startDate,
                endDate: date.endDate,
            };

            let getCustomerSignupMetricsResponse = [], getEngagedUsersMetricsResponse = [], getLeadMetricsResponse = [];
            const getEngagedUsers: any = [];
            const getCustomerSignup: any = [];
            const getCustomerLeads: any = [];

            getEngagedUsersMetricsResponse = await this.dataService.postToServer(
                "functions/getEngagedUsersMetrics",
                params,
            );
            getCustomerSignupMetricsResponse = await this.dataService.postToServer(
                "functions/getCustomerEventSignupMetrics",
                params,
            );
            getLeadMetricsResponse = await this.dataService.postToServer(
                "functions/getAllLeadsMetrics",
                params,
            );

            getEngagedUsersMetricsResponse &&
            getEngagedUsersMetricsResponse.result &&
            getEngagedUsersMetricsResponse.result.forEach((obj: any) => {
                getEngagedUsers.push([
                    moment(obj.objectId).format("MMM-DD"),
                    obj.count,
                    0,
                    0,
                    moment(obj.objectId).format("DD-MMM-YYYY"),
                ]);
            });

            getCustomerSignupMetricsResponse &&
            getCustomerSignupMetricsResponse.result &&
            getCustomerSignupMetricsResponse.result.forEach((obj: any) => {
                getCustomerSignup.push([
                    moment(obj.objectId.date).format("MMM-DD"),
                    0,
                    obj.count,
                    0,
                    moment(obj.objectId.date).format("DD-MMM-YYYY"),
                ]);
            });

            getLeadMetricsResponse &&
            getLeadMetricsResponse.result &&
            getLeadMetricsResponse.result.forEach((obj: any) => {
                getCustomerLeads.push([
                    moment(obj.objectId.date).format("MMM-DD"),
                    0,
                    0,
                    obj.count,
                    moment(obj.objectId.date).format("DD-MMM-YYYY"),
                ]);
            });
            const mergedMap = new Map();
            [getEngagedUsers, getCustomerSignup, getCustomerLeads].forEach(arr => {
                arr.forEach((innerArr: any[]) => {
                    const date = innerArr[0];
                    if (mergedMap.has(date)) {
                        mergedMap.get(date).forEach((_value: any, index: number) => {
                            if (index !== 0) {
                                mergedMap.get(date)[index] += innerArr[index];
                            }
                        });
                    } else {
                        mergedMap.set(date, innerArr.slice());
                    }
                });
            });

            const mergedArray = Array.from(mergedMap.values());
            if (mergedArray && mergedArray.length) {
                mergedArray.forEach((obj: any) => {
                    chartData.push(obj.slice(0, 4));
                });
            }

            if(chartData.length) {
                chartData.sort((a, b) => {
                    const dateA = moment(a[0], "MMM-DD");
                    const dateB = moment(b[0], "MMM-DD");
                    // @ts-ignore
                    return dateA - dateB;
                });
            }

            this.overViewReport.graphDate = chartData || []
            return this.overViewReport;
        } catch (_) {
            return this.overViewReport;
        }
    }
}
