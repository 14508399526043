import * as moment from 'moment';
import {ReportsData, ResultData} from "../types/report";
import {Leads} from "../types/leads";
import html2canvas from "html2canvas";
import * as Parse from 'parse';

export function getDates(selectedRange: string) {
    // @ts-ignore
    let startOfPeriod; let endOfPeriod;
    switch (selectedRange) {
        case 'thisYear':
            startOfPeriod = moment().startOf('year').toString();
            endOfPeriod = moment().endOf('year').toString();
            break;
        case 'thisMonth':
            startOfPeriod = moment().startOf('month').toString();
            endOfPeriod = moment().endOf('month').toString();
            break;
        case 'thisWeek':
            startOfPeriod = moment().startOf('week').toString();
            endOfPeriod = moment().endOf('week').toString();
            break;
        case 'lastThirtyDays':
            startOfPeriod = moment().subtract(30, 'days').toString();
            endOfPeriod = moment().endOf('day').toString();
            break;
        default:
            startOfPeriod = moment().startOf('week').toString();
            endOfPeriod = moment().endOf('week').toString();
            break;
    }
    return {
        startDate: new Date(startOfPeriod),
        endDate: new Date(endOfPeriod),
    };
}

export function getMessage(selectedRange: string) {
    let message;
    switch (selectedRange) {
        case 'thisYear':
            message = 'No new leads have been generated in this year.';
            break;
        case 'thisMonth':
            message = 'No new leads have been generated in this month.';
            break;
        case 'thisWeek':
            message = 'No new leads have been generated since three weeks.';
            break;
        case 'lastThirtyDays':
            message = 'No new leads have been generated in the past thirty days.';
            break;
        default:
            message = 'No leads found.';
            break;
    }
    return message;
}

export function getDatesRange(selectedRange: string) {
    // @ts-ignore
    let startDate; let endDate;
    switch (selectedRange) {
        case 'thisYear':
            startDate = moment().startOf('year').toString();
            endDate = moment().endOf('year').toString();
            break;
        case 'lastYear':
            startDate = moment(moment().subtract(1, 'year')).startOf('year').toString();
            endDate = moment(moment().subtract(1, 'year')).endOf('year').toString();
            break;
        case 'thisMonth':
            startDate = moment().startOf('month').toString();
            endDate = moment().endOf('month').toString();
            break;
        case 'thisWeek':
            startDate = moment().startOf('week').toString();
            endDate = moment().endOf('week').toString();
            break;
        case 'lastWeek':
            startDate = moment(moment().subtract(1, 'week')).startOf('week').toString();
            endDate = moment(moment().subtract(1, 'week')).endOf('week').toString();
            break;
        case 'lastThirtyDays':
            startDate = moment().subtract(30, 'days').toString();
            endDate = moment().endOf('day').toString();
            break;
        case 'lastMonth':
            startDate = moment(moment().subtract(1, 'month')).startOf('month').toString();
            endDate = moment(moment().subtract(1, 'month')).endOf('month').toString();
            break;
        case 'lastFifteenDays':
            startDate = moment(moment().subtract(15, 'days')).startOf('month').toString();
            endDate = moment(moment().subtract(15, 'days')).endOf('month').toString();
            break;
        case 'lastTenDays':
            startDate = moment(moment().subtract(10, 'days')).startOf('month').toString();
            endDate = moment(moment().subtract(15, 'days')).endOf('month').toString();
            break;
        default:
            startDate = moment().startOf('month').toString();
            endDate = moment().endOf('month').toString();
            break;
    }
    return {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
    };
}

export function convertData(data:any, filterDataType?: string) : ReportsData {
    if (!data || data.length === 0) {
        return { data: [], maxValue: 0 }; // Return a consistent type with empty data and a default maxValue
    }

    const result: ResultData[] = [];
    const dateCounts: { [date: string]: number } = {};

    if(filterDataType === 'Dau' || filterDataType === 'Events') {
        const convertedData = data.map((item: any) => [
            moment(item.objectId).format('DD-MMM-YYYY'),
            item.count
        ]);

        let maxValue = Math.max(...data.map((item: any) => item.count));

        let flattenedAllRecords = data.reduce((acc: any[], curr: any) => {
            return acc.concat(curr.records);
        }, []);

        // Add 25% to maxValue
        maxValue = Math.ceil(maxValue * 1.5)

        return {
            data: convertedData,
            maxValue: maxValue,
            allRecors: flattenedAllRecords,
        };
    } else {
        data.forEach((item: any) => {
            // const formattedDate = moment(item.createdAt).format('MMM-YYYY');
            const formattedDate = moment(item.createdAt).format('DD-MMM-YYYY');
            if (!dateCounts[formattedDate]) {
                dateCounts[formattedDate] = 0;
            }
            if (filterDataType === 'Revenue') {
                dateCounts[formattedDate] += item.dealValue || 0;
            } else {
                dateCounts[formattedDate] += 1;
            }
        });

        let maxValue = 0;

        for (const [date, count] of Object.entries(dateCounts)) {
            result.push([date, count]);
            if (count > maxValue) {
                maxValue = count;
            }
        }

        // Add 25% to maxValue
        maxValue = Math.ceil(maxValue * 1.5)

        return {
            data: result,
            maxValue: maxValue
        };
    }
};

export function expectedRevenue(leads: Leads[]) {
    let totalDealValue = 0;
    leads.forEach(deal => {
        totalDealValue += deal.dealValue || 0;
    });
    return totalDealValue;
}

export function getEventnames(eventsList: any[]) {
    return [
        { id: 'ALL', value: 'ALL' },
        ...Array.from(new Set(eventsList
            .filter(({ event }) => !['VISIT', 'WAITLIST'].includes(event))
            .map(({ event }) => event)))
            .map(event => ({ id: event, value: event }))
    ];
}

export async function generateImg(data: any, fileName: string) {
    try {
        const id = 'myIframe1';
        const htmlString = `<iframe id="${id}" frameborder="0" style="width: 100%; height: 100%;"></iframe>`;
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;
        const htmlElement = tempElement.firstElementChild as HTMLIFrameElement;
        document.body.appendChild(htmlElement);

        const iframe = document.getElementById(id) as HTMLIFrameElement;

        if (iframe) {
            iframe.srcdoc = data;

            // Add an event listener to wait for the iframe to load its content
            return new Promise((resolve, reject) => {
                iframe.onload = async () => {
                    try {
                        const iframeDocument = iframe.contentDocument || (iframe.contentWindow && iframe.contentWindow.document);
                        if (iframeDocument) {
                            const canvas = await html2canvas(iframeDocument.body);
                            const img = canvas.toDataURL("image/png");
                            const parsedFile = await img2parseFile(img, fileName);
                            iframe.remove();
                            resolve(parsedFile);
                        } else {
                            iframe.remove();
                            resolve(undefined);
                        }
                    } catch (e) {
                        iframe.remove();
                        reject(e);
                    }
                };

                // Add error handling for the iframe
                iframe.onerror = () => {
                    iframe.remove();
                    reject('Failed to load iframe content');
                };
            });
        }
        return undefined;
    } catch (e) {
        console.error(e);
        return undefined;
    }
}

export async function img2parseFile(data: string, fileName: string) {
    try {
        const parseFile = new Parse.File(fileName, { base64: data });
        await parseFile.save(); 
        return parseFile; 
    } catch (e) {
        console.error('Error creating Parse.File:', e);
        return undefined; 
    }
}

export function getRelativeTime(date: string | Date): string {
    const duration = moment.duration(moment().diff(moment(date)));
    const years = duration.years();
    const months = duration.months();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
  
    if (years > 0) return `${years} y ago`;
    if (months > 0) return `${months} mo ago`;
    if (days > 0) return `${days} d ago`;
    if (hours > 0) return `${hours} h ago`;
    if (minutes > 0) return `${minutes} min ago`;
    return 'just now';
  }