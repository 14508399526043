/*
  Phone Number Pattern Source: https://stackoverflow.com/a/22061081
  Valid number are.
    9883443344
    09883443344
    919883443344
    0919883443344
    +919883443344
    +91-9883443344
    0091-9883443344
    +91 -9883443344
    +91- 9883443344
    +91 - 9883443344
    0091 - 9883443344
*/

export const validationPattern = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[456789]\d{9}$/,
  number: /^[0-9]+$/,
  date: /^((0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])-[12]\d{3})$/,
  password : /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@$%^&*()_+-=|{[\]}:;"'?/>.<,])(?=.{8,})/
};
