/* tslint:disable:no-any */
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { DataTableDirective } from 'angular-datatables';
import { DataService } from 'src/app/services/data.service';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators,ValidatorFn,AbstractControl } from '@angular/forms';
import { User } from '../../types/user';
import { Activity, ActivityParams } from '../../types/activity';
import { validationPattern } from '../../constants/regexp';
import { UserService } from '../../services/user.service';
import { LeadsService } from '../../services/leads.service';
import { LeadParams, Leads } from '../../types/leads';
import { ContactsService } from '../../services/contacts.service';
import { AuthService } from '../../services/auth';
import { NotifierService } from 'angular-notifier';
import { Tasks } from '../../types/tasks';
import { Contacts, ContactsParams } from '../../types/contacts';
import { Stages } from '../../types/stages';
import { KanbanComponent } from '../../components/kanban/kanban.component';
import { TaskService } from '../../services/task.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Products } from 'src/app/types/products';
import { Subject } from 'rxjs';
import { HelperService } from '../../services/helper.service';
import { track } from '@inleads/event-logger';
import { IcalService } from '../../services/ical.service';
import { getDates, getMessage } from '../../helpers/helper.date';


@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss'],
})
export class LeadsComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective) public datatableElement: DataTableDirective;
  @ViewChild('tableWrapper') public tableWrapperElement: ElementRef;
  @ViewChild(KanbanComponent) public kanbanComponent: KanbanComponent;
  @ViewChild('editLeadModal') public editLeadModal: ModalDirective;
  @ViewChild('dailyStatusUpdateModal')
  public dailyStatusUpdateModal: ModalDirective;
  @ViewChild('newlead') public newleadModal?: ModalDirective;
  @ViewChild('newStage') public newStageModal?: ModalDirective;
  @ViewChild('newProduct') public newProductModal?: ModalDirective;
  @ViewChild('lostReason') public lostReasonModal?: ModalDirective;
  @ViewChild('newTask') public newTaskModal?: ModalDirective;
  @ViewChild('editTask') public editTaskModal?: ModalDirective;
  @ViewChild('deleteTask') public deleteTaskModal?: ModalDirective;
  @ViewChild('openTask') public openTaskModal?: ModalDirective;
  @ViewChild("customDate") public customDate?: ModalDirective;
  public dtTrigger: Subject<any> = new Subject();
  public toggle = -1;
  public dtInstance: DataTables.Api;
  public isLoading: boolean;
  public isPageLoading: boolean;
  public viewLeadData: boolean;
  public leadPageData: Leads[] = [];
  public activityPageData: Activity[] = [];
  public selectedLead: Leads;
  public spaceId: any;
  public selectedLeadActivity: Activity[];
  public activityResponseData: Activity[];
  public userPageData: User[] = [];
  public managersPageData: User[] = [];
  public stagesListData: Stages[] = [];
  public dtOptions = {};
  public modalRef: BsModalRef;
  public editLeadForm: FormGroup;
  public newLeadForm: FormGroup;
  public newTaskForm: FormGroup;
  public lostReasonForm: FormGroup;
  public newStageForm: FormGroup;
  public newProductForm: FormGroup;
  public dailyStatusUpdateForm: FormGroup;
  public dateForm: FormGroup;
  public allUsers: User[] = [];
  public currentUser: User | undefined;
  public addNewUser: boolean;
  public isShowTxtField: boolean;
  public policyType: string;
  public marked: boolean;
  public stageSelect = true;
  public opt: Tasks[] = [];
  public statusUpdateModal = {};
  public updateLeadModal = '';
  public selected: Tasks[] = [];
  // tslint:disable-next-line:no-anyow
  public currentLead: any;
  public minDate: Date;
  public wonStatus: string;
  public lostStatus: string;
  public closingObjectId: string | undefined;
  public lastStage: any;
  public policyTypeOther: string;
  public agentsData: User[] = [];
  public agentsListData: User[] = [];
  public kanbanList: object[] = [];
  public displayContent = 'kanban';
  public contactList: Contacts[] = [];
  public statusType = '';
  public spacesList: any[] = [];
  public selectedSpace: any;
  public sharedSpaceId: any;
  public lostReasonLeadData: Leads;
  public lostReasonLostObjectId: string | undefined;
  public lostReasonClosingObjectId: string | undefined;
  public disableFields: boolean;
  public statusPopOverTemplateRef: any;
  public newTask: any;
  public currentNewTaskLead: any;
  public currentLeadStage: any;
  public editTaskForm: FormGroup;
  public currentTaskObjectID: string;
  public productList: any[] = [];
  public productID: any;
  public isEmpty = false;
  public lostReasonList : string;
  public showTour: string = 'false';
  public isLeadArchive: string;
  public kanbanSearchQuery: string = '';
  public leadsObservableSubscription: any;
  public isButtonDisabled: boolean;
  public selectedSortOption: string = 'createdAt';
  public ascendingOrder: boolean = true;
  public tagField:any;
  public selectedRangeFilter: string = 'lastThirtyDays';
  public selectedDates = getDates('lastThirtyDays');
  public shwAllLeads: boolean = false;
  public showNoLeadsMessage: boolean = false;
  public noLeadstext: string = 'No new leads have been generated in the past thirty days.';


  constructor(
    private leadsService: LeadsService,
    private taskService: TaskService,
    private dataService: DataService,
    private fb: FormBuilder,
    public userService: UserService,
    public authService: AuthService,
    public contactService: ContactsService,
    private notifier: NotifierService,

    private route:ActivatedRoute,

    private router: Router,
    private helperService: HelperService,
    private icalService: IcalService,
  ) {
    this.minDate = new Date();
  }

  public ngOnInit() {
    this.leadsObservableSubscription = this.helperService.getLiveQueryLeadsData().subscribe(async (response: any) => {
      console.log("response LeadsData ::: *** ", response);
      const  status = this.toggle === 0 ? '' : (this.toggle === 1 ? 'won' : (this.toggle === 2 ? 'lost' : 'archived'));
      this.loadLeadRequests(status).then();
    });
    track("LEADS");
   this.lostReasonList = 'Not responsive';
    this.route.params.subscribe(params =>{
      if(params){
        this.selectedSpace = params.id;
        this.spaceId = params.id;
        const showTourSteps = localStorage.getItem('showTour');
        if(showTourSteps === 'true'){
          this.showTour = showTourSteps;
          localStorage.setItem('showTour', 'false');
        }
      }
    });
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.sharedSpaceId=this.authService.getData();
    if(localStorage.getItem('activeTab') !== null){
      // @ts-ignore
      this.toggle = +localStorage.getItem('activeTab');
    }else{
      this.toggle = 0;
      localStorage.setItem('activeTab', this.toggle.toString());
    }
    this.lostStatus = 'tt4nl8dJg6';
    this.wonStatus = 'lGXBCm91gj';
    if(this.selectedSpace){
      this.getSpaces(this.selectedSpace).then();
    }else{
      this.getSpaces().then();
    }
    //this.ngxLoader.start();
    this.getProducts().then();
    this.currentUser = this.userService.getUser();
    this.initializeLeadForm();
    this.initializeStageForm();
    this.initializeProductForm();
    this.loadTeams();
    this.isShowTxtField = false;
    this.initializeTagField();
    //this.setKanbanData();
  }

  public ngAfterViewInit(): void {
    if(this.router.url === '/lead/add') {
      this.addLead();
    }
    this.dtTrigger.next();
  }

  public async loadTeams() {
    let userParams ={};
    if(this.authService.isSuperAdmin()) {
      userParams = {
        where: {
          isActive: true,
        },
      };
    }
    if(this.authService.isAdmin()) {
      userParams = {
        where: {
          isActive: true,
          entityId: {__type: 'Pointer', className: 'Entity', objectId: this.authService.getUser().entityId.objectId},
        },
      };
    }
    if(this.authService.isManager()) {
      userParams = {
        where: {
          isActive: true,
          manager: {__type: 'Pointer', className: '_User', objectId: this.authService.getUser().objectId},
        },
      };
    }
    const users = await this.dataService.getFromServer(
      'classes/_User',
      userParams,
    );
    this.userPageData = users && users.results || [];
  }

  public async getProducts() {
    let productsQuery;
    if(!this.authService.isSuperAdmin()){
      productsQuery = {
        where: {
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
        },
      };
    }
    const resp = await this.dataService.getFromServer(
      'classes/Products',
      productsQuery,
    );
    this.productList = resp.results;
  }

  public async getSpaces(spaceId?: string ) {
    let spacesQuery: any;
    if(!this.authService.isSuperAdmin()){
      spacesQuery = {
        where: {
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
          isArchived: { $ne : true },
        },
      };
      if (spaceId) {
        spacesQuery.where['objectId'] = spaceId
      }
    }
    const resp = await this.dataService.getFromServer(
      'classes/Spaces',
      spacesQuery,
    );
    this.spacesList = resp.results;
    if(this.selectedSpace){
      this.selectedSpace = {
        __type: 'Pointer',
        className: 'Spaces',
        objectId: this.selectedSpace,
      }
    } else {
      this.selectedSpace = resp.results.length > 0 ? this.spacesList[0] : null;
    }
   if(spaceId){
    await this.getStagesList(spaceId).then();
   }else{
    await this.getStagesList().then();
   }
    //const status = this.toggle === 0 ? '' : (this.toggle === 1 ? 'won' : 'lost');
      const  status = this.toggle === 0 ? '' : (this.toggle === 1 ? 'won' : (this.toggle === 2 ? 'lost' : 'archived'));
    this.loadLeadRequests(status).then();
  }

  public async getContacts() {
    let contactConditionParams;
    if (this.userService.isSuperAdmin) {
      contactConditionParams = {
        where: {
          isActive: { $ne: false },
        },
      };
    } else if (this.userService.isAdmin) {
      contactConditionParams = {
        where: {
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
          isActive: { $ne: false },
        },
      };
    } else if (this.userService.isAgent) {
      contactConditionParams = {
        where: {
          $or: [
            {
              agent: {
                __type: 'Pointer',
                className: '_User',
                objectId: this.authService.getUser().objectId,
              },
            },
            {
              assignTo: {
                __type: 'Pointer',
                className: '_User',
                objectId: this.authService.getUser().objectId,
              },
            },
          ],
          isActive: { $ne: false },
        },
      };
    }
    const res = await this.dataService.getFromServer(
      'classes/Contacts',
      contactConditionParams,
    );
    let resultsList = res.results || [];
    this.contactList = resultsList.map((contact:any) => ({
      ...contact,
      name: contact.name || 'NA',
      email: contact.email || 'NA',
      phoneNumber: contact.phoneNumber || 'NA',
      gender: contact.gender || 'NA',
      address: contact.address || 'NA',
      city: contact.city || 'NA',
      state: contact.state || 'NA',
      country: contact.country || 'NA',
      contactType: contact.contactType || 'NA'
    }));   
  }

  public onChangeCustomer(e: any) {
    console.log(e)
  }

  public selectCustomer(e: any) {
    this.newLeadForm.get('email')!.setValue(e.email);
    this.newLeadForm.get('phone')!.setValue(e.phoneNumber);
    this.newLeadForm.get('contactId')!.setValue(e.objectId);
  }

  public async getStagesList(spaceId?: string ) {
    let params: any;
    if (this.authService.getUser().entityId && !this.authService.isSuperAdmin()) {
      params = {
        where: {
          $or: [
            {
              entity: {
                __type: 'Pointer',
                className: 'Entity',
                objectId: this.authService.getUser().entityId.objectId,
              },
            },
         //  { entity: { $exists: false } },
          ],
        },
      };
      if (spaceId) {
        params.where.space = {
          __type: 'Pointer',
          className: 'Spaces',
          objectId: spaceId,
        };
      }
      // if (this.selectedSpace) {
      //   params.where.space = {
      //     __type: 'Pointer',
      //     className: 'Spaces',
      //     objectId: this.selectedSpace.objectId,
      //   };
      // }
    } else {
      if (this.selectedSpace) {
        params = {
          where: {
            space: {
              __type: 'Pointer',
              className: 'Spaces',
              objectId: this.selectedSpace.objectId,
            },
          },
        }
      }
    }
    const stagesList = await this.dataService.getFromServer(
      'classes/Stages',
      params,
    );
    this.stagesListData = stagesList ? stagesList.results : [];
    this.stagesListData = this.stagesListData.sort((a: any, b: any)=> a.order - b.order);
    this.lastStage = this.stagesListData[this.stagesListData.length -1];
    stagesList.results.forEach((stage: Stages) => {
      if (stage.name === 'Closing') {
        this.closingObjectId = stage.objectId;
      }
    });
  }


  public async loadLeadRequests(status: string) {
    let requestleadParams: any = {};
    let isArchivedRequested = false;
    const getCurrentUser = this.authService.getUser();
    // this.ngxLoader.start();
    if (this.authService.isSuperAdmin()) {
      requestleadParams = {
        include: 'contact,agent,stage,creator',
        order: '-createdAt',
      };
    } else if (this.authService.isAdmin()) {
      requestleadParams = {
        include: 'contact,agent,stage,creator,assignTo,status',
        order: '-createdAt',
        where: {
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
        },
      };
    } else if (this.authService.isAgent()) {
      requestleadParams = {
        include: 'contact,agent,policyType,stage,creator,assignTo,status',
        order: '-createdAt',
        where: {
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
        },
      };
      if(!!getCurrentUser.isPrivate) {
        requestleadParams = {
          where: {
            ...requestleadParams.where,
            $and: [
              {
                $or: [
                  {
                    agent: {
                      __type: 'Pointer',
                      className: '_User',
                      objectId: this.authService.getUser().objectId,
                    },
                  },
                  {
                    assignTo: {
                      __type: 'Pointer',
                      className: '_User',
                      objectId: this.authService.getUser().objectId,
                    },
                  },
                ],
              },
            ],
          },
        }
      }
    } else if (this.authService.isManager()) {
      const parameters = {
        where: {
          manager: {
            __type: 'Pointer',
            className: '_User',
            objectId: this.authService.getUser().objectId,
          },
        },
      };
      const userResponse = await this.dataService.getFromServer(
        'classes/_User',
        parameters,
      );
      const agentsList: {
        __type: string;
        className: string;
        objectId: string;
      }[] = [];
      userResponse.results.forEach((user1: User) => {
        agentsList.push({
          __type: 'Pointer',
          className: '_User',
          objectId: user1.objectId,
        });
        this.agentsListData.push(user1);
      });
      agentsList.push({
        __type: 'Pointer',
        className: '_User',
        objectId: this.authService.getUser().objectId,
      });
      requestleadParams = {
        include: 'contact,agent,policyType,stage,creator,assignTo,status',
        order: '-createdAt',
        where: {
          $and: [
            {
              $or: [
                {agent: {$in: agentsList}},
                {assignTo: {$in: agentsList}},
              ],
            },
          ],
        },
      };
    }

    requestleadParams.where.isArchived = { $ne: true };

    if (status === 'archived') {
      isArchivedRequested = true;
    }

    if (isArchivedRequested) {
      requestleadParams.where = {
          ...requestleadParams.where,
          isArchived: true,
      };
    }
    // requestleadParams.where.stage = {$in: stages};
    requestleadParams.limit = 10000;
    if(!this.shwAllLeads && this.selectedDates && this.selectedDates.startDate && this.selectedDates.endDate) {
      const startDate = moment(this.selectedDates.startDate).toISOString();
      const endDate = moment(this.selectedDates.endDate).toISOString();
      if(requestleadParams.where.$and) {
        requestleadParams.where.$and = [
          ...requestleadParams.where.$and,
          {$or: [{createdAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}}},
          {updatedAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}}}]},
        ];
      } else {
        requestleadParams.where = {
          ...requestleadParams.where,
          $or: [
            { createdAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}}},
            { updatedAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}}},
          ],
        }
      }
    }
    const response = await this.leadsService.leadRequests(requestleadParams);
    // this.ngxLoader.stop();
    // await new Promise(resolve =>  setTimeout(resolve, 100))
    this.leadPageData = response || [];
    this.showNoLeadsMessage = this.leadPageData.length === 0;
    this.noLeadstext = getMessage(this.selectedRangeFilter || 'lastThirtyDays');

    // this.isLeadArchive == 'archive' ? null : this.setKanbanData();
    this.setKanbanData();

    this.dtOptions = {
      order: [
        [2, 'desc'],
        [4, 'desc'],
      ],
      dom: '<"appRequestsFilter"lf>tipr',
      buttons: [
        {
          extend: 'csv',
          title: 'Export InLeads-leads',
        },
      ],
      columnDefs: [
        {
          orderable: false,
          targets: 0,
          responsivePriority: 1,
        },
        {
          orderable: false,
          targets: 1,
          // responsivePriority: 1,
        },
        {
          responsivePriority: 1,
          targets: 12,
        },
        {
          targets: [2],
          visible: false,
        },
        {
          responsivePriority: 2,
          targets: 4,
        },
        {
          responsivePriority: 1,
          targets: 3,
        },
        {
          responsivePriority: 3,
          targets: 5,
        },
        {
          defaultContent: '',
          targets: '_all',
        },
      ],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search...',
        lengthMenu: '<span>Show</span> _MENU_',
      },
      responsive: true,
      data: this.leadPageData.filter(
        (x) =>
          x.stage &&
          x.stage.space &&
          x.stage!.space!.objectId === this.selectedSpace.objectId,
      ),
      columns: [
        { data: '' },
        { data: '' },
        {
          data: (row: Leads) => {
            return moment(row.createdAt).valueOf();
          },
        },
        {
          data: (row: Leads) => {
            return (
              '<div style="width:200px; white-space:normal;">' +
              row.description +
              '</div>'
            );
          },
        },
        {
          data: (row: Leads) => {
            return row.contact ? row.contact.name : 'NA';
          },
        },
        {
          data: (row: Leads) => {
            return row.dealValue ? row.dealValue : 'NA';
          },
        },
        {
          data: (row: Leads) => {
            return row.stage ? row.stage.name : 'NA';
          },
        },
        {
          data: (row: Leads) => {
            return row.contact ? row.contact.phoneNumber : 'NA';
          },
        },
        {
          data: (row: Leads) => {
            return row.contact ? row.contact.email : 'NA';
          },
        },
        {
          data: (lead: Leads) => {
            return lead.closingDate
              ? moment(lead.closingDate.iso).format('MM/DD/YYYY')
              : 'NA';
          },
        },
        {
          data: (lead: Leads) => {
            return lead.activityNotes ? lead.activityNotes : 'NA';
          },
        },
        {
          data: (row: Leads) => {
            const agentName = row.assignTo;
            return agentName ? agentName!.name : 'NA';
          },
        },
        {
          data: (row: Leads) => {
            let str = '';
            if (!row.status) {
              str =
                '</span><span title="Tasks" class="fa fa-stack-exchange cpointer"></span>&nbsp;&nbsp;&nbsp;&nbsp;';
            }
            return (
              '<span title="Edit Lead" class="fa fa-pencil cpointer">&nbsp;&nbsp;&nbsp;&nbsp;' +
              str
            );
          },
        },
        {
          data: (row: Leads) => {
            return row.creator ? row.creator!.name : 'NA';
          },
        },
      ],
      rowCallback: (row: Node, data: object) => {
        row.removeEventListener('click', () => {});
        row.addEventListener('click', (e) => {
          if ((e.target as HTMLElement).classList.contains('fa-pencil')) {
            this.editLead(data);
          } else if ((e.target as HTMLElement).classList.contains('fa-check')) {
            this.statusUpdateModal = data as Leads;
          } else if ((e.target as HTMLElement).classList.contains('fa-clock-o')) {
            this.openUpdateDailyStatus(data as Leads);
          } else if (!(e.target as HTMLElement).classList.contains('dtr-control')) {
            const leadId = (data as Leads).objectId;
            this.router.navigate(['/lead-details', { leadId, spaceId : this.spaceId }]);
          }
        });
        return row;
      },
    };
    if (response && response.length) {
      setTimeout(this.generateFilters, 100);
    }

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      setTimeout(() => {
        this.dtTrigger.next();
      }, 10);
    });
  }

  private initializeTagField() {
    this.tagField = this.tagField || {};
    this.tagField = {
      type: 'select',
      name: 'userTags',
      FormControlName: 'userTags',
      tags: true,
      placeholder: 'Select Tags',
      select2Data: '',
      select2Options: { multiple: true, tags: true },
    };
  }

  public searchLeads() {
    const query = this.kanbanSearchQuery.toLowerCase();
    this.kanbanList.forEach((stage: any) => {
      stage.data = stage.data.filter((lead: any) => {
        const contactName = (lead.contact && lead.contact.name) ? lead.contact.name.toLowerCase() : '';
        const stageName = (lead.stage && lead.stage.name) ? lead.stage.name.toLowerCase() : '';
        const description = lead.description ? lead.description.toLowerCase() : '';

        return contactName.includes(query) ||
                stageName.includes(query) ||
                description.includes(query);
      });
    });
  }

  public async renderTable(status: string) {
    if (status === '') {
      this.statusType = '';
      await this.loadLeadRequests('');
      this.toggle = 0;
    } else if (status === 'isArchived') {
      await this.loadLeadRequests('archived');
      this.toggle = 3;
    }
    localStorage.setItem('activeTab', this.toggle.toString());
  }

  private generateFilters = async () => {
    if (this.tableWrapperElement) {
      const filter = this.tableWrapperElement.nativeElement.querySelector(
        '.appRequestsFilter',
      );
      this.dtInstance = await this.datatableElement.dtInstance;
      const dtInstance = await this.datatableElement.dtInstance;
      filter.querySelectorAll('a').forEach((ele: HTMLElement) =>
        ele.addEventListener('click', (evt: Event) => {
          evt.preventDefault();
          const type = (evt.target as HTMLInputElement)!.dataset.type;
          dtInstance
            .column(6)
            .search(type!)
            .draw();
        }),
      );
    }
  };

  public async statusUpdate(lead: Leads, status: string, stageId: string) {
    let statusObjectId = '';
    // tslint:disable-next-line:no-any
    const leadObj: any = {};
    if(this.statusPopOverTemplateRef){
      this.statusPopOverTemplateRef.hide();
      this.statusPopOverTemplateRef = null;
    }
    if (status === 'Won') {
      statusObjectId = this.wonStatus;
      leadObj.stage = {
        __type: 'Pointer',
        className: 'Stages',
        objectId: this.lastStage.objectId,
      };
      this.kanbanComponent.archiveLead(lead)
    }
    if (status === 'Lost') {
      statusObjectId = this.lostStatus;
      this.lostReasonLeadData = lead;
      this.lostReasonLostObjectId = statusObjectId;
     if(this.spacesList[0].type === 'sales'){
      this.lostReasonClosingObjectId = this.closingObjectId;
     }
      this.lostReasonModal!.show();
      this.statusUpdateModal = {};
      return;
    }

    if (status === 'stage') {
      leadObj.stage = {
        __type: 'Pointer',
        className: 'Stages',
        objectId: stageId,
      };
    } else if (status === 'archive') {
      leadObj.isArchived = true;
      this.kanbanComponent.archiveLead(lead)

    }
    else {
      leadObj.status = {
        __type: 'Pointer',
        className: 'Status',
        objectId: statusObjectId,
      }
    }

    if(status === 'Open'){
      leadObj.status = {'__op':'Delete'}
    }

    // if(status === 'archive'){
    //   this.isLeadArchive = 'archive'
    // }else{
    //   this.isLeadArchive = 'notArchive'
    // }

    // if (status === 'unArchive') {
    //   leadObj.isArchived = false;
    //   leadObj.status = {"__op":"Delete"}
    // }

    if (status === 'archive') {
      this.isLeadArchive = 'archive';
      this.notifier.notify('success', 'Lead archived successfully.');
    } else if (status === 'unArchive') {
      this.kanbanComponent.archiveLead(lead)
      leadObj.isArchived = false;
      leadObj.status = {'__op':'Delete'}
      this.notifier.notify('success', 'Lead unarchived successfully.');
    } else{
      this.isLeadArchive = 'notArchive'
    }

    try {
      await this.dataService.updateToServer(
        'classes/Leads/' + lead.objectId,
        leadObj,
      );
      this.statusType = '';

    } catch (e) {
      this.notifier.notify('error', e.error.error);
    }

    this.statusUpdateModal = {};
    // todo
    // const toggleStatus = this.toggle === 0 ? '' : (this.toggle === 1 ? 'won' : 'lost');
    // const toggleStatus = this.toggle === 0 ? '' : (this.toggle === 1 ? 'won' : (this.toggle === 2 ? 'lost' : 'archived'));
    // this.loadLeadRequests(toggleStatus).then();
  }

  public async openNewTaskPopUp(){
    document.body.click();
    const selectedDateTime = moment().toDate();
    this.newTaskForm.get('task_nextActivity')!.setValue(selectedDateTime);
    this.newTaskModal!.show();
    this.newTaskForm.patchValue({ taskStage: this.currentLeadStage,})
  }

  public async openDeleteTaskPopup(task: Tasks){
    document.body.click();
    this.deleteTaskModal!.show();
    this.currentTaskObjectID = task.objectId || '';
  }

  public async deleteCurrentTask(){
    const taskParams: any = {
      isArchive: true,
    };
    const id = this.currentTaskObjectID;
    await this.taskService.updateTask(taskParams , id);
    this.notifier.notify('success', 'Task Archived Successfully!!');
    this.deleteTaskModal!.hide();
  }

  public async openTaskPopup(task: Tasks){
    document.body.click();
    this.openTaskModal!.show();
    this.currentTaskObjectID = task.objectId || '';
  }

  public async openCurrentTask(){
    const taskParams: any = {
      isComplete: false,
    };
    const id = this.currentTaskObjectID;
    await this.taskService.updateTask(taskParams , id);
    this.notifier.notify('success', 'Task opened Successfully!!');
    this.openTaskModal!.hide();
  }


  public async openEditTaskPopUp(task: Tasks){
    document.body.click();
    this.currentTaskObjectID = task.objectId || '';
    this.editTaskForm.patchValue({ editTaskName: task.stageOption, edit_task_nextActivity: task.nextActivity ? new Date(task.nextActivity.iso) : new Date(),
       edit_task_activityNotes: task.activityNotes, editTaskType : task.taskType ? task.taskType : 'Call', editTaskStage : task.stage!.objectId });
    this.editTaskModal!.show();
  }
  public async openUpdateDailyStatus(lead: Leads) {
    this.currentLead = lead;
    this.dailyStatusUpdateModal.show();
    const activityParams = {
      where: {
        lead: {
          __type: 'Pointer',
          className: 'Leads',
          objectId: lead.objectId,
        },
      },
      include: 'lead,lead.agent',
      order: '-createdAt',
    };
    const activityResponse = await this.dataService.getFromServer(
      'classes/Activity',
      activityParams,
    );
    this.activityResponseData = activityResponse
      ? activityResponse.results
      : undefined;
    this.editTasks(lead);
  }

  public async updateDailyStatus() {
    this.isPageLoading = true;
    const message = this.dailyStatusUpdateForm.get('message')!.value;
    if (message.length > 0) {
      const activityParams: ActivityParams = {
        type: 'Status Update',
        message,
        lead: {
          __type: 'Pointer',
          className: 'Leads',
          objectId: this.currentLead.objectId,
        },
        user: {
          __type: 'Pointer',
          className: '_User',
          objectId: this.authService.getUser().objectId,
        },
      };
      await this.dataService.postToServer('classes/Activity', activityParams);
      this.dailyStatusUpdateModal.hide();
      this.dailyStatusUpdateForm.setValue({ message: '', type: '', stage: '' });
      this.isPageLoading = false;
      this.loadLeadRequests('').then();
    } else {
      this.isPageLoading = false;
      this.notifier.notify('error', 'Message cannot be empty!!');
      return;
    }
  }

  public async editLead(leadData: any) {
    this.editLeadModal.show();
    this.getContacts();
    // const leadParams = {
    //   include: 'contact,agent,creator,policyType,stage',
    // };
    // const lead = await this.dataService.getFromServer(
    //   'classes/Leads/' + objectId,
    //   leadParams,
    // );
    const lead = leadData;
    /*let nextActivity;
    if (lead && lead.nextActivity) {
      nextActivity = moment(new Date(lead.nextActivity.iso)).format(
        'MM/DD/YYYY',
      );
    }*/
    let closingDate;
    if (lead && lead.closingDate) {
      closingDate = moment(new Date(lead.closingDate.iso)).format('MM/DD/YYYY');
    }

    let name: string = lead!.name;
    if (!name && lead!.contact) {
      name = (lead!.contact && lead!.contact!.name) || 'NA';
    }

    let email: string = lead!.email;
    if (!email && lead!.contact) {
      email = (lead!.contact && lead!.contact!.email) || 'NA';
    }

    let phoneNumber: number | string = lead!.phoneNumber;
    if (!phoneNumber && lead!.contact) {
      phoneNumber = (lead!.contact && lead!.contact!.phoneNumber) || 'NA';
    }

    /*let gender: string = lead!.gender;
    if (!gender && lead!.contact) {
      gender = (lead!.contact && lead!.contact!.gender) || '';
    }*/

    let address: string;
    address = '';
    if (!address && lead!.contact) {
      address = (lead!.contact && lead!.contact!.address) || '';
    }
    let state: string;
    state = '';
    if (!state && lead!.contact) {
      state = (lead!.contact && lead!.contact!.state) || '';
    }
    let pincode: number;
    pincode = 0;
    if (!pincode && lead!.contact) {
      pincode = (lead!.contact && lead!.contact!.pincode) || 0;
    }
    const dealValue: string = lead!.dealValue;
    /*const activityNotes: string =
      lead && lead.activityNotes ? lead.activityNotes : '';*/
    const userAgent: string = lead && lead.agent ? lead.agent.objectId : '';
    const creator: string = lead && lead.creator ? lead.creator.name : '';
    const leadAssignTo: string =
      lead && lead.assignTo ? lead.assignTo.objectId : userAgent;
    const stage: string = lead && lead.stage ? lead.stage.objectId : '';
    const product: string = lead && lead.product ? lead.product.objectId : '';
    const contact: string = lead && lead.contact ? lead.contact.objectId : '';
    const noOfSeats: string = lead && lead.noOfSeats ? lead.noOfSeats : '';
    const estimatedContractLength: string = lead && lead.estimatedContractLength ? lead.estimatedContractLength : '';

    this.editLeadForm.patchValue({
      objectId: lead!.objectId,
      fullName: name || '',
      email: email || '',
      phone: phoneNumber || '',
      stage: stage || '',
      product: product || '',
      dealValue: dealValue || '',
      category: lead!.category || '',
      description: lead!.description || '',
      // nextActivity,
      // assignTo: userAgent || '',
      creator: creator || '',
      leadAssignTo: leadAssignTo || '',
      contact: contact || '',
      closingDate,
      noOfSeats: noOfSeats || '',
      estimatedContractLength: estimatedContractLength || '',
    });
    // this.showOrhideTxt();

    this.loadLeadRequests('').then();
  }

  public async editTasks(lead: Leads) {
  this.opt = [];

    const taskParams = {
      where: {
        stage: {
          __type: 'Pointer',
          className: 'Stages',
          objectId: lead!.stage!.objectId,
        },
        lead: {
          __type: 'Pointer',
          className: 'Leads',
          objectId: lead!.objectId,
        },
        isArchive: { $ne :true},
      },
      include: 'stage,lead,lead.contact',
      order: 'createdAt',
    };
    let tasks = await this.dataService.getFromServer(
      'classes/Tasks',
      taskParams,
    );
    tasks = tasks && tasks.results;
    /* const curTask = tasks.filter((x: Tasks) => {
      // return x!.stage!.objectId === lead!.stage!.objectId;
      return !x.isComplete;
    }); */
    this.opt = tasks && tasks.filter((x: Tasks) => {
      if (tasks.length === 0) {
        return x.stage!.name === 'Nurturing';
      } else {
        return x.stage!.name === tasks[0].stage.name;
      }
    });
    this.dailyStatusUpdateForm
      .get('stage')!
      .setValue(this.opt && this.opt[0] ? this.opt[0].stage!.name : '');
    return true;
  }

  private initializeProductForm() {
    this.newProductForm = this.fb.group({
      product_name: ['', Validators.required],
      product_description: ['', Validators.required],
      price_type: ['onetime', Validators.required],
      price_period: ['', Validators.required],
      new_price: [, Validators.required],
      new_sheets: [, Validators.required],
    });
  }

  public async createProduct(){
    const productName = this.newProductForm.get('product_name')!.value;
    const productPriceType = this.newProductForm.get('price_type')!.value;
    let productPricePeriod = this.newProductForm.get('price_period')!.value;
    const productPrice = this.newProductForm.get('new_price')!.value;
    const productDescription = this.newProductForm.get('product_description')!.value;
    if(!productName){
      this.notifier.notify('error', 'Name cannot be empty');
      return
    }
    // if(!productDescription){
    //   this.notifier.notify('error', 'Description cannot be empty');
    //   return
    // }
    // if(!productPriceType){
    //   this.notifier.notify('error', 'Price Type cannot be empty');
    //   return
    // }
    if(!productPricePeriod && productPriceType === 'recurring'){
      this.notifier.notify('error', 'Price Period cannot be empty');
      return
    }
    if(productPriceType === 'onetime') {
      productPricePeriod = '';
    }
    // if(!productPrice){
    //   this.notifier.notify('error', 'Price cannot be empty');
    //   return
    // }

    const productParams: Products = {
      name: productName,
      description: productDescription,
      entity: {
        __type: 'Pointer',
        className: 'Entity',
        objectId: this.authService.getUser().entityId.objectId,
      },
      website: '',
      objectId: '',
      priceType: productPriceType,
      pricePeriod: productPricePeriod,
      price: productPrice
    };
    const productResponse = await this.dataService.postToServer('classes/Products/', productParams);
    if(productResponse){
      this.notifier.notify('success', 'Product created Successfully!!!');
      this.productID = productResponse.objectId;
      this.newProductForm.reset();
      this.newProductModal!.hide();
    }
    // this.ngxLoader.start();
    this.getProducts();
    // this.initializeLeadForm();
    this.newLeadForm.get('product')!.setValue(productName);
    // this.ngxLoader.stop();
  }

  private initializeStageForm() {
    this.newStageForm = this.fb.group({
      name: ['', Validators.required],
      entity: [''],
    });
  }

  private initializeLeadForm() {
    this.editLeadForm = this.fb.group({
      stage: [''],
      product: [''],
      noOfSeats: ['', Validators.required],
      estimatedContractLength: ['', Validators.required],
      fullName: ['', Validators.required],
      email: [
        '',
        [Validators.required, Validators.pattern(validationPattern.email)],
      ],
      phone: [
        '',
        [
          Validators.required,
         // this.phoneValidator(),
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(validationPattern.phone),
        ],
      ],
      dealValue: [''],
      // gender: [''],
      description: [''],
      // nextActivity: ['', Validators.required],
      // activityNotes: [''],
      // assignTo: [''],
      creator: [''],
      leadAssignTo: [''],
      objectId: ['', Validators.required],
      contact: [''],
      closingDate: ['', Validators.required],
    });
    this.newTaskForm = this.fb.group({
      taskType: ['', Validators.required],
      taskStage: ['', Validators.required],
      newTaskName: ['', Validators.required],
      task_nextActivity: [new Date(), Validators.required],
      task_activityNotes: [''],
    });

    this.editTaskForm = this.fb.group({
      editTaskName: ['', Validators.required],
      editTaskType: ['', Validators.required],
      editTaskStage: ['', Validators.required],
      edit_task_nextActivity: [new Date(), Validators.required],
      edit_task_activityNotes: [''],
    });

    this.newLeadForm = this.fb.group({
      objectId: ['', Validators.required],
      stage: [''],
      product: [''],
      fullName: ['', Validators.required],
      email: [
        '',
        [Validators.pattern(validationPattern.email)],
      ],
      phone: ['',
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(validationPattern.phone),
        ],
      ],
      dealValue: [''],
      gender: [''],
      city: [''],
      address: [''],
      state: [''],
      pincode: [''],
      description: [''],
      nextActivity: [new Date(), Validators.required],
      createdAt: [new Date(), Validators.required],
      activityNotes: [''],
      assignTo: [''],
      creator: [''],
      leadAssignTo: [''],
      contactId: [''],
      closingDate: ['', Validators.required],
      noOfSeats: ['', Validators.required],
      estimatedContractLength: ['', Validators.required],
      userTags: [''],
    });
    this.dailyStatusUpdateForm = this.fb.group({
      type: [''],
      stage: [''],
      message: [''],
    });

    this.lostReasonForm = this.fb.group({
      lostReasondescription: [''],
    });

    this.dateForm = this.fb.group({
      startDate: [new Date(), Validators.required],
      endDate: [new Date(), Validators.required],
    });
    setTimeout(() => {
      this.newLeadForm.get('product')!.setValue(this.productList[0].objectId);
      // Setting the price value when the default product is selected initially in the newLeadForm
      this.newLeadForm.get('product')!.valueChanges.subscribe((selectedProductId) => {
        this.setDealValueFromProduct(this.newLeadForm, selectedProductId);
      });
      const newLeadDefaultProductId = this.productList[0].objectId;
      this.setDealValueFromProduct(this.newLeadForm, newLeadDefaultProductId);

      // Setting the price value when the default product is selected initially in the editLeadForm
      this.editLeadForm.get('product')!.valueChanges.subscribe((selectedProductId) => {
        this.setDealValueFromProduct(this.editLeadForm, selectedProductId);
      });
      const editLeadDefaultProductId = this.productList[0].objectId;
      this.setDealValueFromProduct(this.editLeadForm, editLeadDefaultProductId);
    }, 500);
  }

   phoneValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const phone = control.value;
      if (!phone) {
        // If the phone number is empty, return null to indicate that it is valid.
        return null;
      } else if (phone.length !== 10 || isNaN(phone)) {
        // If the phone number is not exactly 10 digits or contains non-numeric characters, return an error object.
        return { invalidPhone: true };
      } else {
        // If the phone number is valid, return null to indicate that it is valid.
        return null;
      }
    };
  }

  // calculating Expected revenue based on no.of subscriptions and estimated contract length
  public onChangeSubsVal(event: Event, prod: any, form: FormGroup) {
    const subsValue = +(event.target as HTMLInputElement).value;
    const eclValue = form.get('estimatedContractLength')!.value;
    this.calcExpRevenue(prod, form, subsValue, eclValue ? eclValue : 1 );
  }

  public onChangeEclVal(event: Event, prod: any, form: FormGroup) {
    const eclValue = +(event.target as HTMLInputElement).value;
    const subsValue = form.get('noOfSeats')!.value;
    this.calcExpRevenue(prod, form, subsValue ? subsValue : 1, eclValue);
  }

  public calcExpRevenue(prod: any, form: FormGroup, subsValue:any, eclValue:any) {
    const selectedProduct = this.productList.find((product) => product.objectId === prod);
    let factor = 1;
    if(selectedProduct.pricePeriod === 'monthly'){
      factor = 12;
    } else if(selectedProduct.pricePeriod === 'weekly'){
      factor = 52;
    } else if(selectedProduct.pricePeriod === 'quarterly'){
      factor = 4;
    } else if(selectedProduct.pricePeriod === 'halfyearly'){
      factor = 2;
    }
    const finalExpRevenue = (subsValue * eclValue * selectedProduct.price * factor);
    form.get('dealValue')!.patchValue(finalExpRevenue);
  }

  // setting the deal value from the product
  public setDealValueFromProduct(formGroup: FormGroup, productId: string) {
    const selectedProduct = this.productList.find((product) => product.objectId === productId);

    if (selectedProduct) {
      formGroup.get('dealValue')!.setValue(selectedProduct.price);
    } else {
      formGroup.get('dealValue')!.reset();
    }
  }

  public async saveLead() {
    const name = this.editLeadForm.get('fullName')!.value;
    const email = this.editLeadForm.get('email')!.value;
    const phoneNumber = this.editLeadForm.get('phone')!.value.toString();
    // tslint:disable-next-line:radix
    const dealValue = Number(this.editLeadForm.get('dealValue')!.value);
    // const gender = this.editLeadForm.get('gender')!.value;
    // const nextActivity = this.editLeadForm.get('nextActivity')!.value;
    const description = this.editLeadForm.get('description')!.value;
    // const activityNotes = this.editLeadForm.get('activityNotes')!.value;
    // const agentLead = this.editLeadForm.get('assignTo')!.value;
    const leadAssignTo = this.editLeadForm.get('leadAssignTo')!.value;
    const stageObjectId = this.editLeadForm.get('stage')!.value;
    const productId = this.editLeadForm.get('product')!.value;
    const objectId = this.editLeadForm.get('objectId')!.value;
    const contactId = this.editLeadForm.get('contact')!.value;
    const closingDate = this.editLeadForm.get('closingDate')!.value;
    const noOfSeats = this.editLeadForm.get('noOfSeats')!.value;
    const estimatedContractLength = this.editLeadForm.get('estimatedContractLength')!.value;
    if(!description){
      this.notifier.notify('error', 'Description cannot be empty');
      return;
    }
    if(!name){
      this.notifier.notify('error', 'Customer Name cannot be empty');
      return;
    }
    if(!dealValue){
      this.notifier.notify('error', 'Expected Revenue cannot be empty');
      return;
    }
    if(this.isRecurringPrice()){
      if(!noOfSeats){
        this.notifier.notify('error', 'No.of Subscriptions cannot be empty');
        return;
      }
    }
    if(this.isRecurringPrice()){
      if(!estimatedContractLength){
        this.notifier.notify('error', 'Estimated Contract Length cannot be empty');
        return;
      }
    }

    const leadParams: LeadParams = {
      dealValue,
      stage: {
        __type: 'Pointer',
        className: 'Stages',
        objectId: stageObjectId,
      },
      /*nextActivity: {
        __type: 'Date',
        iso: new Date(nextActivity),
      },
      activityNotes,*/
      description,
      contact: {
        __type: 'Pointer',
        className: 'Contacts',
        objectId: contactId,
      },
      closingDate: {
        __type: 'Date',
        iso: new Date(closingDate),
      },
    };

    if(productId) {
      leadParams.product = {
        __type: 'Pointer',
        className: 'Products',
        objectId: productId,
      }
    }

    /*if (agentLead) {
      leadParams.agent = {
        __type: 'Pointer',
        className: '_User',
        objectId: agentLead,
      };
    }*/

    if (leadAssignTo) {
      leadParams.assignTo = {
        __type: 'Pointer',
        className: '_User',
        objectId: leadAssignTo,
      };
    }

    if (!leadAssignTo && !this.userPageData.length) {
      leadParams.assignTo = {
        __type: 'Pointer',
        className: '_User',
        objectId: this.authService.getUser().objectId,
      };
    }

    if(noOfSeats){
      leadParams.noOfSeats = noOfSeats
    }
    if(estimatedContractLength){
      leadParams.estimatedContractLength = estimatedContractLength
    }

    /*if (!agentLead && !this.userPageData.length) {
      leadParams.agent = {
        __type: 'Pointer',
        className: '_User',
        objectId: this.authService.getUser().objectId,
      };
    }*/

    this.editLeadModal.hide();
    await this.dataService.updateToServer(
      'classes/Leads/' + objectId,
      leadParams,
    );
    const contactsParams: ContactsParams = {
      objectId: contactId,
      name,
      email,
      phoneNumber,
      dealValue,
      entity: {
        __type: 'Pointer',
        className: 'Entity',
        objectId: this.authService.getUser().entityId.objectId,
      },
    };

    /*if (agentLead) {
      contactsParams.agent = {
        __type: 'Pointer',
        className: '_User',
        objectId: agentLead,
      };
    }*/

    if (leadAssignTo) {
      contactsParams.assignTo = {
        __type: 'Pointer',
        className: '_User',
        objectId: leadAssignTo,
      };
    }
    await this.dataService.updateToServer(
      'classes/Contacts/' + contactId,
      contactsParams,
    );
    // this.notifier.notify('success','Lead Updated Successfully!!');
    this.loadLeadRequests('').then();
  }

  public addLead(event?: Event) {
    if(event) {
    event.preventDefault();
    }
    this.getContacts();

    this.initializeLeadForm();
    this.newleadModal!.show();
    if (this.userService.isAgent) {
      this.newLeadForm.get('assignTo')!.setValue(this.currentUser!.name);
    }
    this.newLeadForm.get('creator')!.setValue(this.currentUser!.name);
   // this.newLeadForm.get('stage')!.setValue(this.prospectingObjectId);
  }

  public addProduct(event: Event) {
    event.preventDefault();
    this.newProductModal!.show();
  }
  public addStage(event: Event) {
    event.preventDefault();
    this.initializeLeadForm();
    this.newStageForm = this.fb.group({
      name: ['', Validators.required],
      entity: [''],
    });

    this.newStageModal!.show();
    // this.newLeadForm.get('creator')!.setValue(this.currentUser!.name);
  }

  public async updateTask() {
    const name = this.editTaskForm.get('editTaskName')!.value;
    const taskType = this.editTaskForm.get('editTaskType')!.value;
    const taskNextActivity = this.editTaskForm.get('edit_task_nextActivity')!.value;
    const taskActivityNotes = this.editTaskForm.get('edit_task_activityNotes')!.value;
    const editTaskStage = this.editTaskForm.get('editTaskStage')!.value;
    if(!name){
      return this.notifier.notify('error', 'Please Enter Name');
    }
    if(!taskType){
      return this.notifier.notify('error', 'Please Select Task Type');
    }
    if(!taskNextActivity){
      return this.notifier.notify('error', 'Please Enter Next Activity');
    }
    const taskParams: any = {
      stageOption: name,
      taskType,
      nextActivity: {
        __type: 'Date',
        iso: new Date(taskNextActivity),
      },
      stage :{
        __type: 'Pointer',
        className: 'Stages',
        objectId: editTaskStage,
      },
      activityNotes: taskActivityNotes,
    };
    const id = this.currentTaskObjectID;
    this.isButtonDisabled = true;
    this.editTaskModal!.hide();
    await this.taskService.updateTask(taskParams , id);
    this.notifier.notify('success', 'Task Updated Successfully!!');
    this.setKanbanData()

  }

  public async createTask(isAddToCalendar?: boolean) {
    const name = this.newTaskForm.get('newTaskName')!.value;
    const taskType = this.newTaskForm.get('taskType')!.value;
    const taskStage = this.newTaskForm.get('taskStage')!.value;
    const taskNextActivity = this.newTaskForm.get('task_nextActivity')!.value;
    const taskActivityNotes = this.newTaskForm.get('task_activityNotes')!.value;
    if(!name){
      return this.notifier.notify('error', 'Please Enter Name');
    }
    if(!taskType){
      return this.notifier.notify('error', 'Please Select Task Type');
    }
    if(!taskNextActivity){
      return this.notifier.notify('error', 'Please Enter Next Activity');
    }
    const taskParams: any = {
      objectId: '',
      stageOption: name,
      taskType,
      nextActivity: {
        __type: 'Date',
        iso: new Date(taskNextActivity),
      },
      activityNotes: taskActivityNotes,
      lead :{
        __type: 'Pointer',
        className: 'Leads',
        objectId: this.currentNewTaskLead,
      },
      stage :{
        __type: 'Pointer',
        className: 'Stages',
        objectId: taskStage,
      },
      entity :{
        __type: 'Pointer',
        className: 'Entity',
        objectId: this.currentUser!.entityId!.objectId,
      },
    };
    this.isButtonDisabled = true;
    this.newTaskModal!.hide();
    await this.taskService.addTask(taskParams);
    this.notifier.notify('success', 'Task created Successfully!!');
    if(taskNextActivity && !!isAddToCalendar) {
      const data = await this.icalService.parseIcal(taskNextActivity, name, taskActivityNotes);
      const blob = new Blob([data], {type: 'text/calendar'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'calendar.ics';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url)
    }

    this.newTaskForm.setValue({ newTaskName: '', task_nextActivity: '', task_activityNotes: '', taskType : '',taskStage:'' });
    this.newTaskForm.reset();
    this.setKanbanData()

  }

  public async createLead() {
    // this.ngxLoader.start();
    const name = this.newLeadForm.get('fullName')!.value;
    const email = this.newLeadForm.get('email')!.value;
    const phoneNumber = this.newLeadForm.get('phone')!.value;
    const contactId = this.newLeadForm.get('contactId')!.value;
    const dealValue = parseInt(this.newLeadForm.get('dealValue')!.value);
    const gender = this.newLeadForm.get('gender')!.value;
    // const city = this.newLeadForm.get('city')!.value;
    // const address = this.newLeadForm.get('address')!.value;
    // const state = this.newLeadForm.get('state')!.value;
    // const pincode = this.newLeadForm.get('pincode')!.value;
    // const policyType = this.newLeadForm.get('policyType')!.value;
    const description = this.newLeadForm.get('description')!.value;
    const nextActivity = this.newLeadForm.get('nextActivity')!.value;
    const createdAt = this.newLeadForm.get('createdAt')!.value;
    const activityNotes = this.newLeadForm.get('activityNotes')!.value;
    let assignTo = '';
    const leadAssignTo = this.newLeadForm.get('leadAssignTo')!.value;
    // const stageObjectId = this.newLeadForm.get('stage')!.value;
    const stageObjectId = this.stagesListData[0].objectId;
    const closingDate = moment().add(15,"days");
    let noOfSeats = this.newLeadForm.get('noOfSeats')!.value;
    let estimatedContractLength = this.newLeadForm.get('estimatedContractLength')!.value;
    const category = 'Lead';
    const user_tags: string[] = this.newLeadForm.get('userTags')!.value;
    let tags = user_tags ? user_tags : [];
   
    if(!name){
      return this.notifier.notify('error', 'Please Enter Name');
    }
    // if(!email){
    //   return this.notifier.notify('error', 'Please Enter Email');
    // }
    // if(!phoneNumber){
    //   return this.notifier.notify('error', 'Please Enter Phone Number');
    // }
    // if(!dealValue){
    //   return this.notifier.notify('error', 'Please Enter Valid Deal Value');
    // }
    if(!closingDate){
      return this.notifier.notify('error', 'Please Enter Valid Closing Date');
    }
    if(!stageObjectId){
      return this.notifier.notify('error', 'Please Select Stage');
    }   
    if(this.isRecurringPrice()){
      if(!noOfSeats){
        return this.notifier.notify('error', 'Please Enter No Of Subscriptions');
      }
    }
    if(this.isRecurringPrice()){
      if(!estimatedContractLength){
        return this.notifier.notify('error', 'Please Enter Estimated Contract Length');
      }
    }
    // if(!city){
    //   return this.notifier.notify('error', 'Please Enter City');
    // }
    // if(!address){
    //   return this.notifier.notify('error', 'Please Enter Address');
    // }
    // if(!state){
    //   return this.notifier.notify('error', 'Please Enter State');
    // }
    // if(!pincode){
    //   return this.notifier.notify('error', 'Please Enter Pincode');
    // }
    if(!description){
      return this.notifier.notify('error', 'Please Enter Description');
    }
    if (this.authService.isAgent()) {
      assignTo = this.authService.getUser().objectId;
    } else {
      assignTo = this.newLeadForm.get('assignTo')!.value;
    }
    this.newleadModal!.hide();
    const contactParams: ContactsParams = {
      name,
      // city,
      // address,
      // state,
      // tslint:disable-next-line:radix
      // pincode: parseInt(pincode),
      gender,
      dealValue,
      email,
      phoneNumber: phoneNumber.toString(),
      isActive: true,
      entity: {
        __type: 'Pointer',
        className: 'Entity',
        objectId: this.authService.getUser().entityId.objectId,
      },
    };

    if (assignTo) {
      contactParams.agent = {
        __type: 'Pointer',
        className: '_User',
        objectId: assignTo,
      };
      contactParams.assignTo = {
        __type: 'Pointer',
        className: '_User',
        objectId: assignTo,
      };
    }
    const contactConditionParams = {
      where: {
        $or: [{ phoneNumber: phoneNumber.toString(), entity: {
          __type: 'Pointer',
          className: 'Entity',
          objectId: this.authService.getUser().entityId.objectId,
        } }, { email }],
      },
    };
    let contactObjectId: string;
    if (contactId) {
      contactObjectId = contactId;
    } else {
      const res = await this.dataService.getFromServer(
        'classes/Contacts',
        contactConditionParams,
      );
      if (res && res.results.length > 0) {
        contactObjectId = res.results[0].objectId;
      } else {
        const user = await this.contactService.addContact(contactParams);
        contactObjectId = user.objectId;
      }
    }
    const leadParams: LeadParams = {
      dealValue,
      description,
      stage: {
        __type: 'Pointer',
        className: 'Stages',
        objectId: stageObjectId,
      },
      category,
      assignTo: {
        __type: 'Pointer',
        className: '_User',
        objectId: leadAssignTo ? leadAssignTo : this.currentUser!.objectId,
      },
      contact: {
        __type: 'Pointer',
        className: 'Contacts',
        objectId: contactObjectId,
      },
      activityNotes,
      nextActivity: {
        __type: 'Date',
        iso: new Date(nextActivity),
      },
      createdAt: {
        __type: 'Date',
        iso: new Date(createdAt),
      },
      creator: {
        __type: 'Pointer',
        className: '_User',
        objectId: this.currentUser!.objectId,
      },
      entity: {
        __type: 'Pointer',
        className: 'Entity',
        objectId: this.authService.getUser().entityId.objectId,
      },
      closingDate: {
        __type: 'Date',
        iso: new Date(closingDate.toDate()),
      },
      user_tags: tags.length > 0 ? tags : undefined
    };
    if(noOfSeats){
      leadParams.noOfSeats = noOfSeats
    }
    if(estimatedContractLength){
      leadParams.estimatedContractLength = estimatedContractLength
    }

    if (assignTo) {
      leadParams.agent = {
        __type: 'Pointer',
        className: '_User',
        objectId: assignTo,
      };
    }
    if (this.selectedSpace) {
      leadParams.space = {
        __type: 'Pointer',
        className: 'Spaces',
        objectId: this.selectedSpace.objectId,
      } ;
    }
    await this.leadsService.addLead(leadParams);
    track("NEW_LEAD");
    this.router.navigate(['/leads/'+this.selectedSpace.objectId]);
    this.loadLeadRequests('').then();
    // this.notifier.notify('success','Nice!! We added some default tasks for you at this Stage.');
      await this.dataService.updateToServer('classes/Contacts/' + contactObjectId, {
      'leadsCount':{'__op':'Increment','amount':1},
      lastActivity: {
        __type: 'Date',
        iso: new Date(),
      },
    });
    // this.ngxLoader.stop();
  }

  isRecurringPrice(): boolean {
    const productIdInNewLeadForm = this.newLeadForm.get('product')!.value;
    const productIdInEditLeadForm = this.editLeadForm.get('product')!.value;
    if((productIdInNewLeadForm && this.productList) || (productIdInEditLeadForm && this.productList)){
      const product = this.productList.find(product => (product.objectId === productIdInNewLeadForm) || (product.objectId === productIdInEditLeadForm));
      return product && product.priceType === 'recurring' ? true : false;
    }
    else{
      return false;
    }
  }

  public async createStage() {
    // this.ngxLoader.start();
    const stageList: any = this.stagesListData || [];
    if (this.newStageForm.get('name')!.value && this.newStageForm.get('name')!.value.match(/^\s*$/) === null){
      const stageParams: any = {
        name: this.newStageForm.get('name')!.value,
        entity: {
          __type: 'Pointer',
          className: 'Entity',
          objectId: this.currentUser!.entityId!.objectId,
        },
        order: stageList.length>0 ? (stageList[stageList.length-1].order+1) : 1,
      };

      if (this.selectedSpace) {
        stageParams.space = {
          __type: 'Pointer',
          className: 'Spaces',
          objectId: this.selectedSpace.objectId,
        };
      }

      try {
        await this.dataService.postToServer('classes/Stages', stageParams);
        if (this.selectedSpace) {
          this.getStagesList(this.selectedSpace.objectId).then();
        }else{
          this.getStagesList().then();
        }


        this.loadLeadRequests('').then();
        this.newStageModal!.hide();
        // this.ngxLoader.stop();
        this.notifier.notify('success', 'New Stage Created');
      } catch (e) {
        this.notifier.notify('error', e.error.error);
      }
    }
    else{
      this.notifier.notify('error', 'Please Enter a Valid Stage Name');
    }
  }

  public async onTaskComplete(item: Tasks) {
    const taskParams = {
      isComplete: true,
    };
    await this.dataService.updateToServer(
      'classes/Tasks/' + item.objectId,
      taskParams,
    );
  }

  public async onTaskEdit(item: Tasks , name: string) {
    const taskParams = {
      stageOption: name,
    };
    await this.dataService.updateToServer(
      'classes/Tasks/' + item.objectId,
      taskParams,
    );
  }

  public async onTaskChange(checked: boolean, item: Tasks) {
    this.isPageLoading = true;
    if (checked) {
      const taskParams = {
        isComplete: checked,
      };
      await this.dataService.updateToServer(
        'classes/Tasks/' + item.objectId,
        taskParams,
      );
      this.notifier.notify('success', 'Task Closed Successfully!!');
      const leadObjectId = this.currentLead;
      setTimeout(async () => {
        // await this.editLead(leadObjectId);
        await this.editTasks(leadObjectId);
      }, 100);
    }
    this.isPageLoading = false;
  }

  public onExportClicked() {
    this.datatableElement.dtInstance.then((dtInstance: any) => {
      dtInstance.button(0).trigger();
    });
  }

  public async setKanbanData() {
    this.kanbanList = [];
    this.stagesListData.forEach((stage) => {
      const obj = {
        id: stage.objectId,
        name: stage.name,
        order: stage.order,
        probabilityValue:stage.probabilityValue ? stage.probabilityValue : 100,
        spaceId : this.spaceId,
        data: this.leadPageData.filter((x)=> x.stage && x.stage!.objectId === stage.objectId),
      }
      this.kanbanList.push(obj);

    });
    setTimeout(() => {
      this.isEmpty = true;
  }, 10);

    // Filter kanbanList based on search query
    if (this.kanbanSearchQuery.trim() !== '') {
      this.searchLeads();
    }

    // sort kanbanList
    this.sortData();

    this.kanbanList.sort((a: any, b: any)=>a.order - b.order);
  //   this.kanbanList.forEach(async (stage:any) => {

  //     stage.data.forEach(async (lead:any,index:number) => {
  //       const taskParams = {
  //         where: {
  //           stage: {
  //             __type: 'Pointer',
  //             className: 'Stages',
  //             objectId: stage!.id,
  //           },
  //           lead: {
  //             __type: 'Pointer',
  //             className: 'Leads',
  //             objectId: lead!.objectId,
  //           },
  //           isArchive : { $ne :true},
  //           isComplete : { $ne :true}
  //         },
  //         limit: 1,
  //         order: 'createdAt',
  //       };
  //       let tasks = await this.dataService.getFromServer(
  //         'classes/Tasks',
  //         taskParams,
  //       );
  //       tasks = tasks && tasks.results[0];
  //       stage.data[index].nextTask = tasks
  //     })


  // })

  }

  public viewAction(data: any) {
    if(data.type !== 'deleteStage'){
      this.currentNewTaskLead = data.lead.objectId;
      this.currentLeadStage = data.lead.stage.objectId;
    }
    if (data.type === 'edit') {
      // fa-pencil
      this.editLead(data.lead);
    } else if (data.type === 'leadStatus') {
      // fa-clock-o
      this.currentLeadStage = data.lead.stage.objectId;
      this.currentLead = data.lead;
      this.statusType = 'tasks';
      this.editTasks(data.lead);
      // this.openUpdateDailyStatus(data.lead);
      if (data.popoverTemplateRef) {
        data.popoverTemplateRef.show();
      }
    }
    else if (data.type === 'editTask') {
      // fa-clock-o
      this.currentLead = data.lead;
      this.statusType = 'tasks';
      this.editTasks(data.lead);
      // this.openUpdateDailyStatus(data.lead);
      if (data.popoverTemplateRef) {
        data.popoverTemplateRef.show();
      }
    }
    else if (data.type === 'status') {
      // fa-check
      this.statusUpdateModal = data.lead;
      if (data.popoverTemplateRef) {
        this.statusPopOverTemplateRef = data.popoverTemplateRef;
        data.popoverTemplateRef.show();
      }
    }else if (data.type === 'lostStatus') {
      // fa-check
      this.statusUpdateModal = data.lead;
      this.statusType = 'lostStatus';
      if (data.popoverTemplateRef) {
        this.statusPopOverTemplateRef = data.popoverTemplateRef;
        data.popoverTemplateRef.show();
      }
    } else if (data.type === 'unArchive') {
      // fa-check
      this.statusUpdateModal = data.lead;
      this.statusType = 'unArchive';
      if (data.popoverTemplateRef) {
        this.statusPopOverTemplateRef = data.popoverTemplateRef;
        data.popoverTemplateRef.show();
      }
    } else if (data.type === 'stage') {
      const index = this.stagesListData.findIndex((x: any)=>x.order === data.index);
      // fa-check
      this.statusUpdate(
        data.lead,
        data.type,
        this.stagesListData[index].objectId || '',
      );
    } else if (data.type === 'deleteStage') {
      this.getStagesList().then();
      this.loadLeadRequests('').then();
    } else if (data.type === 'deleteLead') {
      this.statusType = 'archive';
      this.statusUpdateModal = data.lead;
      if (data.popoverTemplateRef) {
        this.statusPopOverTemplateRef = data.popoverTemplateRef;
        data.popoverTemplateRef.show();
      }
    }
  }

  viewActionTaskDone(){
    this.setKanbanData()
  }

  public filterKanban() {
    this.setKanbanData();
  }

  sortBySelectedValue() {
    this.sortData();
  }

  toggleSortingOrder() {
    this.ascendingOrder = !this.ascendingOrder;
    this.sortData();
  }

  sortData() {
    const sortBy = this.selectedSortOption || "createdAt";
    this.kanbanList.forEach((stage: any) => {
        stage.data.sort((a: any, b: any) => {
            let propertyA, propertyB;

            switch (sortBy) {
                case 'dealValue':
                    propertyA = a.dealValue || 0;
                    propertyB = b.dealValue || 0;
                    break;
                case 'closingDate':
                    propertyA = a.closingDate ? new Date(a.closingDate.iso) : new Date(0);
                    propertyB = b.closingDate ? new Date(b.closingDate.iso) : new Date(0);
                    break;
                case 'updatedAt':
                    propertyA = a.updatedAt ? new Date(a.updatedAt.iso) : new Date(0);
                    propertyB = b.updatedAt ? new Date(b.updatedAt.iso) : new Date(0);
                    break;
                case 'nextActivity':
                    propertyA = a.nextActivity ? new Date(a.nextActivity.iso) : new Date(0);
                    propertyB = b.nextActivity ? new Date(b.nextActivity.iso) : new Date(0);
                    break;
                default:
                    // propertyA = a.name || '';
                    // propertyB = b.name || '';
                    propertyA = a.createdAt ? new Date(a.createdAt) : new Date(0);
                    propertyB = b.createdAt ? new Date(b.createdAt) : new Date(0);
                    break;
            }
            // sorting direction based on the boolean variable
            const sortOrderMultiplier = this.ascendingOrder ? 1 : -1;

            // Sort based on the property and sorting direction
            if (propertyA > propertyB) return -1 * sortOrderMultiplier;
            if (propertyA < propertyB) return 1 * sortOrderMultiplier;
            return 0;
        });
    });
  }

  public changeSpace(space: any, type: string) {
    this.selectedSpace = space;
    if(type === 'space' || this.opt.every((x)=>!!x.isComplete)){
      this.getStagesList().then();
      this.loadLeadRequests('').then();
    }
  }

  public openPopUp(){

  }

  ngOnDestroy() {
    if (this.leadsObservableSubscription) this.leadsObservableSubscription.unsubscribe();
  }

  // public async showOrhideTxt() {
  //   this.isShowTxtField = (this.editLeadForm.get('policyType')!.value === this.policyTypeOther) || (this.newLeadForm.get('policyType')!.value === this.policyTypeOther);
  // }

  public async markasLost() {
    const leadObj: any = {
      status: {
        __type: 'Pointer',
        className: 'Status',
        objectId: this.lostReasonLostObjectId,
      },
      lostReason : this.lostReasonList,
    };
    if (
      this.lostReasonLeadData.stage &&
      this.lostReasonLeadData.stage.objectId !== this.lastStage.objectId
    ) {
      // leadObj.stage = {
      //   __type: 'Pointer',
      //   className: 'Stages',
      //   objectId: this.lostReasonClosingObjectId,
      // };
    }
    this.kanbanComponent.archiveLead(this.lostReasonLeadData)
    this.lostReasonModal!.hide();

    try {
      await this.dataService.updateToServer(
        'classes/Leads/' + this.lostReasonLeadData.objectId,
        leadObj,
      );
      // this.notifier.notify('success', 'Lead Updated');
      this.statusType = '';
      this.loadLeadRequests('').then();
    } catch (e) {
      this.notifier.notify('error', e.error.error);
    }

    this.lostReasonForm.reset();
  }

  public isFieldValid(field: string, currentForm: FormGroup) {
    if (currentForm.get(field) && !this.disableFields) {
      return !currentForm.get(field)!.valid && currentForm.get(field)!.touched;
    }
    return;
  }

  public displayFieldCss(field: string, currentForm: FormGroup) {
    return {
      'has-error': this.isFieldValid(field, currentForm),
      'has-feedback': this.isFieldValid(field, currentForm),
    };
  }

  public filterBySelectedValue() {
    if(this.selectedRangeFilter !== 'custom') {
      if(this.selectedRangeFilter !== 'all') {
        this.shwAllLeads = false;
        this.selectedDates = getDates(this.selectedRangeFilter || 'lastThirtyDays');
      } else {
        this.shwAllLeads = true
      }
      this.loadLeadRequests('').then();
    }else {
      this.dateForm.reset();
      this.customDate!.show();
    }
  }

  public dateRandeSubmit() {
    let startDate = this.dateForm.get('startDate')!.value;
    let endDate = this.dateForm.get('endDate')!.value;
    if(startDate && endDate) {
      startDate = moment(startDate).startOf('day').toString();
      endDate = moment(endDate).endOf('day').toString();
      if (moment(endDate).isAfter(startDate)) {
        this.shwAllLeads = false;
        this.selectedDates = {startDate, endDate};
        this.loadLeadRequests('').then();
        this.dateForm.reset();
        this.customDate!.hide();
      } else {
        this.notifier.notify('error', 'Please select valid dates!!!');
      }
    }else {
      this.notifier.notify('error', 'Please select valid dates!!!');
    }
  }

  public editStagesRefresh(){
    if(this.spaceId){
      this.getStagesList(this.spaceId);
    }else{
      this.getStagesList();
    }
    this.loadLeadRequests('');
  }
}
