import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TaskService } from '../../services/task.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../services/auth';
import { DataService } from '../../services/data.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Tasks } from '../../types/tasks';
import {IcalService} from '../../services/ical.service';
// import { NgxUiLoaderService } from 'ngx-ui-loader';
import { track } from '@inleads/event-logger';
import {getTasksData} from "../../helpers/tasks.helper.data";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ListParams } from "src/app/types/kanban";
import { Stages } from "src/app/types/stages";

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit {
  @ViewChild('newTask') public newTaskModal?: ModalDirective;
  @ViewChild(DataTableDirective) public datatableElement: DataTableDirective;
  @ViewChild('tableWrapper') public tableWrapperElement: ElementRef;
  @ViewChild('calendar') calendar: ElementRef;
  public dtElement: DataTableDirective;
  public leadsList: any[] = [];
  public dtOptions: DataTables.Settings = {};
  // public dtOptions = {};
  public dtTrigger: Subject<any> = new Subject();
  public dtRendered = false;
  public dtInstance: DataTables.Api;
  public newTaskForm: FormGroup;
  public isLoading: boolean;
  public leadId: any;
  public stageId: any;
  public tasksList: any = [];
  public taskId: any;
  public editTaskForm: FormGroup;
  @ViewChild('editTask') public editTaskModal?: ModalDirective;
  public archiveModal = false;
  public unArchiveModal = false;
  public completedTaskModal= false;
  public openTaskModal = false;
  public showActiveTasks: boolean = true;
  public currentToggleUsers: boolean;
  public isButtonDisabled: boolean;
  public showCompletedTasks = false;
  public goToPage: number = 1;
  public minDate: Date;
  public openTasksFilter : any = {
    isArchive : { $ne :true},
    isComplete : { $ne :true}
  }
  public totalTasksCount :number
  public itemsPerPage: number = 10;
  public totalPages: number = 0;
  public currentPage: number = 1;
  public taskPagenationArray:number[];
  public taskPagenationArrayDynamic:any[];
  public currentFilter : any;
  public isPrivateUser: boolean;
  public calendarPlugins = [dayGridPlugin, interactionPlugin];
  public calendarTasksList: any = [];
  public tasksModal: { visible: boolean, data?: any } = { visible: false };
  public selectedDate:  Date;
  public displayKanbanContent: boolean = false;
  public isVisible: boolean = false;
  public kanbanList : ListParams[];
  public taskTemplateId: any;
  public taskStagesList: Stages[];
  public taskTemplatesList: any;
  public leadsStagesList: any;
  public leadsTasksList: any;

  constructor(
    private taskService: TaskService,
    private notifier: NotifierService,
    private fb: FormBuilder,
    public authService: AuthService,
    private dataService: DataService,
    public cdr: ChangeDetectorRef,
    // private ngxService: NgxUiLoaderService,
    private icalService: IcalService,
    private router: Router,
  ) { }

  async ngOnInit() {
	this.isPrivateUser = this.authService.isPrivateUser();
    this.intializeTask();
    this.getLeadsData();
    await this.fetchtotalTasksCount();
    await this.fetchTasksData();
    await this.getLeadsStagesList();
    
    this.isButtonDisabled = false;
    track("TASKS");
    
  }

  public async intializeTask() {
    this.newTaskForm = this.fb.group({
      taskType: ['', Validators.required],
      newTaskName: ['', Validators.required],
      task_nextActivity: [new Date(), Validators.required],
      task_activityNotes: [''],
      task_lead: ['', Validators.required],
    });

    this.editTaskForm = this.fb.group({
      editTaskName: ['', Validators.required],
      editTaskType: ['', Validators.required],
      edit_task_nextActivity: [new Date(), Validators.required],
      edit_task_activityNotes: [''],
    });
  }

  public async fetchtotalTasksCount(filter?: any) {
    const filterParamsObj :any  = filter ? filter : this.openTasksFilter;
      let taskParams : any = {
        where: {
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
          ...filterParamsObj
        },
        
          count: 1,
          limit: 0,
      };
      let response = await this.dataService.getFromServer(
        'classes/Tasks',
        taskParams,
      );
      const totalCount = response.count || 0; // Extract the count from the response
      this.totalPages = Math.ceil(totalCount / this.itemsPerPage);
      this.totalTasksCount =  response.count; 
      this.taskPagenationArray = Array.from({ length: this.totalPages }, (_, i) => i + 1);
      // const startPage = taskPagenationArray[]
      this.taskPagenationArrayDynamic = this.pagination(1,this.taskPagenationArray.length)
  }

  public goToTablePage(pageNumber: number) {
          if (pageNumber >= 1 && pageNumber <= this.totalPages) {
            this.currentPage = pageNumber;
            this.taskPagenationArrayDynamic = this.pagination(pageNumber,this.taskPagenationArray.length)
      
      if(this.currentFilter){
        this.fetchTasksData(this.currentFilter,this.currentPage);

      }else{
        this.fetchTasksData(null,this.currentPage);

      }
          }
        }

  public async openNewTaskPopUp() {
    document.body.click();
    if (this.selectedDate) {
      const selectedDateTime = moment(this.selectedDate).set({
        hour: moment().hour(),
        minute: moment().minute(),
      });
      this.newTaskForm.get('task_nextActivity')!.setValue(selectedDateTime.toDate());
    } else {
      this.minDate = new Date();
    }
    console.log("selected date:",this.selectedDate)
    this.newTaskModal!.show();
  }

  public async getLeadsData() {
    const leadsQuery = {
      where: {
        entity: {
          __type: 'Pointer',
          className: 'Entity',
          objectId: this.authService.getUser().entityId.objectId,
        },
        isArchived: { $ne: true },
      },
    };
    const resp = await this.dataService.getFromServer(
      'classes/Leads',
      leadsQuery,
    );
    this.leadsList = resp.results;
  }

  public async createTask(isAddToCalendar?: boolean) {
    try {
      // const name = this.newTaskForm.get('newTaskName')!.value;
      const name = this.newTaskForm.get('newTaskName')!.value.charAt(0).toUpperCase() + this.newTaskForm.get('newTaskName')!.value.slice(1);
      const taskType = this.newTaskForm.get('taskType')!.value;
      const taskNextActivity = this.newTaskForm.get('task_nextActivity')!.value;
      let leadData = this.newTaskForm.get('task_lead')!.value;
      leadData = leadData.split(':');
      this.leadId = leadData[0];
      this.stageId = leadData[1];
      const taskActivityNotes = this.newTaskForm.get('task_activityNotes')!.value;
      if (!name) {
        return this.notifier.notify('error', 'Please Enter Name');
      }
      if (!taskType) {
        return this.notifier.notify('error', 'Please Select Task Type');
      }
      if (!taskNextActivity) {
        return this.notifier.notify('error', 'Please Enter Next Activity');
      }
      if (!this.leadId) {
        return this.notifier.notify('error', 'Please Select Lead');
      }

      const taskParams: any = {
        objectId: '',
        stageOption: name,
        taskType,
        nextActivity: {
          __type: 'Date',
          iso: new Date(taskNextActivity),
        },
        activityNotes: taskActivityNotes,
        lead: {
          __type: 'Pointer',
          className: 'Leads',
          objectId: this.leadId,
        },
        stage: {
          __type: 'Pointer',
          className: 'Stages',
          objectId: this.stageId,
        },
        entity: {
          __type: 'Pointer',
          className: 'Entity',
          objectId: this.authService.getUser().entityId.objectId,
        },
        isComplete:false,
      };
      this.isButtonDisabled = true;
      this.newTaskModal!.hide();
      await this.taskService.addTask(taskParams);
      this.notifier.notify('success', 'Task created Successfully!!');
      track("NEW_TASK");
      if(taskNextActivity && !!isAddToCalendar) {
        const data = await this.icalService.parseIcal(taskNextActivity, name, taskActivityNotes);
        const blob = new Blob([data], {type: 'text/calendar'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'calendar.ics';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url)
      }
      this.showActiveTasks = true;
      this.newTaskModal!.hide();
      this.isButtonDisabled = false;
      this.newTaskForm.reset();
      await this.fetchtotalTasksCount();
      await this.fetchTasksData();
      await this.getLeadsStagesList();
    } catch (e) {
      this.newTaskModal!.hide();
      this.isButtonDisabled = false;
      this.notifier.notify('error', e.error.error);
    }
  }

  handleEventClick(info: any) {
    this.tasksModal = { visible: true, data: info.event.extendedProps };
  }

  handleDateClick(info: any) {
    const date = info.dateStr;
    if(date) {
      this.selectedDate = date
      this.openNewTaskPopUp()
    }
  }

  calendarHeight() {
    return window.innerHeight;
  }

  onWindowResize() {
    this.calendarHeight()
  }

  public tasksModalClose() {
    this.tasksModal.visible = false;
  }

  public navigateToLeadDetails(leadId: string) {
    this.router.navigate(['/lead-details', { leadId }]);
  }

  public async fetchTasksData(filter?: any,currentPageValue?: number){
    try {
      // this.isLoading = true;
      const currentPageNum =  currentPageValue ? currentPageValue : 1
      const skipValue = Math.abs((currentPageNum -1) * 10)
      // const filterParamsObj :any  = filter ? filter : this.openTasksFilter;
      console.log('filter',filter);
      let taskParams : any = {
        where: {
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
        },
        include: 'stage,lead,lead.assignTo,lead.contact',
        order: '-createdAt',
        limit:10000,
        skip:skipValue,
      };
      let resp = await this.dataService.getFromServer(
        'classes/Tasks',
        taskParams,
      );
      this.tasksList = resp.results || [];
      console.log('tasksList ----*********');

      console.log(this.tasksList);

      this.calendarTasksList = await getTasksData(resp.results)

      // const dtOptions = {
      //   dom: '<"appRequestsFilter"lf>tipr',
      //   columnDefs: [
      //     {
      //       orderable: false,
      //       targets: 0,
      //       responsivePriority: 1,
      //       width: '25px',
      //     },
      //     {
      //       responsivePriority: 1,
      //       targets: 1,
      //     },
      //     {
      //       responsivePriority: 1,
      //       targets: 5,
      //     },
      //     {
      //       responsivePriority: 2,
      //       targets: 2,
      //     },
      //     {
      //       responsivePriority: 2,
      //       targets: 3,
      //     },
      //     {
      //       defaultContent: 'NA',
      //       targets: '_all',
      //     },
      //   ],
      //   order: [
      //     // [0, 'desc'],
      //   ],
      //   // displayStart:5,
      //   language: {
      //     info:' Showing '+  (this.currentPage - 1) * this.itemsPerPage  +' to '  + Math.min(this.currentPage * this.itemsPerPage, this.totalTasksCount)+ ' of ' + this.totalTasksCount  + ' entries' ,
      //     search: '_INPUT_',
      //     searchPlaceholder: 'Search...',
      //     lengthMenu: '_MENU_',
      //   },
      //   responsive: true,
      //   data: this.tasksList,
      //   autoWidth: false,
      //   paging: false,
      //   columns: [
      //     { data: ''},
      //     {
      //       title: '',
      //       data: (row: Tasks) => {
      //         const completeTask = row.isComplete ? 'TaskOpen' : 'TaskComplete';
      //         return `
      //         <div style="display:flex;">
      //         <input type="select-checkbox" style="width: 22px;height: 22px;border-radius: 4px;border: 1px solid #a4a9b7;cursor: pointer;" data-toggle="tooltip"
      //         class="${completeTask}" data-placement="top" title="${completeTask}" />
      //         </div>`;
      //       },
      //     },
      //     {
      //       title: 'Task',
      //       className: "task_desc",
      //       width : '30%',
      //       data: (row: Tasks) => {
      //         // let taskTypeIcon = `<img src="../../../assets/images/0_customer.svg" alt=""  style="width:15px;"/>`;
      //         // if(row.taskType && row.taskType === "Call"){
      //         //   taskTypeIcon = `<a href="tel:${ row.lead && row.lead.contact && row.lead.contact.phoneNumber }"><img src="../../../assets/images/0_call.svg" alt=""  style="width:15px;"/></a>`;
      //         // }
      //         // if(row.taskType && row.taskType === "Email"){
      //         //   taskTypeIcon = `<a href="mailto::${ row.lead && row.lead.contact && row.lead.contact.email }"><img src="../../../assets/images/0_email.svg" alt=""  style="width:15px;"/></a>`;
      //         // }
      //         // const taskDesc = row.stageOption ? `<div style="cursor:pointer;margin-left:5px" data-placement="top" title="${row.stageOption}">${row.stageOption.length > 20 ? row.stageOption.slice(0, 20) + '...' : row.stageOption}</div>` : 'NA';
      //         const taskDesc = row.stageOption ? `<div style="margin-left:5px" data-placement="top" title="${row.stageOption}">${row.stageOption}</div>` : 'NA';
      //         return `<div style="display:flex;align-items:center;text-indent:initial">${taskDesc}</div>` ;
      //       },
      //     },
      //     {
      //       title: 'Lead',
      //       data: (row: Tasks) => {
      //         // const leadName = row.lead && row.lead.description ? `<span style="cursor:pointer" data-placement="top" title="${row.lead.description}">${row.lead.description.length > 10 ? row.lead.description.slice(0, 10) + '...' : row.lead.description}</span>` : 'NA';
      //         const leadName = row.lead && row.lead.description ? `<span style="cursor:pointer" data-placement="top" title="${row.lead.description}">${row.lead.description}</span>` : 'NA';
      //         return leadName;
      //       },
      //     },
      //     {
      //       title: 'AssignedTo',
      //       data: (row: Tasks) => {
      //         const assignedTo = row.lead && row.lead.assignTo ? row.lead.assignTo.name : 'NA';
      //         return assignedTo;
      //       },
      //     },
      //     // {
      //     //   title: 'Contact',
      //     //   data: (row: Tasks) => {
      //     //     const contactName = row.lead && row.lead.contact ? row.lead.contact.name : 'NA';
      //     //     return contactName;
      //     //   },
      //     // },
      //     {
      //       title: 'Task Type',
      //       data: (row: Tasks) => {
      //         const taskType = row.taskType ? row.taskType : 'NA';
      //         return taskType;
      //       },
      //     },
      //     {
      //       title: 'Actions',
      //       data: (row: Tasks) => {
      //         const archiveClass = row.isArchive ? 'unarchive' : 'archive';
      //       //let taskTypeIcon = `<img src="../../../assets/images/0_customer.svg" alt=""  style="width:15px;"/>`;
      //        let taskTypeIcon = '';
      //       if(row.taskType && row.taskType === "Call"){
      //           taskTypeIcon = `<a href="tel:${ row.lead && row.lead.contact && row.lead.contact.phoneNumber }"><img src="../../../assets/images/0_call.svg" alt=""  style="width:15px;"/></a>`;
      //         }
      //         if(row.taskType && row.taskType === "Email"){
      //           taskTypeIcon = `<a href="mailto::${ row.lead && row.lead.contact && row.lead.contact.email }"><img src="../../../assets/images/0_email.svg" alt=""  style="width:15px;"/></a>`;
      //         }
      //         if(!this.isPrivateUser) {
      //           return `
      //         <div style="display:flex;align-items:center;cursor:pointer">
      //           <span data-toggle="tooltip" style="margin-right:10px" data-placement="top">${taskTypeIcon}</span>
      //           <span data-toggle="tooltip" style="margin-right:10px" class="fa fa-pencil" data-placement="top" title="Edit"></span>
      //           <span *ngIf="${!this.isPrivateUser}" data-toggle="tooltip" style="margin-right:10px" data-placement="top" title="${archiveClass}">
      //               <img class="${archiveClass}" style="width:15px; cursor: pointer;" src="../../../../assets/images/New/${archiveClass}.svg" />
      //           </span>
      //         </div>`;
      //         } else {
      //           return `
      //         <div style="display:flex;align-items:center;cursor:pointer">
      //           <span data-toggle="tooltip" style="margin-right:10px" data-placement="top">${taskTypeIcon}</span>
      //           <span data-toggle="tooltip" style="margin-right:10px" class="fa fa-pencil" data-placement="top" title="Edit"></span>
      //         </div>`;
      //         }
      //       },
      //     },
      //   ],
      //   rowCallback: (row: Node, data: object) => {
      //     row.removeEventListener('click', () => {
      //     });
      //     row.addEventListener('click', e => {
      //       if ((e.target as HTMLElement).classList.contains('archive')) {
      //         this.archiveTask((data as Tasks).objectId);
      //       }
      //       if ((e.target as HTMLElement).classList.contains('unarchive')) {
      //         this.unArchiveTask((data as Tasks).objectId);
      //       }
      //       if ((e.target as HTMLElement).classList.contains('fa-pencil')) {
      //         this.editTask((data as Tasks));
      //       }
      //       if ((e.target as HTMLElement).classList.contains('TaskComplete')) {
      //         this.taskCompleted((data as Tasks).objectId);
      //       }
      //       if ((e.target as HTMLElement).classList.contains('TaskOpen')) {
      //         this.taskOpen((data as Tasks).objectId);
      //       }
      //     });
      //     // @ts-ignore
      //     row.setAttribute('id', `id-${(data as Tasks).objectId}`);
      //     return row;
      //   },
      //   initComplete: () => {
      //     const dataTable = $('#tasks-table').DataTable();
      //     dataTable.on('page.dt', () => {
      //       const currentPage = dataTable.page.info().page + 1;
      //       this.goToPage = currentPage;
      //     });
      //     setTimeout(() => {
      //       dataTable.page(this.goToPage - 1).draw(false);
      //     },100)
      //
      // },
      // };
      if(this.dtElement && this.dtElement.dtInstance){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          setTimeout(() => {
            this.dtTrigger.next();
          }, 10);
        });
      }
      else{
        // this.renderTable(dtOptions);
      }
    
      // this.isLoading = false;
    } catch (e) {
      // this.isLoading = false;
      this.notifier.notify('error', e.error);
    }
  }

  public async showEditTask(task: Tasks) {
    this.tasksModal.visible = false;
    this.editTask(task);
  }

  public async archiveTask(taskId:any) {
    console.log(taskId);
    this.tasksModal.visible = false;
    this.archiveModal = true;
    this.taskId = taskId;
  }

  public async unArchiveTask(taskId:any) {
    this.unArchiveModal = true;
    this.taskId = taskId;
  }

  public async taskCompleted(taskId:any) {
    this.tasksModal.visible = false;
    this.completedTaskModal = true;
    this.taskId = taskId;
    console.log(this.taskId);
  }

  public async taskOpen(taskId:any) {
    this.openTaskModal = true;
    this.taskId = taskId;
  }

  public async clickYes() {
    try {
      this.isButtonDisabled = true;
      await this.dataService.updateToServer('classes/Tasks/' + this.taskId, {
        isArchive: true,
        isComplete: true
      });
      this.isButtonDisabled = false;
      this.fetchTasksData();
      this.archiveModal = false;
      this.notifier.notify('success', 'Task Archived');
    } catch (e) {
      this.isButtonDisabled = false;
      this.notifier.notify('error', e.error.error);
    }
  }

  public async clickUnArchive() {
    try {
      this.isButtonDisabled = true;
      await this.dataService.updateToServer('classes/Tasks/' + this.taskId, {
        isArchive: false,
        isComplete: false
      });
      this.isButtonDisabled = false;
      this.toggleUsers(true);
      this.unArchiveModal = false;
      this.notifier.notify('success', 'Task Unarchived');
    } catch (e) {
      this.isButtonDisabled = false;
      this.notifier.notify('error', e.error.error);
    }
  }

  public async clickTaskClose() {
    try {
      this.isButtonDisabled = true;
      await this.dataService.updateToServer('classes/Tasks/' + this.taskId, {
        isComplete: true,
      });
      this.isButtonDisabled = false;
      if(this.currentToggleUsers && !this.showCompletedTasks){
        await this.fetchTasksData();
      }else{
        await this.fetchTasksData();
      }
      this.completedTaskModal = false;
      this.notifier.notify('success', 'Task Completed');
    } catch (e) {
      this.isButtonDisabled = false;
      this.notifier.notify('error', e.error.error);
    }
  }

  public async clickTaskOpen() {
    try {
      this.isButtonDisabled = true;

    await this.dataService.updateToServer('classes/Tasks/' + this.taskId, {
        isComplete: false,
      });
      this.isButtonDisabled = false;
      if(this.currentToggleUsers && !this.showCompletedTasks){
        await this.fetchTasksData();
      }else{
        this.toggleTasks();

      }
      this.openTaskModal = false;
      this.notifier.notify('success', 'Task Opened');
    } catch (e) {

      this.isButtonDisabled = false;
      this.notifier.notify('error', e.error.error);
    }
  }

  public declineTask() {
    this.completedTaskModal = false;
  }

  public declineTaskOpen() {
    this.openTaskModal = false;
  }

  public decline() {
    this.archiveModal = false;
  }

  public declineUnArchive() {
    this.unArchiveModal = false;
  }

  public renderTable = (dtOptions: any) => {
    // destroy you current configuration
    this.dtRendered = false;
    this.dtOptions = dtOptions;
    // make sure your template notices it
    this.cdr.detectChanges();
    // initialize them again
    this.dtRendered = true;
    this.dtTrigger = new Subject();
    this.cdr.detectChanges();
    this.dtTrigger.next();
  }


  // public async updateTask() {
    
  //   try {
  //     const name = this.editTaskForm.get('editTaskName')!.value;
  //     const taskType = this.editTaskForm.get('editTaskType')!.value;
  //     const editTaskTime = this.editTaskForm.get('editTaskTime')!.value;
  //     const taskNextActivity = this.editTaskForm.get('edit_task_nextActivity')!.value;
  //     const taskActivityNotes = this.editTaskForm.get('edit_task_activityNotes')!.value;
  //     if (!name) {
  //       return this.notifier.notify('error', 'Please Enter Name');
  //     }
  //     if (!taskType) {
  //       return this.notifier.notify('error', 'Please Select Task Type');
  //     }
  //     if (!taskNextActivity) {
  //       return this.notifier.notify('error', 'Please Enter Next Activity');
  //     }

  //     if (!editTaskTime) {
  //       return this.notifier.notify('error', 'Please Enter Task Time');
  //     }

  //     const year = taskNextActivity.getFullYear();
  //     const month = taskNextActivity.getMonth() + 1; // Jan is 0, dec is 11
  //     const day = taskNextActivity.getDate();
  //     const dateString = '' + year + '-' + month + '-' + day;
  //     const combinedTime = new Date(dateString + ' ' + editTaskTime);

  //     const taskParams: any = {
  //       stageOption: name,
  //       taskType,
  //       nextActivity: {
  //         __type: 'Date',
  //         iso: new Date(combinedTime),
  //       },
  //       activityNotes: taskActivityNotes,
  //     };
  //     const id = this.taskId;
  //     this.isButtonDisabled = true;
  //     this.editTaskModal!.hide();

  //     await this.taskService.updateTask(taskParams, id);
  //     this.notifier.notify('success', 'Task Updated Successfully!!');
  //     // let filter : any = this.currentToggleUsers ? { isArchive : this.currentToggleUsers } : {...this.openTasksFilter};
  //     // let filter : any = {
  //     //   isArchive : this.currentToggleUsers
  //     // }
  //     // if(!this.currentToggleUsers){
  //     //   filter = {...this.openTasksFilter}
  //     // }
  //     this.isButtonDisabled = false;
  //     this.fetchTasksData(this.currentFilter,this.currentPage);
  //   } catch (e) {
  //     this.editTaskModal!.hide();
  //     this.isButtonDisabled = false;
  //     this.notifier.notify('error', e);
  //   }
  // }

  async updateTask(addToCalendar?: boolean) {
    try {
      const name = this.editTaskForm.get('editTaskName')!.value;
      const taskType = this.editTaskForm.get('editTaskType')!.value;
      const taskNextActivity = this.editTaskForm.get('edit_task_nextActivity')!.value;
      const taskActivityNotes = this.editTaskForm.get('edit_task_activityNotes')!.value;

      if (!name) {
        return this.notifier.notify("error", "Please Enter Name");
      }
      if (!taskType) {
        return this.notifier.notify("error", "Please Select Task Type");
      }
      if (!taskNextActivity) {
        return this.notifier.notify("error", "Please Enter Next Activity");
      }
      const taskParams: any = {
        stageOption: name,
        taskType,
        nextActivity: {
          __type: 'Date',
          iso: new Date(taskNextActivity),
        },
        activityNotes: taskActivityNotes,
        isComplete: false,
      };
      
      this.isButtonDisabled = true;
      this.editTaskModal!.hide();
      
      // Update task
      await this.taskService.updateTask(taskParams, this.taskId);
      this.notifier.notify('success', 'Task Updated Successfully!!');

      // If addToCalendar is true, add the task to the calendar
      if (addToCalendar) {
        const data = await this.icalService.parseIcal(taskNextActivity, name, taskActivityNotes);
        const blob = new Blob([data], { type: 'text/calendar' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'calendar.ics';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }

      this.isButtonDisabled = false;
      this.fetchTasksData(this.currentFilter,this.currentPage);
    } catch (e) {
      this.editTaskModal!.hide();
      this.isButtonDisabled = false;
      this.notifier.notify('error', e);
    }
  }

  public async editTask(task: Tasks) {
    this.taskId = task.objectId;
    this.editTaskForm.patchValue({
      editTaskName: task.stageOption, edit_task_nextActivity: task.nextActivity ? new Date(task.nextActivity.iso) : new Date(),
      edit_task_activityNotes: task.activityNotes, editTaskType: task.taskType ? task.taskType : 'Call'
    });
    this.editTaskModal!.show();

  }


  pagination(c:number, m:number) {
    var current = c,
        last = m,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

    for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || i >= left && i < right) {
            range.push(i);
        }
    }

    for (let i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }
    return rangeWithDots;
}

  public async toggleUsers(isArchived:boolean) {
    this.currentToggleUsers = isArchived;
    this.showActiveTasks = !isArchived;
    this.showCompletedTasks = false;
    // this.ngxService.start();
    let filter : any = {
      isArchive : isArchived
    }
    if(!isArchived){
      filter = {...this.openTasksFilter}
    }
    this.currentPage = 1;
    this.currentFilter = filter;
    await this.fetchtotalTasksCount(filter);
    await this.fetchTasksData();
    // this.ngxService.stop();
  }

  public async toggleTasks() {
    this.currentToggleUsers = false;
    this.showCompletedTasks = true;
    // this.ngxService.start();
    const filter = {
      isComplete : {$ne:false},
      isArchive: {$ne:true},
    }
    this.currentPage = 1;
    this.currentFilter = filter;
    await this.fetchtotalTasksCount(filter);
    await this.fetchTasksData();
    // this.ngxService.stop();
  }

  public async calendarIcs(task: Tasks) {
    if (task && task.nextActivity && task.nextActivity!.iso) {
      const name = task.stageOption || '';
      const note = task.activityNotes || ''
      const data = await this.icalService.parseIcal(task.nextActivity!.iso, name, note);
      const blob = new Blob([data], {type: 'text/calendar'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'calendar.ics';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  }

  public loadView(view: string) {
    if (view === "kanban") {
      this.displayKanbanContent = true;
    } else {
      this.displayKanbanContent = false;
    }
  }

  public async getLeadsStagesList() {
    let params: any;
    if (this.authService.getUser().entityId && !this.authService.isSuperAdmin()) {
        params = {
          where: {
           
                entity: {
                  __type: 'Pointer',
                  className: 'Entity',
                  objectId: this.authService.getUser().entityId.objectId,
                },
                templateType: { $ne : 'network' },
                
              },
              order: "order"
      }
    
    const stagesList = await this.dataService.getFromServer(
      'classes/Stages',
      params,
    );
    this.leadsStagesList = stagesList ? stagesList.results : [];
    console.log('this.leadsStagesList');
    console.log(this.leadsStagesList);
    this.mapStagesToKanbanModel(this.leadsStagesList)
  }
}

  mapStagesToKanbanModel(data: any[]) {
    this.kanbanList = data.map((stage) => {
      const filteredTasksByStage = this.tasksList.filter((x: any) => x.stage && x.stage.objectId === stage.objectId).map((task : any) => {
        return { name: task.stageOption || task.objectId,
           objectId: task.objectId , 
           email: task.lead.assignTo.email,
           isComplete: task.isComplete,
           isArchived : task.isArchive,
           assignTo: task.lead.assignTo.name,
           nextActivity : task.nextActivity.iso,
           isTask: true
        }
      });
      console.log('filteredTasksByStage');
      console.log(filteredTasksByStage);
      const item: ListParams = {
        name: stage.name,
        id: stage.objectId,
        data: [...filteredTasksByStage]
      };
      return item;
    });
  }

  public onStageChange(stage: any){
    const stageId = stage.objectId;
    const taskId = stage.data.objectId;
    console.log(stageId, taskId);
     this.updateTaskStage(stageId, taskId)
  }

  public async updateTaskStage(stageId: string, taskId : string){
    const data = {
      stage: {
      __type: 'Pointer',
      className: 'Stages',
      objectId: stageId,
   },
    }
    try {
       this.dataService.updateToServer("classes/Tasks/" + taskId, data);
    } catch (e) {
      console.log(e.message);
    }
  }


}
