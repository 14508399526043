import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { NotifierService } from "angular-notifier";
import { UserService } from "../../services/user.service";
import { EventsService } from "../../services/events.service";
import { DataService } from "src/app/services/data.service";
import { AuthService } from "src/app/services/auth";
import { ModalDirective } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import * as moment from 'moment';
import {DataTableDirective} from 'angular-datatables';
import { Subject } from 'rxjs';


interface Tab {
  objectId: string;
  generateUniqueId?: string;
  templateId?: any;
  action: string;
  event: string;
  isActive: boolean;
  enableMapping: boolean;
  stage: any;
  order?: number;
  delay?: number;
}

@Component({
  selector: 'app-automationComponent',
  templateUrl: './automation.component.html',
  styleUrls: ['./automation.component.scss'],
})
export class AutomationComponent implements OnInit {
  public disabled = true;
  public selected: any;
  public showGuide: Boolean = false;
  public showWorkflow: Boolean = true;
  public isLoading = true;
  public stagesId: any;
  public templateId: any;
  public emailTemplatesList: any[] = [];
  public eventsList: any[] = [];
  public eventsMappingList: any[] = [];
  public checkedList: any = [];
  public tabs: { [key: string]: Tab[] } = {};
  public selectedEvent: string | null = null;
  public eventKeys:any[] = [];
  public uniqueEventsList: any[] = [];
  public tabEvent: string;
  public selectedSpace: any;
  public spacesList: any[] = [];
  public allEventsAppingData: any[] = [];
  @ViewChild("newlead") public newleadModal: ModalDirective;
  public automationForm: FormGroup;
  public minDate: string;
  public actionTypes = ['Send mail', 'Add to']; 
  public conditionTypes = [
    {key:'lastActivity', value: 'Last Activity'},
    {key:'country', value: 'Country'},
    {key:'city', value: 'City'},
  ];
  @ViewChild(DataTableDirective) public datatableElement: DataTableDirective;
  @ViewChild(DataTableDirective) public dtElement: DataTableDirective;

  @ViewChild('tableWrapper') public tableWrapperElement: ElementRef;  
  public productsPageData: any[] = [];
  public dtOptions: any;
  dtTrigger: Subject<any> = new Subject<any>();
  public products: any;
  public dtInstance: DataTables.Api;    
  public excludedEvents: string[] = ['INIT'];

  constructor(
    private notifier: NotifierService,
    public userService: UserService,
    public eventService: EventsService,
    private dataService: DataService,
    public authService: AuthService,
    private fb: FormBuilder,
  ) {
    this.automationForm = this.fb.group({
      mappingname: this.fb.group({  // Ensure mappingname is correctly set up
        name: ['', Validators.required]
      }),
      trigger: this.fb.group({
        type: ['event'],
        event: [''],
        frequency: [''],
        date: [''],
        time: ['']
      }),
      conditions: this.fb.array([]),
      actions: this.fb.array([])
    });
    
  }


  public goToPage: number = 1;


  public async ngOnInit() {
    this.eventsList = await this.eventService.getEventsList(true);
    this.getEventsMappingList();
    this.getStagesList();
    this.isLoading = false;
    this.addNewAction();
    this.minDate = moment().add(1, 'day').format('YYYY-MM-DD');

  }

  get triggerType() {
    // return this.automationForm.get('trigger.type').value || 'event';
    const control = this.automationForm.get('trigger.type');
    return control ? control.value : 'event';
  }

  get conditions() {
    return this.automationForm.get('conditions') as FormArray;
  }

  get actions() {
    return this.automationForm.get('actions') as FormArray;
  }

  public onTriggerTypeChange() {
    const triggerGroup = this.automationForm.get('trigger') as FormGroup;
    if (this.triggerType === 'event') {
      triggerGroup.patchValue({
        frequency: '',
        date: '',
        time: ''
      });
    } else {
      triggerGroup.patchValue({
        event: ''
      });
    }
  }

  public createNewCondition() {
    return this.fb.group({
      id: this.generateUniqueId(),  // Unique ID for the new tab
      type: [{ value: '', disabled: false }, Validators.required],
      operator: ['', Validators.required],
      value: ['', Validators.required]
    });
  }


  public isConditionTypeSelected(optionKey: string, index: number): boolean {
    //@ts-ignore
    return this.conditions.controls.some((ctrl, i) => i !== index && ctrl.get('type').value === optionKey);
  }

  public addNewCondition() {
    if(this.conditions && this.conditions.value && this.conditions.value.length > 2) {
      this.notifier.notify('error', 'You can not add more than three Criteria / Condetions.');
    }else {
    this.conditions.push(this.createNewCondition());
    }
  }

  public removeCondition(index: number, condtion: any) {
    try {
      this.isLoading = true;
      let eventl: any[] = [];
      //@ts-ignore
      if (this.selectedEvent && this.selectedEvent.events) {
        //@ts-ignore
      this.selectedEvent.events.forEach(async (event: any) => {
        eventl = event.criteria.filter((eventData: any) => eventData.id !== condtion.value.id);
        if(event.objectId){
          await this.dataService.updateToServer(
            "classes/EventMapping/" + event.objectId,
            {  criteria: eventl});
          }
      this.notifier.notify('success', 'Filter updated Successfully!!!');
      });        
      }
      this.conditions.removeAt(index);
      this.isLoading = false;
    } catch(_) {
      this.isLoading = false;
      this.notifier.notify('error', 'Something went wrong, please try again!!!');
    }
  }

  public createNewAction() {
    return this.fb.group({
      type: ['', Validators.required],
      delay: [0, [Validators.required, Validators.min(0)]],
      stage: '',
      template: ''
    });
  }

  public addNewAction() {
    if(this.actions && this.actions.value && this.actions.value.length > 1) {
      this.notifier.notify('error', 'You can not add more than two actions.');
    }else {
      this.actions.push(this.createNewAction());
    }
    
  }
  
  public isActionTypeSelected(option: string, index: number): boolean {
    //@ts-ignore
    return this.actions.controls.some((ctrl, i) => i !== index && ctrl && ctrl.get('type').value === option);
  }

  public async removeAction(index: number, action: any) {
    this.actions.removeAt(index);
    try {
      if(action && action.value && action.value.objectId) {
        await this.dataService.updateToServer(
          "classes/EventMapping/" + action.value.objectId,
          { isActive: false, enableMapping: false });
      }
    } catch(_) {
      this.notifier.notify('error', 'Something went wrong, please try again!!!');
    }
  }

  public saveWorkflow() {
    if (this.automationForm.valid) {
      console.log(this.automationForm.value);
    } else {
      console.log('Form is invalid');
    }
  }

  // Event handlers
  public selectEvent(eventKey: string) {
    this.selectedEvent = eventKey;
    this.tabEvent = eventKey
    if(!this.eventKeys.includes(eventKey) && !this.tabs[this.selectedEvent]) {
      this.addNewRow('NewMapping')
    }
  }

  public async getSpacesList(spaceId?: string ) {
    let spacesQuery: any;
    if(!this.authService.isSuperAdmin()){
      spacesQuery = {
        where: {
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
          isArchived: { $ne : true },
        },
      };
      if (spaceId) {
        spacesQuery.where['objectId'] = spaceId
      }
    }
    const resp = await this.dataService.getFromServer(
      'classes/Spaces',
      spacesQuery,
    );
    this.spacesList = resp.results;
    
     return resp.results.length > 0 ? this.spacesList[0] : null;
   
  }

  public async getStagesList() {
    const resp1 = await this.getSpacesList()
    let stageQuery = {
      where: {
        $or: [
          {
            entity: {
              __type: "Pointer",
              className: "Entity",
              objectId: this.authService.getUser().entityId.objectId,
            },
          },
          {
            entity: { $exists: false },
          },
        ],
      },
      order: "order",
    };
    
    if (resp1.objectId) {
      stageQuery.where = {
        ...stageQuery.where, 
        space: {
          __type: "Pointer",
          className: "Spaces",
          objectId: resp1.objectId,
        },
      } as any;
    }
  
    const resp = await this.dataService.getFromServer(
      "classes/Stages",
      stageQuery,
    );
    this.stagesId = resp.results; 
  }

  public async getEmailTemplatesList(){
    try {
    let emailTemplateQuery;
    if(!this.authService.isSuperAdmin()){
      emailTemplateQuery = {
        where: {
          fromUser: {
            __type: 'Pointer',
            className: '_User',
            objectId: this.authService.getUser().objectId
          },
        },
        include: 'toContact',
    };
    }
    const resp = await this.dataService.getFromServer(
      'classes/EmailTemplates', emailTemplateQuery,
    );
    this.emailTemplatesList = resp.results;
  }
  catch(error) {
    this.notifier.notify('error', 'Getting error while checking for Email Templates');
    return;
    }
  }
  
  public async getEventsMappingList() {
    this.getEmailTemplatesList();
    let eventsMappingQuery;
    if (!this.authService.isSuperAdmin()) {
      eventsMappingQuery = {
        include: 'templateId,stage',
        where: {
          entity: {
            __type: "Pointer",
            className: "Entity",
            objectId: this.authService.getUser().entityId.objectId
          },
          isActive: true,
        },
      };
    }
    const resp = await this.dataService.getFromServer(
      "classes/EventMapping",
      eventsMappingQuery
    );

    if(resp.results.length) {

      this.uniqueEventsList = resp.results;

      const grouped = new Map();

      resp.results.forEach((event: any) => {
        const journeyId = event.journey && event.journey.objectId;
        const createdDate = event.createdAt;
        if (!grouped.has(journeyId)) {
          grouped.set(journeyId, { journeyId, createdDate, events: [] });
        }
        grouped.get(journeyId).events.push(event);
      });
    
      this.allEventsAppingData = Array.from(grouped.values()).reverse();
    }else {

    this.allEventsAppingData = resp.results || []
    }

  }

  public isEventDisabled(eventName: string): boolean {
    return this.uniqueEventsList.some((e: any) => 
       e.event === eventName
    );
  }
  

  public handleAutomation(data: any) {
    try {
      if(data && data.journeyId) {
        this.updateAutomation(data);
      } else {
        this.saveAutomation(data);
      }
    } catch (_) {
      this.notifier.notify('error', 'Something went wrong, please try again');
    }
  }

  public async updateAutomation(rowData?: any) {
    if (this.automationForm) {
      try {
        this.isLoading = true;
        const formData = this.automationForm.value;


        if(!formData.mappingname.name) {
          this.notifier.notify('error', 'A name field cannot be left blank');
          this.isLoading = false;
          return false;
        }

        if(formData.trigger.type === 'event') {
          if(!formData.trigger.event) {
            this.notifier.notify('error', 'Please select event');
            this.isLoading = false;
            return false;
          }

          if(!formData.actions.length) {
            this.notifier.notify('error', 'Please select atleast one action.');
              this.isLoading = false;
              return false;
          }

          for (const action of formData.actions) {
            if(!action.type) {
              this.notifier.notify('error', 'Please select atleast one action.');
              this.isLoading = false;
              return false;
            }
            if (action.type === 'Send mail' && !action.template) {
              this.notifier.notify('error', 'Please select an email template for Send mail action.');
              this.isLoading = false;
              return false;
            }
            if (action.type === 'Add to' && !action.stage) {
              this.notifier.notify('error', 'Please select a stage for Add to action.');
              this.isLoading = false;
              return false;
            }
          }
        } else {
          if(!formData.trigger.frequency) {
            this.notifier.notify('error', 'Please select a valid frequency');
            this.isLoading = false;
            return false;
          }

          if(!formData.trigger.date || !moment(formData.trigger.date).isAfter(moment(), 'day')) {
            this.notifier.notify('error', 'Please select a valid date');
            this.isLoading = false;
            return false;
          }

          if(!formData.trigger.time) {
            this.notifier.notify('error', 'Please select a valid time');
            this.isLoading = false;
            return false;
          }     
          
          if(!formData.actions.length) {
            this.notifier.notify('error', 'Please select atleast one action.');
              this.isLoading = false;
              return false;
          }

          for (const action of formData.actions) {
            if(!action.type) {
              this.notifier.notify('error', 'Please select atleast one action.');
              this.isLoading = false;
              return false;
            }
            if (action.type === 'Send mail' && !action.template) {
              this.notifier.notify('error', 'Please select an email template for Send mail action.');
              this.isLoading = false;
              return false;
            }
            if (action.type === 'Add to' && !action.stage) {
              this.notifier.notify('error', 'Please select a stage for Add to action.');
              this.isLoading = false;
              return false;
            }
          }

        }

        for (const condition of formData.conditions) {
          if(!condition.type) {
            this.notifier.notify('error', 'Please select valid condetion type.');
            this.isLoading = false;
            return false;
          }
          
          if(!condition.operator) {
            this.notifier.notify('error', 'Please select a valid operator.');
            this.isLoading = false;
            return false;
          }

          if(condition.type === 'lastActivity') {
            this.notifier.notify('error', 'Please select valid date.');
            this.isLoading = false;
            return false; 
          }

          if(!condition.value) {
            this.notifier.notify('error', 'Please enter a valid value.');
            this.isLoading = false;
            return false;
          }

        }

        const promises = formData.actions.map(async (action:any) => {
          let obj:any = {}
          obj = {
              action: action.type,
              eventName: formData.trigger.event,
              delay: formData.trigger.frequency ? 0 : action.delay.toString(),
              criteria: formData.conditions,
              stageObjectId: action.stage ? action.stage : '',
              templateObjectId: action.template ? action.template : '',
              enableMapping: true,
              // journeyObjectId: '',
              frequency: formData.trigger.frequency || undefined,
              startDate: formData.trigger.frequency ? (formData.trigger.date ? {"__type": "Date", "iso": moment(`${formData.trigger.date} ${formData.trigger.time}`).toISOString()} : moment().toISOString()) : undefined,
              eventMappingObjectId: action && action.objectId,
              isActive: true,
              entityObjectId : this.authService.getUser().entityId.objectId,
              name: formData.mappingname.name,
            };

            if(!action.objectId && rowData.journeyId) {
              obj = { ...obj, journeyObjectId: rowData && rowData.journeyId }
              await this.dataService.postToServer('functions/saveEventMapping', obj);
            } else {
              await this.dataService.postToServer('functions/updateEventMapping', obj);
            }

         });
        await Promise.all(promises);
        this.getEventsMappingList();
        this.showMappingHome();
        this.newleadModal!.hide();
        this.isLoading = false;
        return true;
      } catch (_) {
        this.isLoading = false;
        this.notifier.notify('error', 'Error processing event mappings!');
        return false;
      }
    } else {
      this.isLoading = false;
      this.notifier.notify('error', 'Form is invalid');
      return false;
    } 
  };

  public async saveAutomation(rowData: any) {
    if (this.automationForm) {
      try {
        this.isLoading = true;
        const formData = this.automationForm.value;
        let journeyResp:any;

        if(!formData.mappingname.name) {
          this.notifier.notify('error', 'A name field cannot be left blank');
          this.isLoading = false;
          return false;
        }

        if(formData.trigger.type === 'event') {
          if(!formData.trigger.event) {
            this.notifier.notify('error', 'Please select event');
            this.isLoading = false;
            return false;
          }

          if(!formData.actions.length) {
            this.notifier.notify('error', 'Please select atleast one action.');
              this.isLoading = false;
              return false;
          }

          for (const action of formData.actions) {
            if(!action.type) {
              this.notifier.notify('error', 'Please select atleast one action.');
              this.isLoading = false;
              return false;
            }
            if (action.type === 'Send mail' && !action.template) {
              this.notifier.notify('error', 'Please select an email template for Send mail action.');
              this.isLoading = false;
              return false;
            }
            if (action.type === 'Add to' && !action.stage) {
              this.notifier.notify('error', 'Please select a stage for Add to action.');
              this.isLoading = false;
              return false;
            }
          }
        } else {
          if(!formData.trigger.frequency) {
            this.notifier.notify('error', 'Please select a valid frequency');
            this.isLoading = false;
            return false;
          }

          if(!formData.trigger.date || !moment(formData.trigger.date).isAfter(moment(), 'day')) {
            this.notifier.notify('error', 'Please select a valid date');
            this.isLoading = false;
            return false;
          }

          if(!formData.trigger.time) {
            this.notifier.notify('error', 'Please select a valid time');
            this.isLoading = false;
            return false;
          }     
          
          if(!formData.actions.length) {
            this.notifier.notify('error', 'Please select atleast one action.');
              this.isLoading = false;
              return false;
          }

          for (const action of formData.actions) {
            if(!action.type) {
              this.notifier.notify('error', 'Please select atleast one action.');
              this.isLoading = false;
              return false;
            }
            if (action.type === 'Send mail' && !action.template) {
              this.notifier.notify('error', 'Please select an email template for Send mail action.');
              this.isLoading = false;
              return false;
            }
            if (action.type === 'Add to' && !action.stage) {
              this.notifier.notify('error', 'Please select a stage for Add to action.');
              this.isLoading = false;
              return false;
            }
          }

        }

        for (const condition of formData.conditions) {
          if(!condition.type) {
            this.notifier.notify('error', 'Please select valid condetion type.');
            this.isLoading = false;
            return false;
          }
          
          if(!condition.operator) {
            this.notifier.notify('error', 'Please select a valid operator.');
            this.isLoading = false;
            return false;
          }

          if(condition.type === 'lastActivity') {
            this.notifier.notify('error', 'Please select valid date.');
            this.isLoading = false;
            return false; 
          }

          if(!condition.value) {
            this.notifier.notify('error', 'Please enter a valid value.');
            this.isLoading = false;
            return false;
          }

        }


        if(!rowData) {
           journeyResp = await this.dataService.postToServer(
            "classes/Journey/",
            {
              isEnabled: true,
              isActive: true,
              entity: { __type: 'Pointer', className: 'Entity', objectId: this.authService.getUser().entityId.objectId },
              name: formData.mappingname.name,
            }
          );
        }
       
        const promises = formData.actions.map(async (action:any) => {
          let obj = {}
          obj = {
              eventName: formData.trigger.event,
              action: action.type,
              delay: formData.trigger.frequency ? 0 : action.delay.toString(),
              criteria: formData.conditions,
              stageObjectId: action.stage ? action.stage : '',
              templateObjectId: action.template ? action.template : '',
              enableMapping: true,
              journeyObjectId: journeyResp ? journeyResp.objectId : '',
              frequency: formData.trigger.frequency || undefined,
              startDate: formData.trigger.frequency ? (formData.trigger.date ? {"__type": "Date", "iso": moment(`${formData.trigger.date} ${formData.trigger.time}`).toISOString()} : moment().toISOString()) : undefined,
              eventMappingObjectId: rowData && rowData.objectId,
              isActive: true,
              entityObjectId : this.authService.getUser().entityId.objectId,
              name: formData.mappingname.name,
            };            
            await this.dataService.postToServer('functions/saveEventMapping', obj);
          });
        await Promise.all(promises);
        this.getEventsMappingList();
        this.showMappingHome();
        this.newleadModal!.hide();
        this.isLoading = false;
        return true;
      } catch (_) {
        this.isLoading = false;
        this.notifier.notify('error', 'Error processing event mappings!');
        return false;
      }    
    } else {
      this.isLoading = false;
      this.notifier.notify('error', 'Form is invalid');
      return false;
    }
  }
  
  public async openModalWithData(data: any) {
    try {
      this.isLoading = true;
    
      // Get trigger form group
      const triggerGroup = this.automationForm.get('trigger') as FormGroup;
      
      
      // Get conditions form array
      const conditionsArray = this.automationForm.get('conditions') as FormArray;
      while (conditionsArray.length) {
        conditionsArray.removeAt(0);
      }  

      // Loop through the criteria data and add form groups to conditions
      data.events[0].criteria.forEach((item: any) => {
        const conditionGroup = this.fb.group({
          id: [item.id || this.generateUniqueId()],
          type: [item.type || '', Validators.required],
          operator: [item.operator || '', Validators.required],
          value: [item.value || '', Validators.required]
        });        

        // Add the condition form group to the form array
        conditionsArray.push(conditionGroup);
      });      
      
      // Get actions form array
      const actionsArray = this.automationForm.get('actions') as FormArray;
    
      // Clear existing actions
      while (actionsArray.length) {
        actionsArray.removeAt(0);
      }  
      // Loop through the actions data and add form groups to actions  
      data.events.map((event: any) => console.log('1', event));

      data.events.forEach((event: any) => {
        const actionGroup = this.fb.group({
          type: [event.action || '', Validators.required],
          delay: [event.delay || 0, Validators.required],
          stage: [event.stage && event.stage.objectId || '', Validators.required],
          template: [event.templateId && event.templateId.objectId || ''],
          objectId: [event.objectId || '']
        });
    
        // Add the action form group to the form array
        actionsArray.push(actionGroup);
      });
    
      // Patch values to trigger form group
      triggerGroup.patchValue({
        type: data.events[0].event ? 'event' : 'time',
        event: data.events[0].event || '',
        frequency: data.events[0].frequency || '',
        date: data.events[0].startDate && data.events[0].startDate.iso ? moment(data.events[0].startDate.iso).format('YYYY-MM-DD') : '',
        time: data.events[0].startDate && data.events[0].startDate.iso ? moment(data.events[0].startDate.iso).local().format('HH:mm') : '',
      });
      this.selectedEvent = data;
      this.newleadModal!.show();
      this.isLoading = false;
    } catch(_) {
      this.isLoading = false;
      this.notifier.notify('error', 'Something went wrong, please try again');
    }
  }
  
  public async getSpaces() {
    const spacesQuery = {
      where: {
        entity: {
          __type: "Pointer",
          className: "Entity",
          objectId: this.authService.getUser().entityId.objectId
        },
      },
    };
    const resp = await this.dataService.getFromServer(
      "classes/Spaces",
      spacesQuery,
    );
    const spacesList = resp.results;
    return spacesList[0].templateId;
  }

  onActionChange(i: number, selectedValue: string) {
    this.eventsMappingList[i].action = selectedValue;
  }

  onStageChange(index:number, newStageId:string){
    const newStage = this.stagesId.find((stage: { objectId: string; }) => stage.objectId == newStageId);
    this.eventsMappingList[index].stage = newStage;
  }

  onTemplateChange(index:number, newTemplateId:string){
    const newTemplate = this.emailTemplatesList.find((template: { objectId: string; }) => template.objectId == newTemplateId);
    this.eventsMappingList[index].templateId = newTemplate;
  }

  public async onSaveAll() {
    if(this.selectedEvent !== null) {
       // Validate duplicate delays
       const delaysWithActions = this.tabs[this.selectedEvent].map((item) => `${item.delay}-${item.action}`);
       const delaySet = new Set(delaysWithActions);
       
       if (delaysWithActions.length !== delaySet.size) {
         this.notifier.notify('error', 'Duplicate delay-action combinations found. Please ensure all combinations are unique.');
       }
      else {       
        try {
          const promises = this.tabs[this.selectedEvent].map((tab) => {
            let obj = {}
            if(tab.objectId === '' && tab.generateUniqueId) {
              obj = {
                eventName: this.selectedEvent,
                action: tab.action,
                stageObjectId: tab.stage && tab.stage.objectId ? tab.stage.objectId : '',
                templateObjectId: tab.templateId ? tab.templateId.objectId : '',
                enableMapping: tab.enableMapping,
                delay: tab.delay,
                isActive: true,
                entityObjectId : this.authService.getUser().entityId.objectId,
              };
            } else {
              obj = {
                eventName: this.selectedEvent,
                action: tab.action,
                stageObjectId: tab.stage && tab.stage.objectId ? tab.stage.objectId : '',
                templateObjectId: tab.templateId ? tab.templateId.objectId : '',
                enableMapping: tab.enableMapping,
                eventMappingObjectId: tab.objectId,
                delay: tab.delay,
                isActive: tab.isActive,
              };
            }
            const endpoint = tab.objectId === '' ? 'functions/saveEventMapping' : 'functions/updateEventMapping';
            return this.dataService.postToServer(endpoint, obj);
          });

          await Promise.all(promises);
            this.notifier.notify('success', 'Changes saved successfully!!!');
        } catch (_) {
          this.notifier.notify('error', 'Error processing event mappings');
        }
        this.getEventsMappingList();
      }
    }
  }

  public addNewRow(NewMapping?: string) {
    if(this.selectedEvent) {
      
      if (this.tabs[this.selectedEvent] && this.tabs[this.selectedEvent].length >= 6) {
        this.notifier.notify('error', 'Only 6 mappins allowed.');
        return;
      }

      let newTab: Tab = {
        objectId: '',
        generateUniqueId: this.generateUniqueId(),  // Unique ID for the new tab
        templateId: { objectId: '', templateName: '' },
        action: '',
        event: '',
        isActive: true,
        enableMapping: true,
        stage: { objectId: '', name: '' },
      };
      if(NewMapping) {
        if (!this.tabs[this.selectedEvent]) {
          this.tabs[this.selectedEvent] = [];
        }
        newTab = {...newTab, delay: 0}
        this.tabs[this.selectedEvent].push(newTab);
      }else {
        this.tabs[this.selectedEvent].push(newTab);
      }
    }
  }

  public generateUniqueId(): string {
    return 'id_' + new Date().getTime();
  }

  public async deleteRow(journey: any, generateId?: string) {
    try {
      this.isLoading = true;
      console.log('generateId',generateId)
     
        if(journey) {

          journey.events.map(async (event: any) => {
              await this.dataService.updateToServer(
                "classes/EventMapping/" + event.objectId,
                { isActive: false, enableMapping: false  }
              );
          })       
      }
        this.notifier.notify('success', 'Event deleted successfully!!!');
        this.getEventsMappingList();
        this.showMappingHome();
        this.isLoading = false;  
    } catch (_) {
      this.isLoading = false;
      this.notifier.notify('error', 'Error processing event deleting');
    }
  }

  public createNewMapping(newMapping: string) {
    if(!newMapping) {
      this.notifier.notify('error', 'Something went wrong, please try again.');
    } else {
      this.tabEvent = newMapping;
      this.selectedEvent = newMapping;
      this.addNewRow(newMapping);
    }
  }

  public showMappingHome() {
    this.tabEvent = '';
    // this.selectedEvent = null;
  }
  
  public addNewRowData() {
    this.automationForm = this.fb.group({
      mappingname: this.fb.group({  // Ensure mappingname is correctly set up
        name: ['', Validators.required]
      }),
      trigger: this.fb.group({
        type: ['event'],
        event: [''],
        frequency: [''],
        date: [''],
        time: ['']
      }),
      conditions: this.fb.array([]),
      actions: this.fb.array([])
    });
    this.selectedEvent = null;
    this.newleadModal!.show();
  }

   public async loadProducts(data: any) {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear().destroy(); // Properly destroy DataTable before reinitializing
      });
    }
      this.isLoading = true;
      const objectIdsArray = (data.events || []).map((object: any) => ({
        __type: "Pointer",
        className: "EventMapping",
        objectId: object.objectId
      }));
    
      if(objectIdsArray.length) {
      
        let eventsMappingQuery = {
          where: {
            entity: {
              __type: "Pointer",
              className: "Entity",
              objectId: this.authService.getUser().entityId.objectId
            },
            mapping: { $in: objectIdsArray }
          },
        };
                
        const productResponse = await this.dataService.getFromServer(
          "classes/Jobs",
          eventsMappingQuery
        );
      
      this.productsPageData = productResponse.results || [];
      this.dtTrigger.next(null);
      this.isLoading = false;
  
      this.dtOptions = {
        dom: '<"appRequestsFilter"lf>tipr',
        select: {
          style: 'multi',
          selector: 'td:first-child'
        },
        order: [],
        language: {
          search: '_INPUT_',
          searchPlaceholder: 'Search...',
          lengthMenu: '_MENU_',
        },
        columnDefs: [
          {
            orderable: false,
            className: '',
            targets: 0,
            width: '1px',
            responsivePriority: 1,
            visible: true,
          },
          {
            responsivePriority: 1,
            targets: 1, // Adjusted priority for Job Id column
          }
        ],
        responsive: true,
        data: productResponse.results || [], // Ensure data exists
        columns: [
          {
            data: null,
            defaultContent: '',
            orderable: false,
          },
          {
            title: 'Frequency',
            data: (row: any) => {
              return row.frequency ? row.frequency : 'NA';
            }
          },
          {
            title: 'Status',
            data: (row: any) => {
              return row.isCompleted ? 'Done' : 'Pending';
            }
          },  
          {
            title: 'Start Date',
            data: (row: any) => {
              return row.startDate ? moment(row.startDate.iso).format('DD-MM-YYYY hh:mm A') : 'NA';
            }
          },
          {
            title: 'Executed Date',
            data: (row: any) => {
              return row.updatedAt && row.isCompleted === true ? moment(row.updatedAt.iso).format('DD-MM-YYYY hh:mm A') : 'NA';
            }
          },
        ],
        rowCallback: (row: Node) => {
          return row;
        },
        initComplete: () => {
          const dataTable = $('#jobs-table').DataTable();
          dataTable.on('page.dt', () => {
            const currentPage = dataTable.page.info().page + 1;
            this.goToPage = currentPage;
          });
          setTimeout(() => {
            dataTable.page(this.goToPage - 1).draw(false);
          }, 1000);
        },
      };
      
      // Ensure DataTable initializes correctly
      setTimeout(() => {
        if (this.dtElement && this.dtElement.dtInstance) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.clear().destroy();
            setTimeout(() => {
              this.dtTrigger.next();
            }, 10);
          });
        } else {
          this.dtTrigger.next();
        }
      }, 10);
    }
  }   
}
