import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  ViewChild,
  AfterViewInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  HostListener, OnDestroy,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {ChatHistoryService} from "../../services/chatHistory.service";
import * as Parse from 'parse';
import {AuthService} from "../../services/auth";
import {NotifierService} from "angular-notifier";
import { getRelativeTime } from 'src/app/helpers/helper.date';

@Component({
  selector: 'app-ai-assist',
  templateUrl: './ai-assist.component.html',
  styleUrls: ['./ai-assist.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AiAssistComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('dynamicContentContainer') public dynamicContentContainer: ElementRef;
  @ViewChild('chatLog') public chatLog: ElementRef;
  @ViewChild('audioControl') audioControl: ElementRef<HTMLAudioElement>;

  public defaultPrompts = [
    {
      category: 'Emails',
      prompts: [
        'Generate a personalised email to [Prospect Name] introducing him [Product/Service]',
        'Generate a follow-up email to [Prospect Name] following him on next [action]',
        'Generate a thank-you email',
        'Draft a closing email to finalize a deal',
      ],
    },
    {
      category: 'Strategies',
      prompts: [
        'Create a Step by Step Go To Market Strategy for Launching [Product/Service]',
      ],
    },
    {
      category: 'Social Media',
      prompts: [
        'Generate a Social media Post for [Product/Service] highlighting [Feature/Benefit]',
      ],
    },
    {
      category: 'Scripts',
      prompts: [
        'Generate a video script introducing [Product/Service/Feature]',
        'Generate a script to handle specific objections',
      ],
    },
  ];

  public isChatOpen: boolean;
  public showLoader: boolean;
  public disableSubmit: boolean;
  public aiInitMessage: string;
  public aiReplayMessage: string;
  public userQuery: string;
  public userInput: string;
  public threadId: string;
  private dynamicContentCount = 0;
  private aiResponseArray: string[] = [];
  private aiMessageHistory: { flag: string, inputContent: string }[] = [];
  public prePromptsList = [];

  public userChoice: 'message-copy' | 'article' | '';
  public isListening: boolean = false;
  public audioSrc: string | null = null;
  public subscription!: Subscription;
  public botConfig: { name: string, initialPrompt: string, prePrompts: [] }
  public showDeleteChatHistoryIcon: boolean = false;
  public textareaHeight: string = '40px';
  public inputText: string = '';

  public selectedCategory: string | null = null;
  public promptsToShow: string[] = [];

  // @ts-ignore
  constructor(
    private renderer: Renderer2,
      private cdr: ChangeDetectorRef,
      private chatHistoryService: ChatHistoryService,
      public authService: AuthService,
      private notifier: NotifierService,
  ) { }

  public ngOnInit() {
    this.isChatOpen = false;
    this.showLoader = false;
    this.aiInitMessage = '';
    this.userInput = '';
    this.threadId = '';
    this.liveQueryUpdates();
    this.loadChatHistory();
    this.selectCategory('Emails');
  }

  public async loadChatHistory() {
    // $('.messageDiv').remove()
    const userId =  this.authService.getUser().objectId
    const chatData = await this.getChatHistory();
    const chatDateTime = await this.getMsgDateTime();
    
    if (chatData && chatDateTime && chatData.length && userId) {
        this.showDeleteChatHistoryIcon = true;
  
            chatData.forEach((data, index) => {
                const message = data.get('message');
                const dateTime = chatDateTime[index];

                this.aiResponseArray.push(message);
                this.dynamicContentHandler(
                  data.get('fromUser') && data.get('fromUser').id === userId ? 'userPrompt' : 'aiResponse',
                  this.formatResponse(message), 
                  dateTime 
              );
            });  
    }
  }

  public ngAfterViewInit() {

  }

  @HostListener('click', ['$event'])
  public onClick(event: Event) {
      const target = event.target as HTMLElement;
      const closestIcon = target.closest('.message-copy-icon');
      const contentId = closestIcon ? closestIcon.getAttribute('data-content-id') : null;
      if (contentId) {
        console.info(contentId);
          this.copyAiMessage(parseInt(contentId));
      }
  }
  

  public setInitAIMessage() {
    this.aiInitMessage = '';
    this.userInput = '';
    this.userQuery = '';
    this.aiReplayMessage = '';
    // @ts-ignore
    if (this.userChoice === 'email') {
      this.userInput = 'Generate warm email for your prospects';
    }
    if (this.userChoice === 'article') {
      this.userInput = 'Generate article';
    }
    // this.dynamicContentHandler('aiInitMessage', this.aiInitMessage)
  }

  public dynamicContentHandler(flag: string, inputContent: string, dateTime: string) {
    this.aiMessageHistory.push({ flag, inputContent });
    let content = '';

    const formattedDateTime = `<small class="message-time">${getRelativeTime(dateTime)}</small>`;

    if (flag === 'aiInitMessage' || flag === 'userPrompt') {
        content = `
            <div class="user-message" data-content-id="${this.dynamicContentCount}">
                <div class="user-icon">   
                    <div class="user-message-header">
                    <div> ${formattedDateTime}</div>
                    </div>
                </div>
                <div class="user-message-text">${inputContent}</div>
            </div>`;
    }
    
    if (flag === 'aiResponse') {
        content = `
            <div class="system-reply-message" data-content-id="${this.dynamicContentCount}">
                <div class="ai-icon">
                <div class="ai-message-header">
                    <img src="../../../assets/images/chatbot/ai-stars.png" alt="AI Icon" width="24" height="24">                   
                    <div>
                      ${formattedDateTime}
                    </div>
                </div>
                </div>
                <div class="system-message-text" style="display: flex">
                    <div>
                        <div>${inputContent}</div>
                        <div>
                            <div class="message-copy-icon" title="copy" data-content-id="${this.dynamicContentCount}" (click)="copyAiMessage(${this.dynamicContentCount})">
                                <img src="assets/images/chatbot/copy_icon.svg" alt="copy" width="12">
                            </div>
                        </div>
                    </div>
                </div>
            </div>`;
    }

    this.dynamicContentCount++;

    setTimeout(() => {
        const newDiv = this.renderer.createElement('div');
        newDiv.className = 'messageDiv';
        this.renderer.setProperty(newDiv, 'innerHTML', content);
        if (this.dynamicContentContainer) {
            this.renderer.appendChild(this.dynamicContentContainer.nativeElement, newDiv);
        }
        this.chatLog.nativeElement.scrollTop = this.chatLog.nativeElement.scrollHeight;
        this.cdr.detectChanges();
    }, 100);
  }

  public selectCategory(category: string): void {
    this.selectedCategory = category;
    const selectedCategoryObj = this.defaultPrompts.find(cat => cat.category === category);
    this.promptsToShow = selectedCategoryObj ? selectedCategoryObj.prompts : [];
  }

  public onPromptClick(prompt: any) {
    this.userInput = prompt; 
  }

  public async submitPrompt() {
    this.showLoader = true;
    this.disableSubmit = true;
    this.aiReplayMessage = '';
    this.userQuery = this.userInput;
    this.userInput = '';
    this.aiResponseArray.push(this.userQuery)
    const currentDateTime = new Date().toLocaleString(); 

    this.dynamicContentHandler('userPrompt', this.formatResponse(this.userQuery), currentDateTime); 


    // const openAIResp = await this.dataService.postToServer(
    //   'functions/get-openAI-response', {userPrompt: this.userQuery, contentType: this.userChoice, threadId: this.threadId},
    // );

    this.chatHistoryService.chatHistory(this.userQuery)

    // const response = openAIResp.result
    // this.threadId = response.threadId
    // this.aiReplayMessage = response.content;
    // this.aiResponseArray.push(this.aiReplayMessage)
    // this.dynamicContentHandler('aiResponse', this.formatResponse(this.aiReplayMessage))
    // this.showLoader = false;
    // this.disableSubmit = false;
  }

  public formatResponse(response: string): string {
    // Replace \n with <br> and \t with &nbsp; (non-breaking space)
    return response.replace(/\n/g, '<br>').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
  }

  public async copyAiMessage(contentId: number) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.innerHTML = this.aiResponseArray[contentId] ? this.aiResponseArray[contentId] : '';
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    const copyIcon = document.querySelector(`.message-copy-icon[data-content-id="${contentId}"]`);
    if (copyIcon) {
      copyIcon.innerHTML = `
        <span class="copy-success-message">
          <img src="assets/images/chatbot/tick-icon.svg" alt="Tick" width="12" style="margin-right: 4px;" />
          Copied!
        </span>`;
      
      setTimeout(() => {
        copyIcon.innerHTML = `<img src="assets/images/chatbot/copy_icon.svg" alt="copy" width="12">`;
      }, 2000);
    }
  }

  public ngOnDestroy() {
    // Clean up the subscription
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public async getChatHistory(){
    try {
      return this.chatHistoryService.getChatHistory();
    } catch (e) {
      console.log(e);
      this.notifier.notify('error', 'Something went wrong, please try again.');
      return;
    }
  }

  public async getMsgDateTime(){
    try {
      return this.chatHistoryService.getMsgDateTime();;
    } catch (e) {
      console.error(e);
      return;
    }
  }

  public async liveQueryUpdates(){
    let self = this;
    let chatHistorySubscription : any;
    const chatHistoryObj = Parse.Object.extend('ChatHistory');
    const chatHistoryQuery = new Parse.Query(chatHistoryObj);
    chatHistoryQuery.equalTo('entity', {__type: 'Pointer', className: 'Entity', objectId: this.authService.getUser().entityId.objectId});
    if (chatHistorySubscription) chatHistorySubscription.unsubscribe();
    chatHistorySubscription = await chatHistoryQuery.subscribe();
    chatHistorySubscription.on('create', async function(data: any) {
      if(data.get('fromUser') && data.get('fromUser').id !== self.authService.getUser().objectId) {
        const message = data.get('message');
        const dateTime = new Date(data.createdAt).toLocaleString();
        self.aiResponseArray.push(data.get('message'))
        self.dynamicContentHandler('aiResponse', self.formatResponse(message), dateTime);
        self.showLoader = false;
        self.disableSubmit = false;
      }
    });
  }

  public async deleteChatHistory() {
    try {
      const userConfirmed = confirm('Are you sure you want to delete chat history!!!');
      if (userConfirmed) {
        await this.chatHistoryService.deleteChatHistory();
        this.showDeleteChatHistoryIcon = false;
        this.notifier.notify('success', 'Deleted Successfully!!');
        this.loadChatHistory();
      }
    } catch (e) {
      this.notifier.notify('error', 'Something went wrong, please try again.');
      console.log(e);
    }
  }

  public autoResize(event: Event) {
    const target = event.target as HTMLTextAreaElement;
    target.style.height = '40px'; 
    target.style.height = `${target.scrollHeight}px`; 

    const maxHeight = 150;  
    if (target.scrollHeight > maxHeight) {
        target.style.height = `${maxHeight}px`;
        target.style.overflowY = 'scroll';  
    } else {
        target.style.overflowY = 'hidden'; 
    }

    this.textareaHeight = target.style.height;
}


}
