import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { AuthService } from '../../../app/services/auth';
import { ListParams } from 'src/app/types/kanban';

@Component({
  selector: 'kanban-view',
  templateUrl: './kanban-view.component.html',
  styleUrls: ['./kanban-view.component.scss'],
})
export class KanbanViewComponent {
  @Input() public list: ListParams[];
  @Output() public stageChanged = new EventEmitter();
  @Output() public markCompleted = new EventEmitter();
  @Output() public kanbanViewCardContentClicked = new EventEmitter();
  @Output() public deactivatedClicked = new EventEmitter();
  @Output() public activatedClicked = new EventEmitter();
  @Output() public editStageData = new EventEmitter();

  public isAdmin = false;

  constructor(public authService: AuthService) {}

  // tslint:disable-next-line:use-life-cycle-interface
  public async ngOnInit() {
    console.log(this.list);
    if (this.authService.isAdmin()) {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  }

  public drop(evt: CdkDragDrop<string[]>) {
    console.log(evt);
    const container: any = evt.container.data;
    const previousContainer: any = evt.previousContainer.data;
    console.log(evt.previousContainer !== evt.container)
    if (evt.previousContainer !== evt.container) {
      this.stageChanged.emit({
        objectId: container.id,
        data: previousContainer.data[evt.previousIndex],
      });
      transferArrayItem(
        previousContainer.data,
        container.data,
        evt.previousIndex,
        evt.currentIndex
      );
    } else {
      moveItemInArray(container.data, evt.previousIndex, evt.currentIndex);
    }
  }


  public editStage(lst:any) {
    console.log(lst);
    this.editStageData.emit(lst);
  }

  // public editStage(evnt: Event, currentItem: any, index: number) {
  //   console.log(evnt, currentItem, index);
  //   console.log(evnt, currentItem, index);
  // }

  public markComplete(id: string) {
    this.markCompleted.emit(id);
  }

  public onKanbanViewCardContentClick(id:string){
    this.kanbanViewCardContentClicked.emit(
      {id}
    )
  }

  public onKanbanViewDeactivate(id:string){
    this.deactivatedClicked.emit(
      {id}
    )
  }

  public onKanbanViewactivate(id:string){
    this.activatedClicked.emit(
      {id}
    )
  }
  
}
