import {ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {Router,NavigationEnd,UrlSegment} from '@angular/router';
import {DataService} from '../../services/data.service';
import { UserService } from '../../services/user.service';
import {User} from '../../types/user';
import {Notifications} from '../../types/notifications';
import {NotificationService} from '../../services/notification.service';
import {EmitterService} from '../../services/emitter.service';
import {AuthService} from '../../services/auth';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { HelperService } from '../../services/helper.service';
import { track } from '@inleads/event-logger';
import { ConfigService } from 'src/app/services/config-data.service';
import {Subscription} from 'rxjs';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() public routeParams: routeParams;
  @Output() public sideBarToggle: EventEmitter<null> = new EventEmitter();
  @ViewChild('standUpModal') public standUpModal?: ModalDirective;
  @ViewChild('firstDataModal') public firstDataModal?: ModalDirective;
  @ViewChild('dynamicContentContainer') public dynamicContentContainer: ElementRef;
  //@ViewChild('chatLog') public chatLog: ElementRef;
  
  public firstDataModalConfig: any = { backdrop: true };
  public isLoggedIn: boolean;
  public navbarOpen = false;
  public isSidebarOpen: boolean = false;
  public prefillinputValue: string = '';
  filter: string = 'all';
  public showHead: boolean;
  public cUseremail: string;
  public currentUser: User;
  public notifications: Notifications[];
  public profileImg: any;
  public tagline: string;
  public sideMenu = ['Dashboard','Settings','Leads','Contacts','Customer-details','Analytics','Agents' , 'Managers', 'Products', 'Spaces', 'Support', 'Event-details',
    'Profile', 'Quotes', 'Hub', 'Tasks', 'Online','Activity', 'Account', 'Outreach', 'Email-Templates', 'dau-metrics', 'Visitors', 'Visitor-details', 'Lead-details', 'Groups', 'Ask-ai', 'Automation'];
  public message?: string;
  public showButton = 1;
  public followUpId: string;
  public followUpCount:number = 0;
  @ViewChild('template') public template?: ModalDirective;
  public followUpSubscription$ : any;
  public isTrileExpire =false;
  public notificationsSubscription$ : any;
  public configData: any;
  public entitySpaceId: any;
  public eventsApiKey: string;
  public connectBtnShow: boolean = false;
  public eventsList: any[] = [];
  public showLoader: boolean;
  public disableSubmit: boolean;
  public aiReplayMessage: string;
  public userQuery: string;
  public userChoice: 'message-copy' | '';
  public aiInitMessage: string;
  public threadId: string;
  private aiResponseArray: string[] = [];
  private messageHistory: { flag: string, inputContent: string }[] = [];
  private dynamicContentCount = 0;
  // tslint:disable-next-line:no-any
  public standUpData : any = {
    question1: {
      question: '',
      answer: '',
      questionId : '',
      followupRespId : ''
    },
  };
  public currentQuestionData = {
    index: 1,
    questionId : '',
    answer : this.standUpData.question1.answer,
  };
  emailPrompts: { [key: string]: string } = {
    coldEmail: "Generate a cold email for [Product/Service] aimed at [Target Audience] that offers a free trial or demo.",
    followUpEmail: "Write a 100-word follow-up email to [prospect name] who I've just had a call with and is now ready to [action].",
    coldEmailSocialProof: "Write a cold email for [Product] targeting [Target Audience] that includes social proof, such as customer testimonials or case studies, to build credibility and trust. [You can also add your reviews here].",
    introducingYourself: "Briefly introduce yourself and explain how your [services]/[products] can solve the recipient's challenges.",
    identifyEmergingTrends: "What are the current and emerging trends in our target [market] or [industry]?",
    competitiveAnalysis: "Who are main competitors of  [Product], and what are their strengths and weaknesses?",
    marketPositioningStrategy: "How should we position [Product] in the [market] based on our competitive analysis?",
    intriguingQuestion: "Did you know [insert interesting statistic or fact] about [industry/topic]?",
    briefCallInvitation: "Would you be open to a 15-minute call next week to discuss how we can address [specific challenge] at [prospect's company]?",
    webinarInvite: "We're hosting a webinar on [topic] that addresses [challenge]. It could provide valuable insights for [prospect's company]. Can I count you in?",
    checkingIn: "Just wanted to make sure you received my previous message about [topic]. I believe [prospect's company] could really benefit from [product/service].",
    researchProspectsBusiness: "What is the core business of the [prospect], and what recent [achievements] or [challenges] have they faced?",
    identifyDecisionMakers: "Who are the key decision-makers and stakeholders in the [prospect's organization]?",
    comparativeAdvantage: "Have you considered the cost of not using our solution compared to the benefits it provides, such as [specific benefit]?",
    futurePlanning: "I understand the timing might not be perfect. Could we schedule a brief call in the future when it's more convenient for you?",
    shareIndustryInsights: "Have you seen the latest trends in [industry]? Here's how they're impacting businesses like yours.",
    caseStudyTeaser: "Our latest case study reveals how we helped [type of business] overcome [challenge]. Let me know if you'd like a copy!",
    networkingFollowUp: "Great connecting with you here! If you're open to it, I'd love to explore how we can collaborate or support each other.",
  };
  private routerEventsSubscription: Subscription;
  private currentPath: string;
  public isAdmin: boolean;
  public isSuperAdmin: boolean;

  constructor(
    private notifier: NotifierService,
    public router: Router,
    private dataService: DataService,
    private configService: ConfigService,
    private notificationService: NotificationService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    public userService: UserService,
    private auth: AuthService,
    private helperService: HelperService) {
  }

  public async ngOnInit() {
    this.notificationsSubscription$ = this.helperService.getLiveQueryNotificationsData().subscribe(async (response: any) => {
      console.log("response"+response);
      this.loadNotifications().then();
    });
    this.isLoggedIn = this.auth.isSignedIn();
    const user:any = localStorage.getItem('user');
    this.configData = await this.configService.getConfigData();
    //localStorage.removeItem('chat_history');
    this.showLoader = false;
    this.aiInitMessage = '';
    this.prefillinputValue = '';
    this.threadId = '';
  

    if (user) {
      const obj = JSON.parse(user);
      await this.dataService.updateSessionHeaders(obj.sessionToken);
      this.currentUser = obj;
      this.cUseremail = obj.email;

      const entityData = localStorage.getItem('entity');
      const resp = entityData ? JSON.parse(entityData) : null;
      this.profileImg = (resp && resp.logo&& resp.logo.url) || (obj.profilePhoto && obj.profilePhoto.url) || 'assets/images/user-01.svg';

      this.showHead = !!this.currentUser;
      this.loadNotifications().then();
      // this.getFollowup();
      // this.checkUserTrileExpire();
      this.isAdmin = this.auth.isAdmin();
      this.isSuperAdmin = this.auth.isSuperAdmin();
    }

    EmitterService.get('headerUpdate').subscribe((obj: any) => {
      const user : User = obj;
      const entityData = localStorage.getItem('entity');
      const resp = entityData ? JSON.parse(entityData) : null;
      this.profileImg = (resp && resp.logo&& resp.logo.url) || (user && user.profilePhoto ? user.profilePhoto.url : 'assets/images/user-01.svg') || 'assets/images/user-01.svg';
      this.currentUser = user;
    });
    // if(!this.followUpSubscription$ && this.auth.isSignedIn() && this.auth.getUser().entityId && this.auth.getUser().entityId.objectId){
    //   await this.dataService.getFollowUpsLiveQueryData(this.auth.getUser().entityId.objectId);
    //   this.followUpSubscription$ = this.helperService.getLiveQueryFollowUps().subscribe(async (response:any) => {
    //     const data = response.toJSON();
    //     if(data.action === 'open'){
    //     // await this.fetchFollowUpData();
    //     // await this.showFollowUpModal();
    //    }
    //   });
    // }
    await this.checkUserTrileExpire();
    await this.checkEventsExist();
    this.connectBtnShow = true;

    this.routerEventsSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateCurrentPath();
      });
    this.updateCurrentPath();
  }

  async checkEventsExist() {
    if(!this.auth.getUser()){
      return
    }
    const entityId = this.auth.getUser().entityId.objectId;

      const params = {
        limit: 1,
        where: {
          name:{$nin:['VISIT']},
          entity: {
            __type: "Pointer",
            className: "Entity",
            objectId: entityId,
          },
        },
      };
    
      try {
        const paramsResponse = await this.dataService.getFromServer("classes/Events", params);
        this.eventsList = paramsResponse.results;
        if (this.eventsList.length <= 0) {
          await this.getEntitySpaceId();
          await this.getEntityEventsApiKey();
        }
      } catch (error) {
        console.error("Error checking events existence:", error);
      }
    }
    async getEntitySpaceId() {
      const params = {
        where: {
          entity: {
            __type: "Pointer",
            className: "Entity",
            objectId: this.auth.getUser().entityId.objectId,
          },
        },
      };
      try {
        const paramsResponse = await this.dataService.getFromServer("classes/Spaces", params);
        this.entitySpaceId = paramsResponse.results[0].objectId;
      } catch (error) {
        console.error("Error getting entity space ID:", error);
      }
    }
  
  openAI() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  closeSidebar() {
    this.isSidebarOpen = false;
  }

  prefillInput(text: string) {
    this.prefillinputValue = this.emailPrompts[text];
    // this.resetTextareaHeight();

    // Get the textarea element
    const textarea = document.querySelector('.input-field') as HTMLTextAreaElement;

    if (textarea) {
      textarea.value = this.prefillinputValue; // Update textarea value with prefilled input
      textarea.style.height = 'auto';
      const scrollHeight = textarea.scrollHeight;

      // Check if the scroll height exceeds the maximum height
      const maxHeight = 150;
      if (scrollHeight > maxHeight) {
        textarea.style.height = `${maxHeight}px`;
        textarea.style.overflowY = 'auto';
      } else {
        textarea.style.height = `${scrollHeight}px`;
        textarea.style.overflowY = 'hidden';
      }

      // Adjust the margin-bottom of the container to make it grow upwards
      const container = textarea.parentElement as HTMLElement;
      const textareaHeight = textarea.offsetHeight;
      container.style.marginBottom = `-${textareaHeight - textarea.clientHeight}px`;
    }

  }

  setFilter(filter: string) {
    this.filter = filter;
  }
  
  autoGrow(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    const scrollHeight = textarea.scrollHeight;
  
    // Check if the scroll height exceeds the maximum height
    const maxHeight = 150;
    if (scrollHeight > maxHeight) {
      textarea.style.height = `${maxHeight}px`;
      textarea.style.overflowY = 'auto';
    } else {
      textarea.style.height = `${scrollHeight}px`;
      textarea.style.overflowY = 'hidden';
    }
  
    // Calculate how much the container should move upwards
    const container = textarea.parentElement as HTMLElement;
    const textareaHeight = textarea.offsetHeight;
  
    // Adjust the margin-bottom of the container to make it grow upwards
    container.style.marginBottom = `-${textareaHeight - textarea.clientHeight}px`;
  }

  public setInitAIMessage() {
    this.aiInitMessage = '';
    this.prefillinputValue = '';
    this.userQuery = '';
    this.aiReplayMessage = '';
    // @ts-ignore
    if (this.userChoice in emailPrompts) {
      this.prefillinputValue = this.emailPrompts[this.userChoice];
    }
  }

  @HostListener('click', ['$event'])
  public onClick(event: Event) {
    const target = event.target as HTMLElement;
    if (target && target.classList.contains('message-copy')) {
      const contentId = target.getAttribute('data-content-id');
      if (contentId) {
        this.copyMessage2(+contentId);
      }
    }
  }

  public async copyMessage2(contentId: number) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.innerHTML = this.aiResponseArray[contentId] ? this.aiResponseArray[contentId] : '';
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  resetTextareaHeight() {
    const textarea = document.querySelector('.input-field') as HTMLTextAreaElement;
    if (textarea) {
      textarea.style.height = 'auto';
    }
  }

  public async submitPrompt() {
    if (this.prefillinputValue.trim() !== '') {
    this.showLoader = true;
    this.disableSubmit = true;
    this.aiReplayMessage = '';
    this.userQuery = this.prefillinputValue;
    this.prefillinputValue = '';
    this.resetTextareaHeight();
    this.dynamicContentHandler('userPrompt', this.userQuery);
    //console.log("dynamicContentHandler---",this.dynamicContentHandler)

    const openAIResp = await this.dataService.postToServer(
      'functions/get-openAI-response', {userPrompt: this.userQuery, contentType: this.userChoice, threadId: this.threadId},
    );
    const response = openAIResp.result;
    this.threadId = response.threadId;
    this.aiReplayMessage = response.content;
    this.aiResponseArray.push(this.aiReplayMessage);
    this.dynamicContentHandler('aiResponse', this.formatResponse(this.aiReplayMessage));
    this.showLoader = false;
    this.disableSubmit = false;
    }
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.submitPrompt();
    }
  }

  public formatResponse(response: string): string {
    // Replace \n with <br> and \t with &nbsp; (non-breaking space)
    return response.replace(/\n/g, '<br>').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
  }

  public dynamicContentHandler(flag: string, inputContent: string) {

    this.messageHistory.push({flag, inputContent})
    let content = ''
    if (flag === 'aiInitMessage' || flag === 'userPrompt') {
      content = '<div class="user-message">\n' +
        '                <div class="user-message-text">' + inputContent + '</div>\n' +
        '            </div>'
    }
    if (flag === 'aiResponse') {
      content = '<div class="system-reply-message" data-content-id="' + this.dynamicContentCount + '">\n' +
        '                    <div class="system-message-text" style="display: flex">\n' +
        '                        <div>\n' +
        '                            <div>' + inputContent + '</div>\n' +
        '\n' +
        '                            <div>\n' +
        '                                <div class="message-copy"  data-content-id="' + this.dynamicContentCount + '"   (click)="copyMessage2($event, ' + this.dynamicContentCount + ')"><img\n' +
        '                                        src="assets/images/chatbot/copy_icon.svg" alt="copy"\n' +
        '                                        width="12">&nbsp;Copy\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '                </div>'
      this.dynamicContentCount++;
    }

    setTimeout(() => {
      const dynamicContent = content;
      const newDiv = this.renderer.createElement('div');
      newDiv.className = 'messageDiv';
      this.renderer.setProperty(newDiv, 'innerHTML', dynamicContent);
      if (this.dynamicContentContainer) {
        this.renderer.appendChild(this.dynamicContentContainer.nativeElement, newDiv);
      }
      this.cdr.detectChanges();
    }, 100)
  }

  async checkUserTrileExpire(){
    const userEntityData = await this.userService.userEntityData();
    if(!userEntityData){
      return
    }
    // Check if the user is subscribed
    const ctrAt =new Date(userEntityData.createdAt)
    const userCreatedAt = moment(ctrAt);
    if(userEntityData && userEntityData.entityId){
      const trialPeriod = userEntityData.entityId.trialPeriod ? Number(userEntityData.entityId.trialPeriod) : 30;
      const fifteenDaysAdded = moment(userCreatedAt).add(trialPeriod, 'days');
      this.isTrileExpire = userEntityData.paidUser ? false : moment().isAfter(fifteenDaysAdded);
    }
  }

  // public async fetchFollowUpData(){
  //   const params = {
  //     include: 'entity,user,questions',
  //     where: {
  //       entity: {
  //         __type: 'Pointer',
  //         className: 'Entity',
  //         objectId: this.auth.getUser().entityId.objectId,
  //       },
  //     },
  //     // limit:100,
  //   };
  //   const paramsResponse = await this.dataService.getFromServer(
  //       'classes/FollowUps',
  //       params,
  //   );
  //   // tslint:disable-next-line:no-any
  //   let followUpResp : any = {};
  //   if(paramsResponse.results){
  //     if(paramsResponse.results.length){
  //       followUpResp = paramsResponse.results[0];
  //       this.followUpCount = paramsResponse.results.length;
  //       this.followUpId = followUpResp.objectId;
  //       this.standUpData = {};
  //     }
  //   }
  //   followUpResp.questions.forEach((q:any, i:number) => {
  //     // if(key.includes('question')){
  //       this.standUpData[`question${i+1}`] = {
  //         question: q.name,
  //         answer : '',
  //         questionId : q.objectId,
  //         followupRespId : ''
  //       }
  //     // }
  //   });
  //   if(Object.entries(this.standUpData).length > 0){
  //     this.currentQuestionData = {
  //       index: 1,
  //       questionId : this.standUpData[`question${1}`].questionId,
  //       answer : this.standUpData[`question${1}`].answer,
  //     }
  //   }
  // }

  public nextQuestion = () => {
    let key = 'question' + this.currentQuestionData.index;
    this.standUpData[key].answer = this.currentQuestionData.answer;
    this.currentQuestionData.index += 1;
    key = 'question' +  this.currentQuestionData.index;
    this.currentQuestionData.answer = this.standUpData[key].answer;
  };

  public previousQuestion = () => {
    this.currentQuestionData.index -= 1;
    const key = 'question' + this.currentQuestionData.index;
    this.currentQuestionData.answer = this.standUpData[key].answer;
  };

  // public getExistingFollowUpRes = async () => {
  //   const startDate = moment().startOf('day').toDate().toISOString();
  //   const endDate = moment().endOf('day').toDate().toISOString();
  //   const params = {
  //     include: 'entity,user,question',
  //     where: {
  //       user: {
  //         __type: 'Pointer',
  //         className: '_User',
  //         objectId: this.auth.getUser().objectId,
  //       },
  //       entity: {
  //         __type: 'Pointer',
  //         className: 'Entity',
  //         objectId: this.auth.getUser().entityId.objectId,
  //       },
  //       createdAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}},
  //     },
  //   };
  //   const paramsResponse = await this.dataService.getFromServer(
  //       'classes/FollowUpResponse',
  //       params,
  //   );
  //   return paramsResponse;
  // };
  //
  // public saveFollowUpResponses = async () => {
  //   const key = 'question' + this.currentQuestionData.index;
  //   this.standUpData[key].answer = this.currentQuestionData.answer;
  //   this.currentQuestionData.answer = this.standUpData[key].answer;
  //   const paramsResponse = await this.getExistingFollowUpRes();
  //   const submitParams : any = {
  //     user: {
  //       __type: 'Pointer',
  //       className: '_User',
  //       objectId: this.auth.getUser().objectId,
  //     },
  //     entity: {
  //       __type: 'Pointer',
  //       className: 'Entity',
  //       objectId: this.auth.getUser().entityId.objectId,
  //     },
  //     followup: {
  //       __type: 'Pointer',
  //       className: 'FollowUps',
  //       objectId: this.followUpId,
  //     },
  //   };
  //   if(paramsResponse.results &&  paramsResponse.results.length === 0){
  //     // @ts-ignore
  //     Object.keys(this.standUpData).forEach(async (key) => {
  //       const obj = {
  //         answer: this.standUpData[key].answer,
  //         question: {
  //           __type: 'Pointer',
  //           className: 'Questions',
  //           objectId: this.standUpData[key].questionId,
  //         },
  //         ...submitParams
  //       }
  //       await this.dataService.postToServer(
  //         'classes/FollowUpResponse',
  //         obj,
  //       );
  //     });
  //     this.closefollowUpModal();
  //     this.notifier.notify('success','FollowUp Saved Successfully !!!');
  //   }
  //   else if(paramsResponse.results.length > 0){
  //     Object.keys(this.standUpData).forEach(async (key) => {
  //       const obj = {
  //         answer: this.standUpData[key].answer,
  //         question: {
  //           __type: 'Pointer',
  //           className: 'Questions',
  //           objectId: this.standUpData[key].questionId,
  //         },
  //         ...submitParams
  //       }
  //       await this.dataService.updateToServer(
  //           'classes/FollowUpResponse/' + this.standUpData[key].followupRespId,
  //           obj,
  //       );
  //     });
  //     this.closefollowUpModal();
  //     this.notifier.notify('success','FollowUp Updated Successfully !!!');
  //   }
  // }

  public showStandUpButton = (button: string) => {
    if(button === 'previous'){
      return this.currentQuestionData.index > 1
    }
    if(button === 'next'){
      return this.currentQuestionData.index < Object.entries(this.standUpData).length
    }
    if(button === 'submit'){
      return this.currentQuestionData.index === Object.entries(this.standUpData).length
    }
    return false;
  };

  public async logoutUser() {
    const userDetails = {};
    localStorage.clear();
    sessionStorage.clear();
    this.template!.hide();
    try {
      await this.dataService.postToServer('/logout', userDetails);
      this.helperService.cleanupLeadsLiveQueries();
      track('LOGOUT');
      await this.dataService.cleanHeaders();      
      if (this.routeParams) {
        await this.router.navigateByUrl('');
      } else {
        window.location.reload();
      }
    } catch (e) {
      console.error(e);
      window.location.reload();
    }    
  }

  public toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  public toggleNavigation() {
    this.sideBarToggle.emit();
  }

  private loadNotifications = async () => {
    this.notifications = await this.notificationService.getNotifications();
  };

  public async updateNotificationStatus(objectId: string) {
    await this.notificationService.updateNotificationStatus(objectId);
    this.notifications.map(n => {
      if (n.objectId === objectId) {
        n.status = 'read';
      }
    });
  }

  public hideBadge(){
    $( 'span' ).removeClass( 'badge');
  }

  // public async showFollowUpModal()
  // {
  //   // await this.fetchFollowUpData();
  //   if(this.followUpCount === 1){
  //   const paramsResponse = await this.getExistingFollowUpRes();
  //   if(paramsResponse.results && paramsResponse.results.length > 0){
  //     const followUpResp = paramsResponse.results;
  //     Object.keys(this.standUpData).forEach(key => {
  //       const [question] = followUpResp.filter((q:any) => q.question.objectId === this.standUpData[key].questionId);
  //       this.standUpData[key].answer = question.answer;
  //       this.standUpData[key].followupRespId = question.objectId;
  //     })
  //     this.currentQuestionData.answer = this.standUpData['question'+ this.currentQuestionData.index].answer;
  //   }
  //   this.standUpModal!.show();
  // }
  //   else{
  //     this.notifier.notify('error','No Records Found !!!');
  //   }
  // }

  public closefollowUpModal(){
    this.standUpModal!.hide();
  }

  // public async getFollowup() {
  //   if(this.auth.getUser().objectId) {
  //     try {
  //       const paramsData: any = {
  //         where: {
  //         user : {
  //         __type: 'Pointer',
  //         className: '_User',
  //         objectId: this.auth.getUser().objectId,
  //         },},};
  //     const resp = await this.dataService.getFromServer(
  //       'classes/FollowUps/',paramsData
  //     );
  //     if(resp.results && resp.results.length) {
  //              this.showButton = resp.results.length;
  //       return resp.results;
  //     }else {
  //       this.showButton = 0;
  //       return;
  //     }
  //   } catch (e) {
  //     if (e instanceof Error) {
  //     this.notifier.notify('error', e.message);
  //     }
  //   }
  //   } else {
  //     this.notifier.notify('error', 'Invalid user');
  //   }
  // }

  ngOnDestroy() {
    if(this.followUpSubscription$) this.followUpSubscription$.unsubscribe();
    if (this.notificationsSubscription$) this.notificationsSubscription$.unsubscribe();
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  public async validateDataAndSave() {
    const activityParams = {
      where: {
        entity: {
          __type: "Pointer",
          className: "Entity",
          objectId: this.auth.getUser().entityId.objectId,
        },
      },
    };
    const events = await this.dataService.getFromServer(
      "classes/Events",
      activityParams
    );
    if (events.results && events.results.length > 0) {
      this.firstDataModal!.hide();
      track("COMPLETED_ONBOARDING_STEPS");
      this.router.navigateByUrl("/leads/" + this.entitySpaceId, {
        replaceUrl: true,
      });
    } else {
      this.notifier.notify(
        "error",
        "Events not detected. Please follow steps above to connect."
      );
    }
  }

  public openEventLogger(link: any) {
    window.open(link, "_blank");
  }

  public hideConnectSDKModal() {
    this.firstDataModal!.hide();
    track("PENDING_ONBOARDING_STEPS");
    this.router.navigateByUrl("/leads/" + this.entitySpaceId, {
      replaceUrl: true,
    });
  }

  public async getEntityEventsApiKey() {
    const entityResponse = await this.dataService.getFromServer(
      "classes/Entity/" + this.auth.getUser().entityId.objectId
    );
    
    if (entityResponse && entityResponse.eventsApiKey === undefined) {
      this.updateEventsApiKey();
    } else {
      this.eventsApiKey = entityResponse.eventsApiKey;
    }
  }

  public async updateEventsApiKey() {
    try {
      const response = await this.dataService.postToServer(
        "functions/updateEventsApiKey",
        {}
      );
      this.eventsApiKey = response.result;
    } catch (e) {
      this.notifier.notify("error", "Unable to update Api key");
    }
  }

    public async copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.innerText = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  private updateCurrentPath() {
    const urlTree = this.router.parseUrl(this.router.url);
    const primaryChild = urlTree.root.children['primary'];
    if (primaryChild) {
      this.currentPath = primaryChild.segments.map((it: UrlSegment) => it.path)[0]
    } else {
      this.currentPath = '';
    }
    this.gettingPageTitles(this.currentPath);
  }

  public gettingPageTitles(currentPath: string) {
    const pageTitles: { [key: string]: string } = {
        "leads": "Manage your sales pipeline efficiently by tracking the status and details of each lead through various stages.",
        "tasks": "Organize and prioritize tasks related to your leads, ensuring nothing falls through the cracks.",
        "contacts": "Keep track of your potential and existing customers, monitor their details and interactions with your product.",
        "agents": "Manage your team members, their roles, and their activities to streamline collaboration and accountability.",
        "products": "Catalog and manage your product offerings, including pricing and descriptions.",
        "reports": "Generate and view detailed reports on your leads, sales, and other key metrics to make informed business decisions.",
        "outreach": "Create and manage your email campaigns to engage with leads and customers, tracking the effectiveness of your outreach efforts.",
        "articles": "Centralize your knowledge base articles and other informational resources for easy access and reference.",
        "visitors": "Track and analyze the visitors to your website, gaining insights into their behavior and engagement.",
        "groups": "Create your own groups with your contacts based on criteria and use those groups where required and save your time.",
        "outreach-template": "Create your own email template beforehand and reuse them instantly whenever you need.",
        "contact-details": "Keep track of your potential and existing customers, monitor their details and interactions with your product.",
        "Ask-ai": "Use Ask AI to write for you, or answer questions about sales related prompts in your Workspace.",
        "Automation": "Inleads SDK for Product Analytics",
        "landingPage": "Go Online with your landing page. Start Promoting and Start capturing leads."
    };

    const pageTitle = currentPath;
    this.tagline = pageTitles[pageTitle] || ''; 
  }

}

type routeParams = {
  pageName: string;
  title: string;
};
