import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadsService } from 'src/app/services/leads.service';
import { AuthService } from 'src/app/services/auth';
import { DataService } from 'src/app/services/data.service';
import { User } from 'src/app/types/user';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { validationPattern } from 'src/app/constants/regexp';
import * as moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { Stages } from 'src/app/types/stages';
import { UserService } from 'src/app/services/user.service';
import { LeadParams } from 'src/app/types/leads';
import { Contacts, ContactsParams } from 'src/app/types/contacts';

@Component({
  selector: 'app-lead-details',
  templateUrl: './lead-details.component.html',
  styleUrls: ['./lead-details.component.scss']
})
export class LeadDetailsComponent implements OnInit {
  leadDetailsData: any;
  leadParamsData: any;
  public agentsListData: User[] = [];
  public form: FormGroup;
  public isViewMode: boolean = true;
  public productList: any[] = [];
  public stagesListData: Stages[] = [];
  public selectedSpace: any;
  public lastStage: any;
  public closingObjectId: string | undefined;
  public userPageData: User[] = [];
  public contactList: Contacts[] = [];
  public tagField: any;
  public isSubmitting = false;
  private spaceId : string;
  public currencyPrefix: string = "$";
  public isNewContactSelected = false;


  constructor(private route: ActivatedRoute, private router: Router, private dataService: DataService, private leadService: LeadsService, private authService: AuthService, private notifier: NotifierService,
    public userService: UserService) { }

  async ngOnInit() {
    this.currencyPrefix = localStorage.getItem("currencyPrefix") || this.currencyPrefix;
    if (this.isViewMode === undefined) {
      this.backButtonClick();
    }
    this.form = new FormGroup({
      stage: new FormControl(""),
      product: new FormControl(""),
      noOfSeats: new FormControl("", [Validators.required]),
      estimatedContractLength: new FormControl("", [Validators.required]),
      fullName: new FormControl("", [Validators.required]),
      email:  new FormControl("", [Validators.required, Validators.pattern(validationPattern.email)]),
      phone: new FormControl("", [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(validationPattern.phone)
      ]),
      dealValue: new FormControl(""),
      description: new FormControl(""),
      creator: new FormControl(""),
      leadAssignTo: new FormControl(""),
      objectId: new FormControl("", [Validators.required]),
      contact: new FormControl(""),
      closingDate: new FormControl("", [Validators.required]),
      activityNotes: new FormControl(""),
      userTags: new FormControl(""),
      newContactName: new FormControl(""),
      newContactEmail: new FormControl(""),
      newContactPhone: new FormControl(""),
    });
    this.route.paramMap.subscribe(async paramMap => {
      const leadId = paramMap.get('leadId');
      this.spaceId = paramMap.get('spaceId') || '';
      let requestleadParams: any = {};
      if (this.authService.isSuperAdmin()) {
        requestleadParams = {
          include: 'contact,agent,stage,creator,product',
          order: '-createdAt',
        };
      } else if (this.authService.isAdmin()) {
        requestleadParams = {
          include: 'contact,agent,stage,creator,assignTo,status,product',
          order: '-createdAt',
          where: {
            entity: {
              __type: 'Pointer',
              className: 'Entity',
              objectId: this.authService.getUser().entityId.objectId,
            },
          },
        };
      } else if (this.authService.isAgent()) {
        requestleadParams = {
          include: 'contact,agent,policyType,stage,creator,assignTo,status,product',
          order: '-createdAt',
          where: {
            $or: [
              {
                agent: {
                  __type: 'Pointer',
                  className: '_User',
                  objectId: this.authService.getUser().objectId,
                },
              },
              {
                assignTo: {
                  __type: 'Pointer',
                  className: '_User',
                  objectId: this.authService.getUser().objectId,
                },
              },
            ],
          },
        };
      } else if (this.authService.isManager()) {
        const parameters = {
          where: {
            manager: {
              __type: 'Pointer',
              className: '_User',
              objectId: this.authService.getUser().objectId,
            },
          },
        };
        const userResponse = await this.dataService.getFromServer(
          'classes/_User',
          parameters,
        );
        const agentsList: {
          __type: string;
          className: string;
          objectId: string;
        }[] = [];
        userResponse.results.forEach((user1: User) => {
          agentsList.push({
            __type: 'Pointer',
            className: '_User',
            objectId: user1.objectId,
          });
          this.agentsListData.push(user1);
        });
        agentsList.push({
          __type: 'Pointer',
          className: '_User',
          objectId: this.authService.getUser().objectId,
        })
        requestleadParams = {
          include: 'contact,agent,policyType,stage,creator,assignTo,status,product',
          order: '-createdAt',
          where: {
            $or: [
              { agent: { $in: agentsList } },
              { assignTo: { $in: agentsList } },
            ],
          },
        };
      }
      requestleadParams['where']['objectId'] = leadId || '';

      if (leadId) {
        try {
          this.leadDetailsData = await this.leadService.getLeadById('',requestleadParams);
          this.leadDetailsData = this.leadDetailsData.results[0];
          this.initializeTagField();
          this.getLeadDetails(this.leadDetailsData.objectId);
        } catch (error) {
          console.error('Error fetching lead details:', error);
        }
      }
    });
    
    setTimeout(() => {
      //Setting the price value when the default product is selected initially in the editLeadForm
      this.form.get('product')!.valueChanges.subscribe((selectedProductId) => {
        this.setDealValueFromProduct(this.form, selectedProductId);
      });
      
      // const editLeadDefaultProductId = this.productList[0].objectId;
      // this.setDealValueFromProduct(this.form, editLeadDefaultProductId);
    }, 500);

    this.getContacts();
    this.getProducts();
    this.loadTeams();
    this.getStagesList();
  }

  public onChangeCustomer(e: any) {
    console.log(e);  
  }

  public selectCustomer(e: any) {
    this.form.get('email')!.setValue(e.email);
    this.form.get('phone')!.setValue(e.phoneNumber);
    // this.form.get('contactId')!.setValue(e.objectId);
  }

  //calculating Expected revenue based on no.of subscriptions and estimated contract length
  public onChangeSubsVal(event: Event, prod: any, form: FormGroup) {
    const subsValue = +(event.target as HTMLInputElement).value;
    const eclValue = form.get('estimatedContractLength')!.value;
    this.calcExpRevenue(prod, form, subsValue, eclValue ? eclValue : 1 );
  }

  public onChangeEclVal(event: Event, prod: any, form: FormGroup) {
    const eclValue = +(event.target as HTMLInputElement).value;
    const subsValue = form.get('noOfSeats')!.value;
    this.calcExpRevenue(prod, form, subsValue ? subsValue : 1, eclValue);
  }

  public calcExpRevenue(prod: any, form: FormGroup, subsValue:any, eclValue:any) {
    const selectedProduct = this.productList.find((product) => product.objectId === prod);
    let factor = 1;
    if(selectedProduct.pricePeriod === 'monthly'){
      factor = 12;
    } else if(selectedProduct.pricePeriod === 'weekly'){
      factor = 52;
    } else if(selectedProduct.pricePeriod === 'quarterly'){
      factor = 4;
    } else if(selectedProduct.pricePeriod === 'halfyearly'){
      factor = 2;
    }
    const finalExpRevenue = (subsValue * eclValue * selectedProduct.price * factor);
    form.get('dealValue')!.patchValue(finalExpRevenue);
  }

  //setting the deal value from the product
  public setDealValueFromProduct(formGroup: FormGroup, productId: string) {
    const selectedProduct = this.productList.find((product) => product.objectId === productId);
  
    if (selectedProduct) {
      formGroup.get('dealValue')!.setValue(selectedProduct.price);
      // formGroup.get('noOfSeats')!.setValue(selectedProduct.noOfSeats);
      // formGroup.get('estimatedContractLength')!.setValue(selectedProduct.estimatedContractLength); 
    } else {
      formGroup.get('dealValue')!.reset();
      formGroup.get('noOfSeats')!.reset();
      formGroup.get('estimatedContractLength')!.reset();
    }
  }

  public getLeadDetails(leadObj: string) {
          const userAgent: string = this.leadDetailsData && this.leadDetailsData.agent ? this.leadDetailsData.agent.objectId : '';
          const creator: string = this.leadDetailsData && this.leadDetailsData.creator ? this.leadDetailsData.creator.name : '';
          const leadAssignTo: string = this.leadDetailsData && this.leadDetailsData.assignTo ? this.leadDetailsData.assignTo.objectId : userAgent;
          const stage: string = this.leadDetailsData && this.leadDetailsData.stage ? this.leadDetailsData.stage.objectId : '';
          const product: string = this.leadDetailsData && this.leadDetailsData.product ? this.leadDetailsData.product.objectId : '';
          const contact: string = this.leadDetailsData && this.leadDetailsData.contact ? this.leadDetailsData.contact.objectId : '';
          const noOfSeats: string = this.leadDetailsData && this.leadDetailsData.noOfSeats ? this.leadDetailsData.noOfSeats : '';
          const estimatedContractLength: string = this.leadDetailsData && this.leadDetailsData.estimatedContractLength ? this.leadDetailsData.estimatedContractLength : '';
          let selectedTag;
          if(Array.isArray(this.leadDetailsData.user_tags)){
            selectedTag = this.leadDetailsData.user_tags
          }

          let closingDate;
          if (this.leadDetailsData && this.leadDetailsData.closingDate) {
            closingDate = moment(new Date(this.leadDetailsData.closingDate.iso)).format('MM/DD/YYYY');
          }

          this.form.patchValue({
            objectId: leadObj,
            fullName: this.leadDetailsData.contact.objectId || '',
            email: this.leadDetailsData.contact.email || '',
            phone: this.leadDetailsData.contact.phoneNumber || '',
            stage: stage || '',
            product: product || '',
            dealValue: this.leadDetailsData.dealValue || '',    
            category: this.leadDetailsData.category || '',
            description: this.leadDetailsData.description || '',
            creator: creator || '',
            leadAssignTo: leadAssignTo || '',
            contact: contact || '',
            closingDate: closingDate,
            noOfSeats: noOfSeats || '',
            estimatedContractLength: estimatedContractLength || '',
            activityNotes: this.leadDetailsData.activityNotes || '',
            userTags: selectedTag
          });
  }

  private initializeTagField() {
      this.tagField = {
        type: 'select', 
        name: 'userTags',
        FormControlName: 'userTags',
        tags: true,
        placeholder: 'Select Tags',
        select2Data: this.leadDetailsData.user_tags,
        select2Options: { multiple: true, tags: true }
      };
  }
  
  backButtonClick() {
    if (this.isViewMode === true) {
      this.router.navigate(['/leads', this.spaceId]);
    } else if (this.isViewMode === false) {
      this.isViewMode = true;
    }
  }

  public editButtonClick() {
    this.isViewMode = false;
  }

  public async onContactSelect(value: string) {
    this.isNewContactSelected = value === 'new';
    
    if (value === 'new') {
      this.form.patchValue({
        email: '',
        phone: '',
      });
    } else {
      const selectedContact = this.contactList.find(contact => contact.objectId === value);
      if (selectedContact) {
        this.form.patchValue({
          fullName: selectedContact.objectId,
          email: selectedContact.email,
          phone: selectedContact.phoneNumber
        });
      }
    }
  }

  public async getContacts() {
    let contactConditionParams;
    if (this.userService.isSuperAdmin) {
      contactConditionParams = {
        where: {
          isActive: { $ne: false },
        },
      };
    } else if (this.userService.isAdmin) {
      contactConditionParams = {
        where: {
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
          isActive: { $ne: false },
        },
      };
    } else if (this.userService.isAgent) {
      contactConditionParams = {
        where: {
          $or: [
            {
              agent: {
                __type: 'Pointer',
                className: '_User',
                objectId: this.authService.getUser().objectId,
              },
            },
            {
              assignTo: {
                __type: 'Pointer',
                className: '_User',
                objectId: this.authService.getUser().objectId,
              },
            },
          ],
          isActive: { $ne: false },
        },
      };
    }
    const res = await this.dataService.getFromServer(
      'classes/Contacts',
      contactConditionParams,
    );
    this.contactList = res.results;
  }

  public async getProducts() {
    let productsQuery;
    if(!this.authService.isSuperAdmin()){
      productsQuery = {
        where: {
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
        },
      };
    }
    const resp = await this.dataService.getFromServer(
      'classes/Products',
      productsQuery,
    );
    this.productList = resp.results;
  }

  public async getStagesList(spaceId?: string ) {
    let params: any;
    if (this.authService.getUser().entityId && !this.authService.isSuperAdmin()) {
      params = {
        where: {
          $or: [
            {
              entity: {
                __type: 'Pointer',
                className: 'Entity',
                objectId: this.authService.getUser().entityId.objectId,
              },
            },
          ],
        },
      };
      if (spaceId) {
        params.where.space = {
          __type: 'Pointer',
          className: 'Spaces',
          objectId: spaceId,
        };
      }
    } 
    else {
      if (this.selectedSpace) {
        params = {
          where: {
            space: {
              __type: 'Pointer',
              className: 'Spaces',
              objectId: this.selectedSpace.objectId,
            },
          },
        }
      }
    }
    const stagesList = await this.dataService.getFromServer(
      'classes/Stages',
      params,
    );
    this.stagesListData = stagesList ? stagesList.results : [];
    this.stagesListData = this.stagesListData.sort((a: any, b: any)=> a.order - b.order);
    this.lastStage = this.stagesListData[this.stagesListData.length -1];
    stagesList.results.forEach((stage: Stages) => {
      if (stage.name === 'Closing') {
        this.closingObjectId = stage.objectId;
      }
    });
  }

  public async loadTeams() {
    let userParams ={};
    if(this.authService.isSuperAdmin()) {
      userParams = {
        where: {
          isActive: true,
        },
      };
    }
    if(this.authService.isAdmin()) {
      userParams = {
        where: {
          isActive: true,
          entityId: {__type: 'Pointer', className: 'Entity', objectId: this.authService.getUser().entityId.objectId},
        },
      };
    }
    if(this.authService.isManager()) {
      userParams = {
        where: {
          isActive: true,
          manager: {__type: 'Pointer', className: '_User', objectId: this.authService.getUser().objectId},
        },
      };
    }
    const users = await this.dataService.getFromServer(
      'classes/_User',
      userParams,
    );
    this.userPageData = users && users.results || [];
  }

  isRecurringPrice(): boolean {
    const productIdInEditLeadForm = this.form.get('product')!.value;
    if(productIdInEditLeadForm && this.productList){
      const product = this.productList.find(product => (product.objectId === productIdInEditLeadForm));
      return product && product.priceType === 'recurring' ? true : false;
    } 
    else{
      return false;
    }
  }

  public async updateLead() {
    const contactData = {__type: 'Pointer', className: 'Contacts', objectId:  this.form.get('fullName')!.value}
    const email = this.form.get('email')!.value;
    const phoneNumber = this.form.get('phone')!.value.toString();
    const dealValue = Number(this.form.get('dealValue')!.value);
    const description = this.form.get('description')!.value;
    const leadAssignTo = this.form.get('leadAssignTo')!.value;
    const stageObjectId = this.form.get('stage')!.value;
    const productId = this.form.get('product')!.value;
    const leadId = this.form.get('objectId')!.value;
    const contactId = this.form.get('contact')!.value;
    const closingDate = this.form.get('closingDate')!.value;   
    const noOfSeats = this.form.get('noOfSeats')!.value;   
    const estimatedContractLength = this.form.get('estimatedContractLength')!.value;    
    const activityNotes = this.form.get('activityNotes')!.value;
    const tags: string[] = this.form.get('userTags')!.value;

    // const contactName = contactData.name ? contactData.name : contactData;

    if(!description){
      this.notifier.notify('error', 'Opportunity cannot be empty');
      return;
    }
    if(!this.form.get('fullName')!.value){
      this.notifier.notify('error', 'Customer Name cannot be empty');
      return;
    }

    // if(!email) {
    //     this.notifier.notify('error', 'Customer Email cannot be empty');
    // }

    if(this.isRecurringPrice()){
      if(!noOfSeats){
        this.notifier.notify('error', 'No.of Subscriptions cannot be empty');
        return;
      }
    }
    if(this.isRecurringPrice()){
      if(!estimatedContractLength){
        this.notifier.notify('error', 'Estimated Contract Length cannot be empty');
        return;
      }
    }

    if(!this.form.get('newContactName')!.value){
      this.notifier.notify('error', 'Customer Name cannot be empty');
      return;
    }

    if(!this.form.get('newContactEmail')!.value){
      this.notifier.notify('error', 'Customer Email cannot be empty');
      return;
    }

    if(!this.form.get('newContactPhone')!.value){
      this.notifier.notify('error', 'Customer Phone number cannot be empty');
      return;
    }

    if (this.isNewContactSelected) {
      const contactParams = {
        name: this.form.get('newContactName')!.value,
        email: this.form.get('newContactEmail')!.value,
        phoneNumber: this.form.get('newContactPhone')!.value,
        entity: {
          __type: 'Pointer',
          className: 'Entity',
          objectId: this.authService.getUser().entityId.objectId,
        },
        isActive: true
      };

      try {
        const newContact = await this.dataService.postToServer('classes/Contacts', contactParams);
        this.form.patchValue({
          fullName: newContact.objectId
        });
        contactData.objectId = newContact.objectId;
      } catch (error) {
        this.notifier.notify('error', 'Failed to create new contact');
        return;
      }
    }

    this.isSubmitting = true;

    const leadParams: LeadParams = {  
      dealValue,
      stage: {
        __type: 'Pointer',
        className: 'Stages',
        objectId: stageObjectId,
      },
      description,
      contact: {
        __type: 'Pointer',
        className: 'Contacts',
        objectId: contactData.objectId ? contactData.objectId : contactId,
      },
      closingDate: {
        __type: 'Date',
        iso: new Date(closingDate),
      },
      activityNotes,
      user_tags: tags
    };

    if(productId) {
      leadParams.product = {
        __type: 'Pointer',
        className: 'Products',
        objectId: productId,
      }
    }

    if (leadAssignTo) {
      leadParams.assignTo = {
        __type: 'Pointer',
        className: '_User',
        objectId: leadAssignTo,
      };
    }

    if (!leadAssignTo && !this.userPageData.length) {
      leadParams.assignTo = {
        __type: 'Pointer',
        className: '_User',
        objectId: this.authService.getUser().objectId,
      };
    }

    if(noOfSeats){
      leadParams.noOfSeats = noOfSeats
    }
    if(estimatedContractLength){
      leadParams.estimatedContractLength = estimatedContractLength
    }
    await this.dataService.updateToServer(
      'classes/Leads/' + leadId,
      leadParams,
    );
    
    const contactParams: ContactsParams = {
      email: this.isNewContactSelected ? this.form.get('newContactEmail')!.value : email,
      phoneNumber: this.isNewContactSelected ? this.form.get('newContactPhone')!.value.toString() : phoneNumber,
      dealValue,
      entity: {
        __type: 'Pointer',
        className: 'Entity',
        objectId: this.authService.getUser().entityId.objectId,
      },
    };

    if (leadAssignTo) {
      contactParams.assignTo = {
        __type: 'Pointer',
        className: '_User',
        objectId: leadAssignTo,
      };
    }

    const contactObjId = contactData.objectId;
    if (contactObjId) {
      try {
        await this.dataService.updateToServer(
          'classes/Contacts/' + contactObjId,
          contactParams,
        );
      } catch (error) {
        this.notifier.notify('error', 'Failed to update contact information');
        return;
      }
    }
    
    if(this.isViewMode == false){
      this.isViewMode = true;
      this.router.navigate(['/lead-details', { leadId }]);
      this.getLeadDetails(leadId);
    }
    this.isSubmitting = false;
    
    // this.notifier.notify('success','Lead Updated Successfully!!');
  }

  public async showSpecificContact(contactID: string) {
    this.router.navigate(["/contact-details"], {
      state: { contactObjectId: contactID, isView: true }
    });
  }

}
