// ChatHistory.service.ts
import { Injectable } from '@angular/core';
import {DataService} from './data.service';
import {AuthService} from "./auth";
import * as Parse from 'parse';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})

export class ChatHistoryService {

    constructor(
        private dataService: DataService,
        private authService: AuthService,
    ) {}

    public chatHistory(userPrompt: string) {
        try {
            const currentUser = this.authService.getUser();
            if(currentUser) {
                this.dataService.postToServer(
                    'classes/ChatHistory',
                    {
                        fromUser: {
                            __type: 'Pointer',
                            className: '_User',
                            objectId: currentUser.objectId,
                        },
                        toUser: {
                            __type: 'Pointer',
                            className: '_User',
                            objectId: '0S3i4vaM6k',
                        },
                        entity: {
                            __type: 'Pointer',
                            className: "Entity",
                            objectId: currentUser.entityId.objectId,
                        },
                        message: userPrompt
                    },
                );
            }
        } catch (error) {
            throw error;
        }
    }

    public async getChatHistory() {
        try {
            let chatHistoryObj = Parse.Object.extend('ChatHistory'),
                chatHistoryQuery = new Parse.Query(chatHistoryObj),
                chatHistoryFromUserQuery = new Parse.Query(chatHistoryObj),
                chatHistoryToUserQuery = new Parse.Query(chatHistoryObj);
            chatHistoryQuery.equalTo('entity', {
                __type: 'Pointer',
                className: 'Entity',
                objectId: this.authService.getUser().entityId.objectId,
            });
            chatHistoryFromUserQuery.equalTo('fromUser', {
                __type: 'Pointer',
                className: '_User',
                objectId: this.authService.getUser().objectId,
            });
            chatHistoryToUserQuery.equalTo('toUser', {
                __type: 'Pointer',
                className: '_User',
                objectId: this.authService.getUser().objectId,
            });
            chatHistoryQuery = Parse.Query.or(chatHistoryFromUserQuery, chatHistoryToUserQuery);
            chatHistoryQuery.limit(10000);
            return await chatHistoryQuery.find();
        } catch (error) {
            throw new Error(error);
        }
    }

    public async deleteChatHistory() {
        try {
            const currentUser = this.authService.getUser();
            if (currentUser && currentUser.entityId.objectId) {
                return await this.dataService.postToServer('functions/deleteChatHistory',
                    {
                        entityId: currentUser.entityId.objectId,
                    },
                );
            } else {
                throw new Error('Something went wrong, please try again.');
            }
        } catch (error) {
            throw error;
        }
    }

    public async getMsgDateTime(): Promise<string[]> {
        try {
            let chatHistoryObj = Parse.Object.extend('ChatHistory'),
                chatHistoryQuery = new Parse.Query(chatHistoryObj),
                chatHistoryFromUserQuery = new Parse.Query(chatHistoryObj),
                chatHistoryToUserQuery = new Parse.Query(chatHistoryObj);
    
            chatHistoryQuery.equalTo('entity', {
                __type: 'Pointer',
                className: 'Entity',
                objectId: this.authService.getUser().entityId.objectId,
            });
    
            chatHistoryFromUserQuery.equalTo('fromUser', {
                __type: 'Pointer',
                className: '_User',
                objectId: this.authService.getUser().objectId,
            });
            chatHistoryToUserQuery.equalTo('toUser', {
                __type: 'Pointer',
                className: '_User',
                objectId: this.authService.getUser().objectId,
            });
    
            chatHistoryQuery = Parse.Query.or(chatHistoryFromUserQuery, chatHistoryToUserQuery);
            chatHistoryQuery.limit(10000);
    
            const results = await chatHistoryQuery.find();
    
            const dateTimeList: string[] = results.map((chat: any) => {
                const createdAt = chat.get('createdAt');
                return moment(createdAt).format('YYYY-MM-DD HH:mm:ss');
            });
    
            return dateTimeList; 
        } catch (error) {
            throw new Error(error);
        }
    }

}
