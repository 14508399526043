import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { AuthService } from './services/auth';
import { Meta, Title } from '@angular/platform-browser';
import { DataService } from './services/data.service';
import { HelperService } from './services/helper.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // public title = 'inleads';
  public isLoggedIn = false;
  public routeParams: any = {};
  public sideMenu = ['Dashboard','Settings','Leads','Contacts','Customer-details','Analytics', 'Event-details',
  'Agents','Managers','Products','Spaces','Profile', 'Support', 'Ask-ai',
   'Quotes', 'Articles', 'Tasks', 'Online', 'Activity', 'Account', 'Outreach', 'Outreach-Templates', 'dau-metrics', 'Visitors', 'Visitor-details', 'Lead-details', 'Groups', 'Automation'];

  constructor(private router: Router  ,private auth: AuthService, private meta: Meta, private title: Title, private dataService: DataService, private helperService: HelperService) {
    this.routeParams.currentRoute = '';
  }

  public ngOnInit() {
    this.initLiveQuery();
    this.initCommonQuery();
  }

  public onActivate() {
    this.isLoggedIn = this.auth.isSignedIn();

    this.router.routerState.root.children[0].params.subscribe(params => {
      params = JSON.parse(JSON.stringify(params));
      params.currentRoute = this.router.url;
      params.pageName = this.router.routerState.root.children[0].snapshot.data[0]['PageName'];
      params.title = this.router.routerState.root.children[0].snapshot.data[0]['Title'];

      this.routeParams = params;

      this.meta.updateTag({ name: 'ROBOTS', content: 'noindex' });

      this.meta.updateTag({ name: 'canonical', content: 'https://www.inleads.ai/" /' });
      switch( this.router.url) {      

        default:
          this.title.setTitle('InLeads - AI Driven Sales and Analytics');         
          this.meta.updateTag( { name: 'keywords', content: 'lms'});
          break;
      }
    },
  );
  }

  public async initCommonQuery() {
    if(this.auth.isSignedIn()) {
      let query, query1;
      if(!this.auth.isSuperAdmin()){
        query = {
          where: {
            entity: { __type: 'Pointer', className: 'Entity', objectId: this.auth.getUser().entityId.objectId },
            isArchived: { $ne : true },
          },
        }
        query1 = {
          where: {
            entity: { __type: 'Pointer', className: 'Entity', objectId: this.auth.getUser().entityId.objectId },
          },
        }
      }
      const spaceResp = await this.dataService.getFromServer('classes/Spaces', query);
      const stageResp = await this.dataService.getFromServer('classes/Stages', query1);
      this.helperService.setSpacesData(spaceResp.results);
      this.helperService.setStagesData(stageResp.results);
    }
  }


  public async initLiveQuery() {
    await this.dataService.getSpacesLiveQueryData();
    //await this.dataService.getFollowUpsLiveQueryData(this.auth.getUser().entityId.objectId);
  }
}
